import ContainerHOC from "../components/General/ContainerHOC";
import NewToNoorioo from "../components/General/NewToNoorioo";
import styled from "styled-components";
import HomeWorkTable from "../components/HomeWork/HomeWorkTable";
import NewHomeWorkButtons from "../components/HomeWork/NewHomeWorkButtons";
import UserRequired from "components/Auth/UserRequired";
import { HomeWorkDataType } from "types/document";
import { useEffect, useState } from "react";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import listHomeWorks from "lib/homework/listHomeWorks";
import groupHomeWorksByDate from "lib/homework/groupHomeWorkList";
import Intercom, { boot } from "@intercom/messenger-js-sdk";
import useUser from "hooks/user/useUser";

export default function Home() {
  const [homeworks, setHomeworks] = useState<HomeWorkDataType[]>([]);
  const [loading, setLoading] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      Intercom({
        app_id: "u7qxri9g",
        user_id: user.id,
        name: user.email,
        email: user.email,
        action_color: "#292826",
        created_at: Math.floor(new Date(user?.created_at).getTime() / 1000),
      });
      boot({
        app_id: "u7qxri9g",
        user_id: user.id,
        name: user.email,
        email: user.email,
        action_color: "#292826",
        created_at: Math.floor(new Date(user?.created_at).getTime() / 1000),
      });
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      if (!loading) {
        try {
          setLoading(true);
          const resp = await listHomeWorks();
          setHomeworks(resp || []);
        } catch (e) {
          axiosErrorHandler(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const groupedHomeWorks = groupHomeWorksByDate(homeworks);

  return (
    <UserRequired>
      <ContainerHOC>
        <Wrapper>
          <NewHomeWorkButtons />
          <ScrollContainer>
            {/* <NewToNoorioo /> */}
            {Object.entries(groupedHomeWorks).map(([date, homeworks], i) => (
              <HomeWorkTable
                key={date}
                withHeaders={i === 0}
                header={i === 0 ? "" : `Erstellt am ${date}`}
                data={homeworks}
              />
            ))}
          </ScrollContainer>
        </Wrapper>
      </ContainerHOC>
    </UserRequired>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: rgba(28, 27, 27, 0.5);
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
`;
