import { Editor } from "@tiptap/react";
import { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { exportConfigState } from "recoil/export";
import { ExportConfig } from "types/document";

const PT_TO_MM = 0.3527777778;

export const usePageCount = (editor: Editor | null) => {
  const [pageCount, setPageCount] = useState(0);
  const exportConfig = useRecoilValue(exportConfigState);
  const prevContentRef = useRef<string>("");
  const prevConfigRef = useRef<ExportConfig | null>(null);

  useEffect(() => {
    if (!editor) return;

    const estimatePageCount = (
      content: string,
      config: ExportConfig
    ): number => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");

      // A4 dimensions in mm
      const pageWidthMm = 210;
      const pageHeightMm = 297;

      // Calculate available content area in mm
      const contentWidthMm =
        pageWidthMm - config.leftSpacing * 10 - config.rightSpacing * 10;
      const contentHeightMm = pageHeightMm - 2 * config.verticalSpacing * 10;

      const lineHeightMm = config.text * PT_TO_MM * config.lineSpacing;

      let totalHeightMm = 0;
      let citationCount = 0;

      const processElement = (element: Element): number => {
        let heightMm = 0;
        const text = element.textContent || "";

        const calculateLines = (text: string, fontSize: number) => {
          const charsPerLine = Math.floor(
            contentWidthMm / (fontSize * PT_TO_MM * 0.5)
          );
          return Math.ceil(text.length / charsPerLine);
        };

        switch (element.tagName) {
          case "H1":
            heightMm =
              config.heading1 * PT_TO_MM * config.lineSpacing +
              lineHeightMm * 2;
            break;
          case "H2":
            heightMm =
              config.heading2 * PT_TO_MM * config.lineSpacing +
              lineHeightMm * 1.5;
            break;
          case "H3":
            heightMm =
              config.heading3 * PT_TO_MM * config.lineSpacing +
              lineHeightMm * 1.25;
            break;
          case "P":
            const lines = calculateLines(text, config.text);
            heightMm = lines * lineHeightMm + lineHeightMm * 0.75;
            break;
          case "UL":
          case "OL":
            Array.from(element.children).forEach((li) => {
              heightMm += processElement(li) + lineHeightMm * 0.5;
            });
            heightMm += lineHeightMm; // Extra space for the list itself
            break;
          case "LI":
            const liLines = calculateLines(text, config.text);
            heightMm = liLines * lineHeightMm + lineHeightMm * 0.25;
            break;
          case "BLOCKQUOTE":
            const bqLines = calculateLines(text, config.text);
            heightMm = bqLines * lineHeightMm + lineHeightMm * 1.5;
            break;
          case "H6":
            heightMm =
              config.text * 1.5 * PT_TO_MM * config.lineSpacing +
              lineHeightMm * 1.5;
            break;
          case "TABLE":
            const rows = element.querySelectorAll("tr").length;
            heightMm = rows * lineHeightMm * 1.5;
            break;
          default:
            const defaultLines = calculateLines(text, config.text);
            heightMm = defaultLines * lineHeightMm + lineHeightMm * 0.75;
        }

        const citations = element.querySelectorAll('a[data-type="citation"]');
        citationCount += citations.length;

        return heightMm;
      };

      doc.body.childNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          totalHeightMm += processElement(node as Element);
        }
      });

      // Add space for footnotes
      const footnoteHeightMm = citationCount * (10 * PT_TO_MM * 1.2);
      totalHeightMm += footnoteHeightMm;

      // Add extra space to account for potential overflow
      totalHeightMm += lineHeightMm * 2;

      let estimatedPages = Math.ceil(totalHeightMm / contentHeightMm);

      // console.log("Total height (mm):", totalHeightMm);
      // console.log("Content height per page (mm):", contentHeightMm);
      // console.log("Estimated pages:", estimatedPages);

      return estimatedPages;
    };

    const updatePageCount = () => {
      const content = editor.getHTML();

      const contentChanged =
        Math.abs(content.length - prevContentRef.current.length) > 50;
      const configChanged =
        JSON.stringify(exportConfig) !== JSON.stringify(prevConfigRef.current);

      if (contentChanged || configChanged) {
        const estimatedPages = estimatePageCount(content, exportConfig);
        setPageCount(estimatedPages);

        prevContentRef.current = content;
        prevConfigRef.current = exportConfig;
      }
    };

    updatePageCount();

    editor.on("update", updatePageCount);

    return () => {
      editor.off("update", updatePageCount);
    };
  }, [editor, exportConfig]);

  return pageCount;
};
