import { Editor } from "@tiptap/react";
import React from "react";
import ReactDOM from "react-dom/client";
import FindeZitate from "components/General/FindeZitate";
import { RecoilRoot } from "recoil";

export function openFindeZitate(editor: Editor) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  const root = ReactDOM.createRoot(div);

  const FindeZitateWrapper = () => {
    return (
      <FindeZitate
        editor={editor}
        isSlashCommand={true}
        onClose={() => {
          root.unmount();
          document.body.removeChild(div);
        }}
      />
    );
  };

  root.render(
    <RecoilRoot>
      <FindeZitateWrapper />
    </RecoilRoot>
  );
}
