import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { GlobalStyles } from "./styles/GlobalStyles";
import { RecoilRoot } from "recoil";
import HomeWork from "./pages/HomeWork";
import NewHomeWorkModal from "./components/HomeWork/NewHomeWorkModal";
import PlansModal from "./components/General/PlansModal";
import SettingsModal from "./components/General/SettingsModal";
import useAddCitationHandler from "hooks/useAddCitationHandler";
import AuthPage from "pages/Auth";
import { Toaster } from "sonner";
import VerifyEmailModal from "components/Auth/AuthModals/VerifyEmailModal";
import ExportSettingsModal from "components/General/ExportSettingsModal";
import AuthCallback from "pages/AuthCallback";
import Onboarding from "pages/Onboarding";
import ManageModal from "components/General/ManageModal";

declare global {
  interface Window {
    handleCitationClick: (event: MouseEvent) => void;
  }
}

function App() {
  useAddCitationHandler();

  return (
    <>
      <RecoilRoot>
        <GlobalStyles />
        <Toaster
          expand
          theme="dark"
          toastOptions={{
            className: "toast",
            classNames: {
              error: "error",
              success: "success",
              warning: "warning",
              info: "info",
            },
          }}
        />
        <SettingsModal />
        <ExportSettingsModal />
        <NewHomeWorkModal />
        <PlansModal />
        <VerifyEmailModal />
        <ManageModal />
        <Routes>
          <Route index path="/onboarding" element={<Onboarding />} />
          <Route index path="/auth" element={<AuthPage />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/homework" element={<HomeWork />} />
        </Routes>
      </RecoilRoot>
    </>
  );
}

export default App;
