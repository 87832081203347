import React, { useEffect, useMemo, useState } from "react";
import Modal from "../General/Modal";
import { useRecoilState } from "recoil";
import { newHomeWorkModalState } from "../../recoil/modal";
import {
  Container,
  Header,
  SmallText,
  Subtitle,
  Title,
} from "../General/Modal/commonStyles";
import Input from "../General/Input";
import Spacer from "../General/Spacer";
import Button from "../General/Button";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import createHomeWork from "lib/homework/createHomeWork";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/General/Dropdown";
import getAllModules from "lib/homework/getModules";

export default function NewHomeWorkModal() {
  const [open, setOpen] = useRecoilState(newHomeWorkModalState);
  const [name, setName] = useState("");
  const [module, setModule] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialModules = [{ name: "Rechtswissenschaften" }];
  const [modules, setModules] = useState(initialModules);

  useEffect(() => {
    (async () => {
      if (!loading) {
        try {
          setLoading(true);
          const resp = await getAllModules();
          setModules(resp || initialModules);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [open]);

  const moduleOptions = useMemo(() => {
    return modules.map((m) => ({ label: m.name, value: m.name }));
  }, modules);

  const handleCreateHomeWork = async () => {
    if (!loading) {
      try {
        setLoading(true);
        if (!module || !name) throw new Error("Please provide all details");
        const resp = await createHomeWork(name, module);
        if (resp.createdAt) {
          navigate(`/homework?id=${resp._id}`);
          setOpen(false);
        }
      } catch (e) {
        axiosErrorHandler(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      maxHeight="430px"
      maxWidth="500px"
      hideClose
    >
      <Container>
        <Header>
          <Title>Create it.</Title>
          <Subtitle>Erstelle Deine Hausarbeit</Subtitle>
        </Header>
        <Spacer height={32.6} />
        <Input
          variant="black"
          placeholder="Name der Hausarbeit"
          value={name}
          onChange={setName}
        />
        <Spacer height={15} />
        <Dropdown
          options={moduleOptions}
          placeholder="Modul"
          value={module}
          setValue={setModule}
        />
        <Spacer height={15} />
        <Button
          disabled={loading}
          text={
            loading ? "Hausarbeit erstellen..." : "Erstellen Sie Hausarbeit"
          }
          variant="blue"
          onClick={handleCreateHomeWork}
        />
        <Spacer height={10} />
        <SmallText>
          Erstelle Deine Hausarbeit. Bei Fragen,
          <br />
          kannst Du immer unser Hilfezentrum aufsuchen.
        </SmallText>
      </Container>
    </Modal>
  );
}
