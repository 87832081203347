import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function getBuyLinkForPlan(
  plan: "monthly" | "yearly"
): Promise<{ url: string }> {
  const response = await axiosWithAuth.get(
    `${process.env.REACT_APP_NOORIOO_API}/user/upgrade/${plan}`
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
