import { Loader2 } from "lucide-react";
import styled, { keyframes } from "styled-components";

export default function CircularLoader({
  size = 15,
  loading,
}: {
  size?: number;
  loading: boolean;
}) {
  return loading ? <Loader size={size} /> : null;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(Loader2)`
  animation: ${rotate} 1s linear infinite;
`;
