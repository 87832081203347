import Modal from "../../General/Modal";

import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { verfiyEmailModalState } from "../../../recoil/modal";
import {
  Container,
  Header,
  SmallText,
  Subtitle,
  Title,
} from "../../General/Modal/commonStyles";
import Input from "../../General/Input";
import Spacer from "../../General/Spacer";
import Button from "../../General/Button";
import { SuccessEmailIcon } from "../../../constants/Svgs";
import styled, { keyframes } from "styled-components";
import linkEduEmail from "lib/user/linkEduEmail";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import { toast } from "sonner";
import CircularLoader from "components/General/CircularLoader";
import verifyEduOTP from "lib/user/verifyEduOTP";
import { isValidEmail } from "lib/utils/validations";
import useOnboarding from "hooks/user/useOnboarding";

enum Progress {
  EMAIL,
  CODE,
  SUCCESS,
  FAILURE,
}

export default function VerifyEmailModal() {
  const [open, setOpen] = useRecoilState(verfiyEmailModalState);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [progress, setProgress] = useState(Progress.EMAIL);
  const [loading, setLoading] = useState(false);
  const { onboarding, updateOnboarding } = useOnboarding();

  useEffect(() => {
    if (onboarding?.universityEmail) {
      setProgress(Progress.SUCCESS);
    } else {
      setProgress(Progress.EMAIL);
    }
  }, [onboarding?.universityEmail, open]);

  const handleSendVerification = async () => {
    if (!loading && progress === Progress.EMAIL)
      try {
        if (!email) throw new Error("Please enter a valid email address");
        setLoading(true);
        const resp = await linkEduEmail(email);
        if (resp.ok) {
          toast.success(resp.message);
          setProgress(Progress.CODE);
        }
      } catch (e) {
        axiosErrorHandler(e);
      } finally {
        setLoading(false);
      }
  };

  const handleVerifyOTP = async () => {
    if (!loading && progress === Progress.CODE)
      try {
        if (!code)
          throw new Error("Please enter verification code sent to your email");
        setLoading(true);
        const resp = await verifyEduOTP(code);
        if (resp.ok) {
          toast.success(resp.message);
          setProgress(Progress.SUCCESS);
          updateOnboarding("universityEmail");
        }
      } catch (e) {
        axiosErrorHandler(e);
      } finally {
        setLoading(false);
      }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      hideClose
      maxHeight="430px"
      maxWidth="500px"
    >
      {progress === Progress.SUCCESS ? (
        <SuccessDisplay />
      ) : (
        <Container>
          <Header>
            <Title>More features.</Title>
            <Subtitle>Verify your EDU-Email for more power.</Subtitle>
          </Header>
          <Spacer height={32.6} />
          <Input
            variant="black"
            placeholder="Enter your EDU-Email"
            type="email"
            value={email}
            onChange={setEmail}
            disabled={progress === Progress.CODE}
          />
          {progress === Progress.CODE && (
            <>
              <Spacer height={14} />
              <Input
                variant="black"
                placeholder="Verifiction code"
                type="text"
                value={code}
                onChange={setCode}
              />
            </>
          )}
          <Spacer height={14} />
          {progress === Progress.CODE ? (
            <Button variant="blue" onClick={handleVerifyOTP}>
              {loading ? <CircularLoader loading={loading} /> : "Verify Email"}
            </Button>
          ) : (
            <Button
              disabled={!email}
              variant="blue"
              onClick={handleSendVerification}
            >
              {loading ? (
                <CircularLoader loading={loading} />
              ) : (
                "Send verification"
              )}
            </Button>
          )}
          <Spacer height={14} />
          <SmallText>
            Erstelle Deine Hausarbeit. Bei Fragen,
            <br />
            kannst Du immer unser Hilfezentrum aufsuchen.
          </SmallText>
        </Container>
      )}
    </Modal>
  );
}

const SuccessDisplay = () => {
  return (
    <SuccessDisplayWrapper>
      <Line2>
        <Line3>
          <Line4>
            <SuccessEmailIcon />
          </Line4>
        </Line3>
      </Line2>
    </SuccessDisplayWrapper>
  );
};

const CenterFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessDisplayWrapper = styled(CenterFlex)`
  flex-direction: column;
  height: 26.875rem;
  gap: 1.25rem;
`;

const colorChange = keyframes`
  0% {
    border-color: rgba(0, 216, 133, .5);
  }
  100% {
    border-color: #00d885;
  }
`;

const Line1 = styled(CenterFlex)`
  width: 15.625rem;
  height: 15.625rem;
  border-radius: 3.75rem;
  border: 0.2188rem solid rgba(0, 216, 133, 0.4);
  animation: ${colorChange} 2s infinite;
  animation-delay: 0.5s;
`;

const Line2 = styled(CenterFlex)`
  width: 13.125rem;
  height: 13.125rem;
  border-radius: 3.125rem;
  border: 0.2188rem solid rgba(0, 216, 133, 0.6);
  animation: ${colorChange} 2s infinite;
  animation-delay: 0.3s;
`;

const Line3 = styled(CenterFlex)`
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 2.5rem;
  border: 0.2188rem solid rgba(0, 216, 133, 0.8);
  animation: ${colorChange} 2s infinite;
  animation-delay: 0.15s;
`;

const Line4 = styled(CenterFlex)`
  width: 8.125rem;
  height: 8.125rem;
  border-radius: 1.875rem;
  border: 0.2188rem solid #00d885;
  animation: ${colorChange} 2s infinite;
`;
