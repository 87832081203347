import styled from "styled-components";
import TogglePane from "./TogglePane";
import { useRecoilValue } from "recoil";
import { paneStateExpanded } from "../../recoil/pane";
import RightSection from "./RightSection";
import TldrSection from "./TldrSection";

export default function HomeWorkRightSection() {
  const isPaneExpanded = useRecoilValue(paneStateExpanded);
  return (
    <Wrapper $isPaneExpanded={isPaneExpanded}>
      <TogglePane />
      <Content $isPaneExpanded={isPaneExpanded}>
        <TldrSection />
      </Content>
    </Wrapper>
  );
}
const Content = styled.div<{ $isPaneExpanded: boolean }>`
  padding-top: 1.75rem;
  padding-left: 1.625rem;
  padding-right: 1.25rem;
  transition-property: opacity;
  transition-duration: ${(props) => (props.$isPaneExpanded ? ".4s" : ".2s")};
  transition-delay: ${(props) => (props.$isPaneExpanded ? ".4s" : "0s")};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Wrapper = styled.div<{ $isPaneExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 0;
  border-left: 1px solid #292826;
  width: 440px;
  transition: width 0.5s ease;
  width: ${(props) => (props.$isPaneExpanded ? "440px" : "60px")};
  transition-delay: ${(props) => (props.$isPaneExpanded ? "0s" : ".1s")};

  ${Content} {
    opacity: ${(props) => (props.$isPaneExpanded ? 1 : 0)};
    pointer-events: ${(props) => (props.$isPaneExpanded ? "auto" : "none")};
    overflow: ${(props) => (props.$isPaneExpanded ? "auto" : "hidden")};
  }
`;
