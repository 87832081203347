import React from "react";
import styled from "styled-components";

interface ButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  text?: string;
  disabled?: boolean;
  variant?: "white" | "blue" | "black";
  square?: boolean;
  type?: "button" | "submit" | "reset";
  center?: boolean;
  maxWidth?: string;
}

export default function Button({
  onClick,
  children,
  text,
  type = "button",
  disabled,
  variant = "white",
  square = false,
  center = false,
  maxWidth,
}: ButtonProps) {
  return (
    <StyledButton
      $maxWidth={maxWidth}
      onClick={onClick}
      disabled={disabled}
      $variant={variant}
      $square={square}
      type={type}
      $center={center}
    >
      {text || children}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  $variant?: string;
  $square: boolean;
  $center: boolean;
  $maxWidth?: string;
}>`
  padding: ${(props) => (props.$square ? "0" : "0.625rem 0.8125rem")};
  font-size: 0.75rem;
  background-color: #fff;
  width: ${(props) => (props.$square ? "2.25rem" : "100%")};
  min-width: ${(props) => (props.$square ? "2.25rem" : "auto")};
  height: 2.25rem;
  display: ${(props) => (props.$square ? "flex" : "block")};
  border: none;
  outline: none;
  border-radius: 0.3125rem;
  cursor: pointer;
  color: #000;
  font-size: 0.75rem;
  text-align: ${(props) => (props.$center ? "center" : "left")};
  transition: background-color 0.2s;
  max-width: ${(props) => props.$maxWidth || "none"};

  ${(props) =>
    props.$square &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  ${(props) =>
    props.$variant === "blue" &&
    `
    background-color: #2383e2;
    color: #fff;

    &:hover {
      background-color: #1e6ab3;
    }
  `}

${(props) =>
    props.$variant === "black" &&
    `
    background-color: #191719;
    color: #fff;

    &:hover {
      background-color: #0f0f0f;
    }
  `}

  &:disabled {
    background-color: #444444;
    cursor: not-allowed;
  }
`;
