import { useState, useEffect, useRef, useCallback } from "react";
import { Editor } from "@tiptap/react";

interface PopoverPosition {
  x: number;
  y: number;
}

export function useSlashCommandPopover(
  editor: Editor,
  isSlashCommand: boolean
) {
  const [position, setPosition] = useState<PopoverPosition>({ x: 0, y: 0 });
  const contentRef = useRef<HTMLDivElement>(null);

  const updatePosition = useCallback(() => {
    if (!contentRef.current) return;

    const rect = contentRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let { x, y } = position;

    if (x + rect.width > viewportWidth) {
      x = viewportWidth - rect.width - 10; // 10px margin from right
    }

    if (y + rect.height > viewportHeight) {
      y = Math.max(10, y - rect.height - 10); // 10px above cursor, but not above viewport
    }

    if (x !== position.x || y !== position.y) {
      setPosition({ x, y });
    }
  }, [position]);

  useEffect(() => {
    if (isSlashCommand) {
      const { state, view } = editor;
      const { selection } = state;
      const { $anchor } = selection;
      const pos = view.coordsAtPos($anchor.pos);

      // Set initial position, but keep it within viewport bounds
      const x = Math.min(pos.left, window.innerWidth - 400); // Assume max width of 400px
      const y = Math.min(pos.bottom, window.innerHeight - 300); // Assume max height of 300px

      setPosition({ x, y });
    }
  }, [isSlashCommand, editor]);

  useEffect(() => {
    if (isSlashCommand) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
      return () => {
        window.removeEventListener("resize", updatePosition);
      };
    }
  }, [isSlashCommand, updatePosition]);

  return {
    position,
    contentRef,
    updatePosition,
  };
}
