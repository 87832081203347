import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function linkEduEmail(
  email: string
): Promise<{ ok: boolean; message: string }> {
  const resp = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/user/link-edu`,
    { email }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
