import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import SmallButton from "./SmallButton";
import { CopySmIcon, DeleteIcon, Tick } from "constants/Svgs";
import { StyledLink } from "components/HomeWork/Sources";
import Divider from "./Divider";
import Spacer from "./Spacer";
import {
  Bubble,
  CitationDisplayInput,
  Content,
  Row,
  SourceDisplayInput,
} from "./FindeZitate";
import getDomainName from "lib/utils/getDomainName";

interface CitationModalProps {
  id: string;
  source: string;
  content: string;
  url?: string;
  x: number;
  y: number;
  onClose: () => void;
}

const CitationModal: React.FC<CitationModalProps> = ({
  id,
  source,
  content,
  url,
  x,
  y,
  onClose,
}) => {
  const [sourcesInput, setSourcesInput] = useState<string>(source);
  const [contentInput, setContentInput] = useState<string>(content);
  const [isOpen, setIsOpen] = useState(true);
  const isEdited = sourcesInput !== source || contentInput !== content;

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    setSourcesInput(source);
    setContentInput(content);
  }, [source, content]);

  const handleSourceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSourcesInput(e.target.value);
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentInput(e.target.value);
  };

  function onDeleteCitation() {
    // Find the element in the document with the specified data-id and data-type
    const selector = `a[data-id="${id}"][data-type="citation"]`;
    const citationElement = document.querySelector(selector);

    // Check if the element exists
    if (citationElement) {
      // Remove the element from the DOM
      citationElement.remove();
      handleClose();
    } else {
      console.error(`No citation found with id ${id}.`);
    }
  }

  const handleSave = () => {
    if (isEdited) {
      const event = new CustomEvent("updateCitation", {
        detail: { id, source: sourcesInput, content: contentInput, url },
      });
      window.dispatchEvent(event);
      handleClose();
    }
  };

  const handleCopy = () => {
    const selector = `a[data-id="${id}"][data-type="citation"]`;
    const citationElement = document.querySelector(selector);
    citationElement.setAttribute("data-id", Date.now().toString());
    navigator.clipboard.writeText(citationElement.outerHTML);
  };

  return (
    <Tippy
      visible={isOpen}
      onClickOutside={handleClose}
      placement="bottom-start"
      interactive={true}
      offset={[0, 10]}
    >
      <Content
        style={{
          position: "absolute",
          left: `${x}px`,
          top: `${y + 10}px`,
        }}
      >
        <Bubble>
          <SourceDisplayInput
            value={sourcesInput}
            onChange={handleSourceChange}
          />
          <CitationDisplayInput
            value={contentInput}
            onChange={handleContentChange}
          />
          <Divider noMargin color="#363433" />
          <Spacer height={8} />
          <Row>
            {!!url && (
              <StyledLink
                item={{
                  source: getDomainName(url),
                  url: url,
                }}
                index={0}
              />
            )}

            <SmallButton onClick={onDeleteCitation}>
              <DeleteIcon />
            </SmallButton>
            <SmallButton onClick={handleCopy}>
              <CopySmIcon />
            </SmallButton>
            {isEdited && (
              <SmallButton onClick={handleSave}>
                <Tick />
              </SmallButton>
            )}
          </Row>
        </Bubble>
      </Content>
    </Tippy>
  );
};

export default CitationModal;
