import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "supabase";
import AuthPage from "./Auth";

export default function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        navigate("/"); // Redirect to your desired page after successful login
      } else {
        navigate("/auth"); // Redirect back to auth page if no session
      }
    });
  }, [navigate]);

  return <AuthPage />; // Or any loading indicator
}
