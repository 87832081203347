import axiosWithAuth from "lib/utils/axiosWithAuth";
import { UserDataType } from "types/user";

export default async function getUserData(): Promise<UserDataType> {
  const resp = await axiosWithAuth.get(
    `${process.env.REACT_APP_NOORIOO_API}/user/me`
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
