import { AxiosError, CanceledError } from "axios";
import { getToken } from "hooks/user/useUser";
import { toast } from "sonner";

export default function axiosErrorHandler(
  error: unknown,
  hideAxiosError?: boolean
) {
  console.log(error);
  if (error instanceof Error && !(error instanceof CanceledError)) {
    if (error instanceof AxiosError) {
      if (
        hideAxiosError &&
        error?.response?.data?.message?.toLowerCase().includes("request failed")
      ) {
        console.log(error.message);
        toast.error("Something went wrong");
        return;
      }
      console.log(error?.response?.data);
      if (error?.response?.data?.message === "jwt expired") {
        getToken();
        window.location.reload();
        return;
      }
      if (error?.response?.data?.errors) {
        error?.response?.data?.errors.forEach((e) => {
          let constraints = e?.constraints;
          if (constraints) {
            Object.values(constraints).forEach((msg: string) =>
              toast.error(msg)
            );
          }
        });
      } else {
        toast.error(
          error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Something went wrong"
        );
      }
    } else {
      console.log(error.message);
      toast.error(error.message);
    }
  }
}
