import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";
import { FalseCitation } from "./FindeZitate";

interface KiPromptPopoverProps {
  value: string;
  visible: boolean;
  handleClose: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleReplace: () => void;
  handleInsertBelow: () => void;
}

export default function KiPrompt({
  value,
  handleClose,
  visible,
  handleReplace,
  handleInsertBelow,
}: KiPromptPopoverProps) {
  return (
    <Popover.Root
      open={visible}
      onOpenChange={(isOpen) => !isOpen && handleClose()}
    >
      <Popover.Trigger
        style={{
          width: 0,
          overflow: "hidden",
        }}
      />
      <Popover.Content
        sideOffset={28}
        alignOffset={-5}
        side="bottom"
        align="start"
      >
        <Content>
          <Result>{value}</Result>
          {/* <FalseCitation>
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.38686 11.2856H9.924C10.7666 11.2856 11.3109 10.6599 11.3109 9.89278C11.3109 9.66478 11.2509 9.42992 11.1309 9.21306L6.85457 1.57335C6.73732 1.35736 6.56395 1.17703 6.35274 1.05137C6.14154 0.925704 5.90033 0.859375 5.65457 0.859375C5.40881 0.859375 5.16761 0.925704 4.9564 1.05137C4.7452 1.17703 4.57183 1.35736 4.45457 1.57335L0.185143 9.21306C0.0651429 9.43592 0 9.66478 0 9.89278C0 10.6599 0.544286 11.2856 1.38771 11.2856H1.38686ZM5.66314 7.54249C5.34171 7.54249 5.15743 7.36249 5.14629 7.04192L5.07 4.32135C5.05886 3.98363 5.29886 3.75049 5.658 3.75049C6.006 3.75049 6.26143 3.98963 6.25029 4.32649L6.16372 7.03678C6.15257 7.36849 5.97343 7.54249 5.66314 7.54249ZM5.66314 9.42478C5.29286 9.42478 4.98857 9.15906 4.98857 8.79906C4.98857 8.43992 5.28771 8.17335 5.66314 8.17335C6.03343 8.17335 6.33172 8.43992 6.33172 8.79906C6.33172 9.16335 6.02743 9.42478 5.66314 9.42478Z"
                fill="white"
                fillOpacity="0.282"
              />
            </svg>
            Found citations can be false - check them.
          </FalseCitation> */}
        </Content>

        <MoreSettings>
          <Button onClick={handleReplace}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.58675 12.3917C5.9035 12.3917 6.1485 12.2605 6.32175 12.0032L11.9454 3.2462C12.0714 3.04845 12.1248 2.88133 12.1248 2.71945C12.1248 2.29508 11.8264 2.00195 11.3959 2.00195C11.1028 2.00195 10.9234 2.10958 10.7431 2.39045L5.55788 10.6155L2.89613 7.20033C2.71675 6.9667 2.53125 6.87133 2.26788 6.87133C1.83738 6.87133 1.52588 7.17583 1.52588 7.59495C1.52588 7.78045 1.5915 7.95983 1.74725 8.14533L4.84563 12.0093C5.05563 12.2718 5.27525 12.3917 5.58675 12.3917Z"
                fill="white"
                fillOpacity="0.81"
              />
            </svg>
            Replace selection
          </Button>
          <Button onClick={handleInsertBelow}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.36104 11.4055H12.4324C12.7151 11.4055 12.9457 11.6303 12.9457 11.9129C12.9457 12.1955 12.7151 12.4204 12.4324 12.4204H8.36104C8.07847 12.4204 7.85356 12.1955 7.85356 11.9129C7.85356 11.6303 8.07847 11.4055 8.36104 11.4055ZM1.06021 8.4701C1.06021 7.96262 1.33702 7.6512 1.78684 7.6512C2.23665 7.6512 2.51922 7.97415 2.51922 8.4528V9.43891C2.51922 9.57733 2.59419 9.6581 2.73836 9.6581H4.11664V9.06983C4.11664 8.59121 4.60682 8.40666 4.9759 8.70078L6.61945 10.0214C6.86743 10.2175 6.86743 10.5692 6.61945 10.7595L4.9759 12.086C4.59529 12.3974 4.11664 12.2127 4.11664 11.7111V11.1171H2.56536C1.65419 11.1171 1.06021 10.5866 1.06021 9.67533V8.4701ZM8.36104 8.3836H12.4324C12.7151 8.3836 12.9457 8.60851 12.9457 8.89107C12.9457 9.1737 12.7151 9.39857 12.4324 9.39857H8.36104C8.07847 9.39857 7.85356 9.1737 7.85356 8.89107C7.85356 8.60851 8.07847 8.3836 8.36104 8.3836ZM1.56193 5.36177H12.4324C12.7151 5.36177 12.9457 5.58668 12.9457 5.86925C12.9457 6.15183 12.7151 6.37673 12.4324 6.37673H1.56193C1.27934 6.37673 1.05444 6.15183 1.05444 5.86925C1.05444 5.58668 1.27934 5.36177 1.56193 5.36177ZM1.56193 2.3457H12.4324C12.7151 2.3457 12.9457 2.56485 12.9457 2.84742C12.9457 3.13576 12.7151 3.3549 12.4324 3.3549H1.56193C1.27934 3.3549 1.05444 3.13576 1.05444 2.84742C1.05444 2.56485 1.27934 2.3457 1.56193 2.3457Z"
                fill="white"
                fillOpacity="0.81"
              />
            </svg>
            Insert below
          </Button>
        </MoreSettings>
      </Popover.Content>
    </Popover.Root>
  );
}

const Result = styled.div`
  font-size: 12px;
  line-height: 2;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  padding: 11px 14px;
  max-height: 200px;
  overflow: auto;
  margin-bottom: 0.5rem;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const Content = styled.div`
  width: 31.5rem;
  border-radius: 3px;
  background-color: #292826;
`;

const MoreSettings = styled.div`
  background-color: #292826;
  border-radius: 3px;
  margin-top: 10px;
  padding: 6px 4px;
  max-width: 320px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  background: none;
  border: none;
  padding: 7px 11px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #363433;
  }
`;
