import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { exportConfigState, settingsVisibilityState } from "recoil/export";
import { LinkIcon } from "constants/Svgs";
import { AnimatePresence, motion } from "framer-motion";
import { ExportConfig } from "types/document";
import { toast } from "sonner";

const Container = styled(motion.div)`
  background-color: #292826;
  color: #ffffff;
  padding: 19px 22px;
  width: 300px;
  border-radius: 5px;
  position: absolute;
  top: 100px;
  z-index: 1;
  left: 100px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  opacity: 0.5;
  margin-bottom: 10px;
`;

const SettingRow = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 10px;
`;

const SettingLabel = styled.span`
  font-size: 12px;
  line-height: 24px;
  min-width: 92px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-right: 0.375rem;
`;

const SaveButton = styled.button`
  background-color: #343231;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 7px 9px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const SaveIcon = styled.span`
  margin-right: 5px;
`;

const Spacer = styled.div`
  height: 1px;
  background-color: #363433;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 11px;
`;

const SettingInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 30px;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  text-align: right;
`;

const Select = styled.select`
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  appearance: none;
  padding: 2px 2px 2px 0;
  cursor: pointer;
  width: 35px;
  text-align: end;

  &:focus {
    outline: none;
  }
`;

const Suffix = styled.span`
  color: #ffffff;
  font-size: 12px;
`;

interface SettingInputProps {
  value: number;
  onChange: (value: string) => void;
  suffix: string;
}

const SettingInput: React.FC<SettingInputProps> = ({
  value,
  onChange,
  suffix,
}) => (
  <SettingInputContainer>
    <Input
      type="text"
      value={value?.toString()}
      onChange={(e) => {
        const newValue = e.target.value;
        // Allow empty string, numbers, and numbers with one decimal point at any position
        if (newValue === "" || /^-?\d*\.?\d*$/.test(newValue)) {
          onChange(newValue);
        }
      }}
      onBlur={(e) => {
        const numValue = parseFloat(e.target.value);
        if (
          isNaN(numValue) &&
          e.target.value !== "" &&
          e.target.value !== "." &&
          e.target.value !== "-"
        ) {
          onChange(value.toString());
        } else {
          onChange(e.target.value);
        }
      }}
    />
    <Suffix>{suffix}</Suffix>
  </SettingInputContainer>
);

const FormatSettings: React.FC = () => {
  const [visible, setVisible] = useRecoilState(settingsVisibilityState);
  const [globalConfig, setGlobalConfig] = useRecoilState(exportConfigState);
  const [config, setConfig] = useState(globalConfig);
  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof ExportConfig, string>>
  >({});
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setVisible]);

  const handleChange = (key: keyof typeof config, value: string) => {
    if (key === "font") {
      setConfig((prev) => ({ ...prev, [key]: value }));
    } else {
      // Check if the value is a valid number or a partial number input
      if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
        setConfig((prev) => ({ ...prev, [key]: value }));
      }
      // If it's a complete valid number, convert it to a number type
      if (/^-?\d+(\.\d+)?$/.test(value)) {
        const numValue = parseFloat(value);
        setConfig((prev) => ({ ...prev, [key]: numValue }));
      }
    }
  };

  const validateConfig = (config: ExportConfig): boolean => {
    const errors: Partial<Record<keyof ExportConfig, string>> = {};

    // Text size validation (6pt to 72pt)
    if (config.text < 6 || config.text > 72) {
      errors.text = "Text size must be between 6pt and 72pt";
    }

    // Heading sizes validation (10pt to 100pt)
    ["heading1", "heading2", "heading3"].forEach((heading) => {
      const size = config[heading as keyof ExportConfig] as number;
      if (size < 6 || size > 100) {
        errors[
          heading as keyof ExportConfig
        ] = `${heading} size must be between 10pt and 100pt`;
      }
    });

    // Spacing validations (0.5cm to 10cm)
    ["leftSpacing", "rightSpacing", "verticalSpacing"].forEach((spacing) => {
      const value = config[spacing as keyof ExportConfig] as number;
      if (value < 0.5 || value > 15) {
        errors[
          spacing as keyof ExportConfig
        ] = `${spacing} must be between 0.5cm and 15cm`;
      }
    });

    // Line spacing validation (1 to 3)
    if (![1, 1.2, 1.5, 2].includes(config.lineSpacing)) {
      errors.lineSpacing = "Line spacing must be between 1 and 3";
    }

    const isError = Object.values(errors).length !== 0;

    if (isError) {
      toast.error(
        <div>
          <p>Validation failed. Please correct the following errors:</p>
          <br />
          {Object.values(errors).map((e) => (
            <p>{e}</p>
          ))}
        </div>
      );
    }

    return !isError;
  };

  const handleSave = () => {
    if (validateConfig(config)) {
      setGlobalConfig(config);
      setVisible(false);
    }
  };

  return (
    <AnimatePresence>
      {visible && (
        <Container
          ref={containerRef}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
        >
          <Section>
            <SectionTitle>Format Einstellungen</SectionTitle>
            <Spacer />
            <SettingRow>
              <SettingLabel>Linke Seite</SettingLabel>
              <SettingInput
                value={config.leftSpacing}
                onChange={(value) => handleChange("leftSpacing", value)}
                suffix="cm"
              />
            </SettingRow>
            <Spacer />
            <SettingRow>
              <SettingLabel>Rechte Seite</SettingLabel>
              <SettingInput
                value={config.rightSpacing}
                onChange={(value) => handleChange("rightSpacing", value)}
                suffix="cm"
              />
            </SettingRow>
            <Spacer />
            <SettingRow>
              <SettingLabel>Vertikale Seite</SettingLabel>
              <SettingInput
                value={config.verticalSpacing}
                onChange={(value) => handleChange("verticalSpacing", value)}
                suffix="cm"
              />
            </SettingRow>
          </Section>

          <Section>
            <SectionTitle>Überschrift Einstellungen</SectionTitle>
            <Spacer />
            {["heading1", "heading2", "heading3"].map((heading, index) => (
              <SettingRow key={heading}>
                <SettingLabel>{`Überschirft${index + 1}`}</SettingLabel>
                <SettingInput
                  value={config[heading as keyof typeof config] as number}
                  onChange={(value) =>
                    handleChange(heading as keyof typeof config, value)
                  }
                  suffix="pt"
                />
              </SettingRow>
            ))}
          </Section>

          <Section>
            <SectionTitle>Text Einstellungen</SectionTitle>
            <Spacer />
            <SettingRow>
              <SettingLabel>Text</SettingLabel>
              <SettingInput
                value={config.text}
                onChange={(value) => handleChange("text", value)}
                suffix="pt"
              />
            </SettingRow>
            <SettingRow>
              <SettingLabel>Zeilenabstand</SettingLabel>
              <Select
                value={config.lineSpacing.toString()}
                onChange={(e) => handleChange("lineSpacing", e.target.value)}
              >
                <option value="1">1</option>
                <option value="1.2">1.2</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
              </Select>
            </SettingRow>
          </Section>
          <Spacer />
          <SaveButton onClick={handleSave}>
            <SaveIcon>
              <LinkIcon />
            </SaveIcon>
            Einstellungen speichern
          </SaveButton>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default FormatSettings;
