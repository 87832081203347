import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function getAllModules(): Promise<{ name: string }[]> {
  const resp = await axiosWithAuth.get(
    `${process.env.REACT_APP_NOORIOO_API}/module/list`
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
