import axiosWithAuth from "lib/utils/axiosWithAuth";

interface ImproveTextArgs {
  aim: string;
  text: string;
  style?: string;
}

export default async function improveText(
  payload: ImproveTextArgs
): Promise<{ success: boolean; improvedText: string }> {
  const resp = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/writing/improve`,
    payload
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
