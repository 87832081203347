import { HomeWorkDataType } from "types/document";
import { parseISO } from "date-fns";
import { formatDottedDate } from "lib/utils/date";

interface GroupedHomeWorks {
  [key: string]: HomeWorkDataType[];
}

export default function groupHomeWorksByDate(
  homeWorks: HomeWorkDataType[]
): GroupedHomeWorks {
  const groupedHomeWorks: GroupedHomeWorks = {};

  homeWorks.forEach((homeWork) => {
    const date = parseISO(homeWork.createdAt.toString());
    const formattedDate = formatDottedDate(date);

    if (!groupedHomeWorks[formattedDate]) {
      groupedHomeWorks[formattedDate] = [];
    }
    groupedHomeWorks[formattedDate].push(homeWork);
  });

  return groupedHomeWorks;
}
