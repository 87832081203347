import React from "react";
import { MainContainerGrid } from "../../styles/Layout";
import Header from "./Header";
import Footer from "./Footer";

export default function ContainerHOC({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <MainContainerGrid>
      <Header />
      {children}
      <Footer />
    </MainContainerGrid>
  );
}
