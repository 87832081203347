import axiosWithAuth from "lib/utils/axiosWithAuth";
import { TldrAnswerType } from "types/question";

export default async function searchTldr(
  query: string
): Promise<TldrAnswerType> {
  const response = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/search/tldr-answer`,
    {
      query,
    }
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
