import { cn } from "lib/utils";

export const DropdownCategoryTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="text-[0.625rem] font-normal mb-[8px] text-neutral-500 dark:text-neutral-400 px-1.5">
      {children}
    </div>
  );
};

export const DropdownButton = ({
  children,
  isActive,
  onClick,
  disabled,
  className,
  variant = "primary",
}: {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  variant?: "primary" | "white";
}) => {
  const buttonClass = cn(
    `flex items-center gap-2 
      ${variant === "white" ? "px-2" : "px-1.5"}
    py-[6px] text-[0.75rem] font-400 ${
      variant === "primary"
        ? "text-[rgba(255,255,255,.8)]"
        : "text-[rgba(255,255,255,.8)]"
    } text-left bg-transparent w-full rounded`,
    !isActive && !disabled,
    "hover:bg-[rgba(0,0,0,.1)] hover:text-[rgba(255,255,255,.8]",
    isActive && !disabled && "text-[rgba(255,255,255,.6)] bg-[rgba(0,0,0,.1)]",
    disabled && "text-neutral-400 cursor-not-allowed dark:text-neutral-600",
    className
  );

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};
