import React, { ChangeEvent, useRef } from "react";
import styled from "styled-components";

interface InputProps {
  placeholder: string;
  value: string | number;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  variant?: "primary" | "black";
  isExpandable?: boolean;
  type?: string;
  disabled?: boolean;
  isBottomBorderRadius?: boolean;
}

export default function Input({
  disabled,
  placeholder,
  variant = "primary",
  value,
  isExpandable = false,
  type = "text",
  onChange,
  isBottomBorderRadius = true,
}: InputProps) {
  const inputRef = useRef<any>(null);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value);
    if (isExpandable && inputRef.current) {
      inputRef.current.style.height = "24px";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  return (
    <InputContainer
      $variant={variant}
      $isBottomBorderRadius={isBottomBorderRadius}
    >
      {!isExpandable ? (
        <StyledInput
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          type={type}
          disabled={disabled}
        />
      ) : (
        <StyledTextArea
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </InputContainer>
  );
}

const InputContainer = styled.div<{
  $variant: string;
  $isBottomBorderRadius: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  background-color: ${(props) =>
    props.$variant === "black" ? "#191719" : "#292826"};
  padding: 6px 0;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  font-family: ${(props) => (props.$variant === "black" ? "Inter" : "inherit")};

  ${({ $isBottomBorderRadius }) =>
    !$isBottomBorderRadius
      ? `
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;`
      : ""}

  &:focus-within {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
`;

const StyledInput = styled.input`
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  padding: 4px 12px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const StyledTextArea = styled.textarea`
  background-color: transparent;
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  padding: 4px 12px;
  font-size: 0.75rem;
  line-height: 1rem;
  max-height: 8.3125rem;
  height: 24px;
  overflow-y: auto;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;
