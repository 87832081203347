import { Extension } from "@tiptap/react";

export const CustomEnter = Extension.create({
  name: "customEnter",

  priority: 180, // High priority to override other extensions

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      Enter: ({ editor }) => {
        return editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => {
            if (editor.isActive("listItem")) {
              return commands.splitListItem("listItem");
            }
            // Split the block at the current cursor position
            return commands.splitBlock();
          },
        ]);
      },
      "Shift-Enter": ({ editor }) => {
        return editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => {
            // Split the block at the current cursor position
            return commands.splitBlock();
          },
        ]);
      },
    };
  },
});
