import Modal from "./Modal";
import { useRecoilState, useRecoilValue } from "recoil";
import { plansModalState } from "../../recoil/modal";
import styled from "styled-components";

import {
  Container,
  Header,
  MediumText,
  Subtitle,
  Title,
} from "./Modal/commonStyles";
import { Plan120, Plan15, PlanBg, PlanBg2 } from "../../constants/Svgs";
import Button from "./Button";
import Spacer from "./Spacer";
import getBuyLinkForPlan from "lib/plans/getBuyLinkForPlan";
import { useState } from "react";
import CircularLoader from "./CircularLoader";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import { userDataState } from "recoil/auth";

type PlanType = "monthly" | "yearly";

const purchaseButtonText: { [key: string]: string } = {
  monthly: `Starten Sie mit 15€ pro Monat`,
  yearly: `Starten Sie mit 120€ pro Jahr`,
};

export default function PlansModal({ upgrade }: { upgrade?: boolean }) {
  const [open, setOpen] = useRecoilState(plansModalState);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanType>("monthly");
  const userData = useRecoilValue(userDataState);
  const hasCredits = userData?.credits > 0;

  const executeSubscriptionIntent = async (plan: PlanType) => {
    if (loading) return;
    try {
      setLoading(true);
      const { url } = await getBuyLinkForPlan(plan);
      window.location.href = url;
    } catch (e) {
      axiosErrorHandler(e);
    } finally {
      setLoading(true);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} hideClose={!upgrade}>
      <Container>
        <Header>
          {hasCredits ? (
            <>
              <Title>Denken Sie darüber nach. Machen Sie es.</Title>
              <Subtitle>Verbessern Sie Ihr Noorioo-Erlebnis.</Subtitle>
            </>
          ) : (
            <>
              <Title>Ihre kostenlosen Credits</Title>
              <Subtitle>sind aufgebraucht. Upgraden Sie jetzt.</Subtitle>
            </>
          )}
        </Header>
        <PlansGrid>
          <Plan1
            onClick={() => setSelectedPlan("monthly")}
            $isSelected={selectedPlan === "monthly"}
          >
            <PlanBg />
            <Content>
              <PriceContainer>
                <Plan15 />
              </PriceContainer>
              <PlanDetails>
                Erhalten Sie Zugang zu Noorioo, ganz einfach - unbegrenzt.
              </PlanDetails>
            </Content>
          </Plan1>
          <Plan2
            onClick={() => setSelectedPlan("yearly")}
            $isSelected={selectedPlan === "yearly"}
          >
            <PlanBg2 />
            <Content>
              <PriceContainer>
                <Plan120 />
              </PriceContainer>
              <PlanDetails>
                <Text>
                  Noorioo Jahresabonnement mit 50% Rabatt, das sind{" "}
                  <span>2 Monate kostenlos.</span>
                </Text>
              </PlanDetails>
            </Content>
          </Plan2>
        </PlansGrid>
        <Button
          variant="white"
          center={false}
          onClick={() => executeSubscriptionIntent(selectedPlan)}
        >
          {loading ? (
            <CircularLoader loading={loading} />
          ) : (
            purchaseButtonText[selectedPlan]
          )}
        </Button>
        <Spacer height={16} />
        <MediumText>
          Es fallen keine Gebühren an, bis Ihre Testphase abläuft,
          <br /> und Sie können jederzeit kündigen.
        </MediumText>
      </Container>
    </Modal>
  );
}

const PlansGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.875rem;
  margin-top: 2.375rem;
  margin-bottom: 0.9375rem;
`;

const Plan1 = styled.div<{ $isSelected: boolean }>`
  position: relative;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected
      ? `
      transform: translateY(-0.7125rem); 
      transition-duration: 200ms; 
      transition-timing-function: ease-in-out;`
      : ""}
`;

const Plan2 = styled.div<{ $isSelected: boolean }>`
  position: relative;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected
      ? `
      transform: translateY(-0.7125rem); 
      transition-duration: 200ms; 
      transition-timing-function: ease-in-out;`
      : ""}
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const PlanDetails = styled.div`
  height: 5rem;
  color: #fff;
  opacity: 0.5;
  font-size: 0.625rem;
  line-height: 1.0625rem;
  text-align: center;
  max-width: 9.75rem;
  margin: 0 auto;
  margin-top: auto;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.3438rem;
`;

const Text = styled.p`
  span {
    font-weight: 700;
  }
`;

const PriceContainer = styled.div`
  height: 10rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
