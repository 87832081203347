import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import axiosWithAuth from "lib/utils/axiosWithAuth";
import { useRecoilState } from "recoil";
import { userDataState } from "recoil/auth";
import { UserDataType } from "types/user";

export default function useSettings() {
  const [userData, setUserData] = useRecoilState(userDataState);
  const settings = userData?.settings;

  const updateSetting = async (
    property: keyof UserDataType["settings"],
    value: boolean
  ) => {
    try {
      const resp = await axiosWithAuth.post(
        `${process.env.REACT_APP_NOORIOO_API}/user/settings`,
        {
          [property]: value,
        }
      );

      if (!resp.data) {
        throw new Error("Some error occured");
      }
      if (resp.data.error) {
        throw new Error(resp.data.error);
      }

      setUserData(resp?.data);
    } catch (e) {
      axiosErrorHandler(e);
    }
  };

  const updateMultipleSettings = async (
    settingsToUpdate: Partial<UserDataType["settings"]>
  ) => {
    try {
      const resp = await axiosWithAuth.post(
        `${process.env.REACT_APP_NOORIOO_API}/user/settings`,
        settingsToUpdate
      );

      if (!resp.data) {
        throw new Error("Some error occurred");
      }
      if (resp.data.error) {
        throw new Error(resp.data.error);
      }

      setUserData(resp?.data);
    } catch (e) {
      axiosErrorHandler(e);
    }
  };

  return { settings, updateSetting, updateMultipleSettings };
}
