import React from "react";
import styled from "styled-components";
import { LeftPad } from "../../styles/Layout";
import { EditorHeaderProps } from "../BlockEditor/components/EditorHeader";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { lastEditedTimeState } from "recoil/homework";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import { settingsVisibilityState } from "recoil/export";
import Tippy from "@tippyjs/react";

const InfoIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 10C6.55228 10 7 9.55226 7 9C7 8.44772 6.55228 8 6 8C5.44772 8 5 8.44772 5 9C5 9.55226 5.44772 10 6 10ZM5.10999 4.2V4C5.11738 3.73959 5.20274 3.52263 5.36737 3.37451C5.532 3.2264 5.7512 3.15234 6.02497 3.15234C6.60211 3.15234 6.89067 3.42741 6.89067 3.97754C6.89067 4.49442 6.51795 4.87516 6.18313 5.13721C5.84831 5.39925 5.53995 5.66536 5.41416 5.93554C5.31583 6.14676 5.25593 6.41193 5.23447 6.73C5.2317 6.77103 5.22941 6.82684 5.22757 6.9C5.22757 6.95535 5.27244 7.00023 5.32779 7.00023L6.65418 7C6.70628 7 6.74964 6.96001 6.75386 6.90809L6.76859 6.72657C6.80929 6.40755 6.94802 6.12923 7.22919 5.89161C7.72917 5.43261 8.08249 5.03467 8.28912 4.69775C8.43716 4.4564 8.5 4.19076 8.5 3.91407C8.5 3.30533 8.28357 2.83415 7.85073 2.50049C7.41787 2.16683 6.78432 2 6 2C5.22309 2 4.63485 2.17578 4.18535 2.52735C3.74024 2.87547 3.51188 3.36852 3.50028 3.98182L3.5 4.2C3.5 4.25523 3.54477 4.3 3.6 4.3H5.00998C5.06522 4.3 5.10999 4.25523 5.10999 4.2Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default function Words({ characters, words, pages }: EditorHeaderProps) {
  const lastEditedTime = useRecoilValue(lastEditedTimeState);
  const setFormatSettings = useSetRecoilState(settingsVisibilityState);
  const editedTimeAgo = lastEditedTime
    ? `Bearbeitet ${formatDistanceToNow(lastEditedTime, {
        addSuffix: true,
        locale: de,
      })}`
    : "";

  const handleSettingsClick = () => {
    setFormatSettings(true);
  };

  return (
    <Wrapper $background="rgba(28, 27, 27, 0.5)">
      <WordsText onClick={handleSettingsClick}>
        {words} <span>Wörter</span> / {pages} <span>Seiten</span>
        <Tippy
          content={
            <TooltipContent>
              <TooltipTitle>Seitenformat</TooltipTitle>
              <p>
                Es kann auftreten, dass es zu falschen Formatierungen für die
                gesamt beschriebenen Seiten vorkommen kann. Es kommt höchstens
                zwischen 0.5 Seiten Differenzproblemen.
              </p>
            </TooltipContent>
          }
          theme="custom"
        >
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
        </Tippy>
      </WordsText>

      <LastEditedText>{editedTimeAgo}</LastEditedText>
    </Wrapper>
  );
}

const Wrapper = styled(LeftPad)`
  padding-right: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #292826;
  border-left: 0;
  border-right: 0;
`;

const WordsText = styled.p`
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    opacity: 0.5;
  }
`;

const IconWrapper = styled.span`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const TooltipContent = styled.div`
  padding: 10px 16px 14px 16px;
  text-align: left;
  background-color: #292826;
  border-radius: 2px;

  p {
    color: #ffffff99;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #292826 transparent;
  }
`;

const TooltipTitle = styled.div`
  margin: 0 0 8px 0;
  color: #ededed;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
`;

const LastEditedText = styled.p`
  font-size: 0.75rem;
  opacity: 0.5;
  color: #fff;
`;
