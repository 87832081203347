import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: relative;
  z-index: 10;
  background-color: #212121;
`;

const BorderContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const LinkContainer = styled.div`
  display: flex;
  gap: 16px;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Link = styled.a`
  &:hover {
    opacity: 0.6;
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  opacity: 0.6;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <div>
        <BorderContainer>
          <LinkContainer>
            {["Über uns", "Team", "Noorioo", "Blog"].map((item, index) => (
              <Link key={index} href="#">
                {item}
              </Link>
            ))}
          </LinkContainer>
        </BorderContainer>
        <div>
          <Copyright>
            © 2024 Noorioo Pte. Ltd. (201227417H) 16 Raffles Quay, #33-03 Hong
            Leong Building, Singapore 048581
          </Copyright>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
