import { DropdownButton } from "components/ui/Dropdown";
import { Icon } from "components/ui/Icon";
import { Surface } from "components/ui/Surface";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { Toolbar } from "components/ui/Toolbar";
import CircularLoader from "components/General/CircularLoader";

export type WritingOption = {
  name: string;
  label: string;
  onClick?: any;
};

export type AiToneOption = {
  name: string;
  label: string;
  onClick?: any;
};

export const writingOptions: WritingOption[] = [
  { name: "Casual", label: "Casual" },
  { name: "Normal", label: "Normal" },
  { name: "Professional", label: "Professional" },
  { name: "Intrepreneur", label: "Intrepreneur" },
  { name: "Change tone", label: "Change tone" },
];

const tones: WritingOption[] = [
  { name: "Gutachtenstil", label: "Gutachtenstil", onClick: () => {} },
  { name: "Urteilsstil", label: "Urteilsstil", onClick: () => {} },
];

export const vereinfaches: AiToneOption[] = [
  { name: "Jura", label: "Jura", onClick: () => {} },
  { name: "BWL", label: "BWL", onClick: () => {} },
  { name: "VWL", label: "VWL", onClick: () => {} },
];

export type AIDropdownProps = {
  onImprove?: (aim: string, style?: string) => void;
  onSimplify?: () => void;
  onFixSpelling?: () => void;
  onMakeShorter?: () => void;
  onMakeLonger?: () => void;
  onEmojify?: () => void;
  onTldr?: () => void;
  onTranslate?: (language: string) => void;
  onTone?: (tone: string) => void;
  onCompleteSentence?: () => void;
  loading: boolean;
};

export const AIDropdown = ({ onImprove, loading }: AIDropdownProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          className="text-[#A782C3] hover:text-purple-600 active:text-purple-600 dark:text-purple-400 dark:hover:text-purple-300 dark:active:text-purple-400"
          activeClassname="text-purple-600 hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-200"
        >
          {loading ? <CircularLoader size={15} loading={loading} /> : "KI"}
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface
          className="py-2 min-w-[250px] mt-4"
          style={{
            borderRadius: 3,
            border: 0,
          }}
        >
          <Dropdown.Item className="px-2 border-none outline-none">
            <div className="text-[#808080] text-[0.625rem] px-1 mb-2 mt-1">
              Edit or review text
            </div>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger className="px-2 border-none outline-none">
              <DropdownButton className="text-[0.625rem] pl-3" variant="white">
                Improve writing
                <Icon
                  name="ChevronRight"
                  className="w-[0.75rem] h-[0.75rem] ml-auto stroke-[#808080]"
                />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface
                style={{
                  borderRadius: 3,
                  border: 0,
                }}
                className="flex flex-col min-w-[9.6875rem] p-2 px-0 max-h-[20rem] overflow-auto"
              >
                {writingOptions.map((option) => (
                  <Dropdown.Item
                    className="px-2 border-none outline-none"
                    onClick={() => onImprove("Improve writing", option.name)}
                    key={option.name}
                  >
                    <DropdownButton className="text-[0.625rem]" variant="white">
                      {option.label}
                    </DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          <Dropdown.Sub>
            <Dropdown.SubTrigger className="px-2 border-none outline-none">
              <DropdownButton className="text-[0.625rem] pl-3" variant="white">
                Vereinfache
                <Icon
                  name="ChevronRight"
                  className="w-[0.75rem] h-[0.75rem] ml-auto stroke-[#808080]"
                />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface
                className="flex flex-col min-w-[9.6875rem] p-2 px-0 max-h-[20rem] overflow-auto"
                style={{
                  borderRadius: 3,
                  border: 0,
                }}
              >
                {vereinfaches.map((tone) => (
                  <Dropdown.Item
                    className="px-2 border-none outline-none"
                    onClick={() => onImprove("Vereinfache", tone.name)}
                    key={tone.name}
                  >
                    <DropdownButton className="text-[0.625rem]" variant="white">
                      {tone.label}
                    </DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          <Dropdown.Item
            className="px-2 border-none outline-none"
            onClick={() => onImprove("Make shorter")}
          >
            <DropdownButton className="text-[0.625rem] pl-3" variant="white">
              Make shorter
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 border-none outline-none"
            onClick={() => onImprove("Make longer")}
          >
            <DropdownButton className="text-[0.625rem] pl-3" variant="white">
              Make longer
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger className="px-2 border-none outline-none">
              <DropdownButton className="text-[0.625rem] pl-3" variant="white">
                Change tone
                <Icon
                  name="ChevronRight"
                  className="w-[0.75rem] h-[0.75rem] ml-auto stroke-[#808080]"
                />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface
                className="flex flex-col min-w-[9.6875rem] p-2 px-0 max-h-[20rem] overflow-auto"
                style={{
                  borderRadius: 3,
                  border: 0,
                }}
              >
                {tones.map((tone) => (
                  <Dropdown.Item
                    className="px-2 border-none outline-none"
                    onClick={tone.onClick}
                    key={tone.name}
                  >
                    <DropdownButton className="text-[0.625rem]" variant="white">
                      {tone.label}
                    </DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          <div className="h-[1px] bg-[#808080] opacity-10 mt-2 mb-3" />
          <Dropdown.Item className="px-2 border-none outline-none">
            <div className="text-[#808080] text-[0.625rem] px-1 mb-2 mt-1">
              Improve with AI
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 border-none outline-none"
            onClick={() => onImprove("Check Prüfungsschema")}
          >
            <DropdownButton className="text-[0.625rem] pl-3" variant="white">
              Check Prüfungsschema
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 border-none outline-none"
            onClick={() => onImprove("Emojify")}
          >
            <DropdownButton className="text-[0.625rem] pl-3" variant="white">
              Emojify
            </DropdownButton>
          </Dropdown.Item>
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
