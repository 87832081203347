import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { manageModalState, verfiyEmailModalState } from "recoil/modal";
import Modal from "./Modal";
import { Container, Header, Subtitle, Title } from "./Modal/commonStyles";
import { Back, BackText } from "./SettingsModal";
import { BackIcon, ChevronRight } from "constants/Svgs";
import Spacer from "./Spacer";
import Divider from "./Divider";
import styled from "styled-components";
import IOSStyleSwitch from "./Switch";

export default function ManageModal() {
  const [open, setOpen] = useRecoilState(manageModalState);
  const setVerifyEmail = useSetRecoilState(verfiyEmailModalState);
  const [checked, setChecked] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose} hideClose maxWidth="550px">
      <Back onClick={handleClose}>
        <BackIcon />
        <BackText>Zurück</BackText>
      </Back>
      <Container>
        <Header>
          <Title>Mange it.</Title>
          <Subtitle>Manage your citations easily.</Subtitle>
        </Header>
        <Spacer height={40} />
        <Divider noMargin />
        <Spacer height={29} />
        <SmHeader>
          <Title>Beck Online</Title>
          <Subtitle>Your results will show Beck results.</Subtitle>
        </SmHeader>
        <Row>
          <IOSStyleSwitch
            checked={checked}
            onChange={(e) => {
              setChecked(e);
            }}
          />
          <SmText>
            By enabling this option,
            <br /> you will export always with your pages numberized.
          </SmText>
        </Row>
        <Spacer height={40} />
        <Divider noMargin />
        <Spacer height={29} />
        <SpaceBetween>
          <SmHeader>
            <Title>BeckOnline</Title>
            <Subtitle>Verwalte Deine BeckOnline Einstellungen</Subtitle>
          </SmHeader>
          <ChevronRight />
        </SpaceBetween>
        <Box>
          <BoldText>
            Verify Email
            <Flag>Feature</Flag>
          </BoldText>

          <StyledText>
            Verifiziere deine Universitätsemail und erhalte Zugang zu allen
            Quellen für deine wissenschaftliche Arbeit.
          </StyledText>
          <SitesImage src={require("../../assets/images/sites2.png")} />
          <Separate />
          <Row>
            <Verify onClick={() => setVerifyEmail(true)}>
              Universitätsemail verifizieren
            </Verify>
            <StyledText
              style={{
                fontSize: 12,
                margin: 0,
              }}
            >
              Du kannst diesen Schritt auch später in Deinen Einstellungen
              nachholen.
            </StyledText>
          </Row>
        </Box>
      </Container>
    </Modal>
  );
}

const Box = styled.div`
  background: #343231;
  padding: 24px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 32px;
`;

const BoldText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

const SmHeader = styled(Header)`
  font-size: 0.75rem;
  line-height: 1.25;
`;

const Row = styled.div<{ $gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.$gap || "0.625rem"};
  margin-top: 1.125rem;
`;

const SitesImage = styled.img`
  height: 1.875rem;
  object-fit: contain;
`;

const Text = styled.p`
  margin-top: 0.75rem;
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.0625rem;
`;

const SmText = styled(Text)`
  font-size: 0.625rem;
  line-height: 0.875rem;
  opacity: 1;
  margin: 0;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.9375rem;
  cursor: pointer;

  &:hover {
    svg path {
      stroke-opacity: 1;
    }
  }
`;

const Flag = styled.span`
  background: #784a15;
  border-radius: 3px;
  margin-left: 8px;
  font-weight: 400;
  font-family: "Arial";
  font-size: 12px;
  padding: 2px 4px;
  margin-bottom: 8px;
`;

const StyledText = styled.div`
  font-size: 14px;
  opacity: 0.6;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 21px;
`;

const Separate = styled.div`
  margin: 22px 0;
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 1px;
`;

const Verify = styled.div`
  height: 35px;
  width: 120px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.25);

  &:hover {
    border-color: #fff;
  }
`;
