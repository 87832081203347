import styled from "styled-components";

export const Title = styled.div``;

export const Subtitle = styled.div`
  opacity: 0.5;
`;

export const Header = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  line-height: 1.5rem;
`;

export const Container = styled.div`
  padding: 2.9375rem 3.75rem;
`;

export const SmallText = styled.div`
  font-size: 0.625rem;
  line-height: 0.975rem;
  color: rgba(255, 255, 255, 0.5);
`;

export const MediumText = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.05rem;
`;
