import React from "react";
import styled from "styled-components";
import NewHeader from "./NewHeader";
import NewFooter from "./NewFooter";

export default function CustomLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Grid>
      <NewHeader />
      <Content>{children}</Content>
      <NewFooter />
    </Grid>
  );
}
const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

const Content = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
