import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import axiosWithAuth from "lib/utils/axiosWithAuth";
import { useRecoilState } from "recoil";
import { userDataState } from "recoil/auth";
import { UserDataType } from "types/user";

export default function useOnboarding() {
  const [userData, setUserData] = useRecoilState(userDataState);
  const onboarding = userData?.onboarding;

  const updateOnboarding = async (
    property: keyof UserDataType["onboarding"]
  ) => {
    try {
      const resp = await axiosWithAuth.post(
        `${process.env.REACT_APP_NOORIOO_API}/user/onboarding`,
        {
          [property]: true,
        }
      );

      if (!resp.data) {
        throw new Error("Some error occured");
      }
      if (resp.data.error) {
        throw new Error(resp.data.error);
      }

      setUserData(resp?.data);
    } catch (e) {
      axiosErrorHandler(e);
    }
  };

  return { onboarding, updateOnboarding };
}
