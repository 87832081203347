import { User } from "@supabase/supabase-js";
import { decode } from "jsonwebtoken";
import getUserData from "lib/user/getUserData";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import { isTokenExpired } from "lib/utils/jwt";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userState, userDataState } from "recoil/auth";
import { toast } from "sonner";
import supabase from "supabase";

export const storageKey = `sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`;

export default function useUser() {
  // Contains the user received from Supabase
  const [user, setUser] = useRecoilState(userState);
  const setUserData = useSetRecoilState(userDataState);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
        setUser(session?.user || null);
        localStorage.setItem("user", JSON.stringify(session?.user));

        // Fetch user data and set it in Recoil
        fetchUserFromDB().then((userData) => setUserData(userData));
      } else if (event === "SIGNED_OUT") {
        setUser(null);
        setUserData(undefined);
        localStorage.removeItem("user");
        localStorage.removeItem(storageKey);
      }
    });

    // Get the initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      const user = session?.user || null;
      setUser(user);
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { user, setUser };
}

// This function gets the token from local storage.
// Supabase stores the token in local storage so we can access it from there.
export const getToken = async () => {
  const sessionDataString = localStorage.getItem(storageKey);
  const sessionData = JSON.parse(sessionDataString || "null");
  const token = sessionData?.access_token;

  if (!token) return null;

  if (isTokenExpired(token)) {
    console.log("Token is expired, refresh the session");
    const { data, error } = await supabase.auth.refreshSession();

    if (error) {
      console.error("Error refreshing session:", error.message);
      return null;
    }

    const { session, user } = data;
    if (session) {
      localStorage.setItem(storageKey, JSON.stringify(session));
      return session.access_token;
    } else {
      return null;
    }
  }

  return token;
};

export const logout = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    throw error;
  } else {
    console.log("Successfully logged out");
    localStorage.removeItem("user");
    // Optionally redirect or take additional actions
    window.location.href = "/auth"; // Redirect to the auth page after logout
  }
};

const fetchUserFromDB = async () => {
  try {
    // console.log("Fetching user from DB");
    const userData = await getUserData();
    return userData;
  } catch (e) {
    axiosErrorHandler(e);
    return null;
  }
};

export function useRefreshUser() {
  const setUserData = useSetRecoilState(userDataState);

  return async () => {
    fetchUserFromDB().then((userData) => setUserData(userData));
  };
}
