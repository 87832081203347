import { Editor } from "@tiptap/react";
import { Group } from "./types";
import header1Icon from "../../assets/images/editor/header1.png";
import header2Icon from "../../assets/images/editor/header2.png";
import header3Icon from "../../assets/images/editor/header3.png";
import numberListIcon from "../../assets/images/editor/numberList.png";
import bulletIcon from "../../assets/images/editor/bulletList.png";
import quoteIcon from "../../assets/images/editor/quote.png";
import todoIcon from "../../assets/images/editor/todo.png";
import calloutIcon from "../../assets/images/editor/callout.png";
import findeZitate from "../../assets/images/editor/findeZitate.png";
import integrateZitate from "../../assets/images/editor/integrateZitate.png";
import { openFindeZitate } from "lib/utils/openFindeZitate";
import { openIntegrateZitat } from "lib/utils/openIntegrateZitat";

export const GROUPS: Group[] = [
  {
    name: "",
    title: "",
    commands: [
      {
        name: "heading1",
        label: "Überschrift1",
        iconName: header1Icon,
        description: "High priority section title",
        aliases: ["h1"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: "heading2",
        label: "Überschrift2",
        iconName: header2Icon,
        description: "Medium priority section title",
        aliases: ["h2"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: "heading3",
        label: "Überschrift3",
        iconName: header3Icon,
        description: "Low priority section title",
        aliases: ["h3"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: "bulletList",
        label: "Aufzählungsliste",
        iconName: bulletIcon,
        description: "Unordered list of items",
        aliases: ["ul"],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: "numberedList",
        label: "Nummerierte Liste",
        iconName: numberListIcon,
        description: "Ordered list of items",
        aliases: ["ol"],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: "taskList",
        label: "Aufgabnliste",
        iconName: todoIcon,
        description: "Task list with todo items",
        aliases: ["todo"],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run();
        },
      },
      {
        name: "blockquote",
        label: "Zitat",
        iconName: quoteIcon,
        description: "Element for quoting",
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
      {
        iconName: calloutIcon,
        action: (editor) =>
          editor
            .chain()
            .focus()
            .toggleNode("heading", "paragraph", {
              level: 6,
            })
            .run(),
        label: "Callout",
        name: "callout",
        description: "Callout section",
      },
      {
        name: "findeZitate",
        label: "Finde Zitate",
        iconName: findeZitate,
        description: "Search for citations",
        action: (editor: Editor) => {
          openFindeZitate(editor);
        },
      },
      {
        name: "integrateZitat",
        label: "Integriere Zitat",
        iconName: integrateZitate,
        description: "Integrate a citation",
        action: (editor: Editor) => {
          openIntegrateZitat(editor);
        },
      },
    ],
  },
];

export default GROUPS;
