import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const currentHomeWorkIdState = atom<string>({
  key: "currentHomeWorkIdState",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const currentHomeWorkContentState = atom<string>({
  key: "currentHomeWorkContentState",
  default: "",
});

export const lastEditedTimeState = atom<Date>({
  key: "lastEditedTimeState",
  default: undefined,
});
