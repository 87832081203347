import { saveAs } from "file-saver";
import { renderAsync } from "docx-preview";
import { ExportConfig } from "types/document";
import { createDocFromHtml } from "./docx";
import html2pdf from "html2pdf.js";

async function convertDocxToPdf(docxBlob: Blob): Promise<Blob> {
  // Create a FormData object
  const formData = new FormData();

  // Append the DOCX blob to the FormData
  formData.append("docx", docxBlob, "document.docx");

  try {
    // Send the POST request to your Go webservice
    const response = await fetch("https://mc1.noorioo.com/upload", {
      method: "POST",
      body: formData,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the PDF blob from the response
    const pdfBlob = await response.blob();

    // Return the PDF blob
    return pdfBlob;
  } catch (error) {
    console.error("Error converting DOCX to PDF:", error);
    throw error;
  }
}

export async function createPdfFromDocx(
  title: string,
  htmlContent: string,
  exportConfigs: ExportConfig
): Promise<void> {
  try {
    console.log("Creating DOCX...");
    const docxBlob = await createDocFromHtml(htmlContent, exportConfigs);
    console.log("DOCX created, converting to PDF...");
    const pdfBlob = await convertDocxToPdf(docxBlob);
    console.log("Saving PDF...");
    saveAs(pdfBlob, `${title}.pdf`);
    console.log("PDF exported successfully");
  } catch (error) {
    console.error("Error exporting PDF:", error);
    throw error;
  }
}
