import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function getFormattedSources(
  sources: string[]
): Promise<{ output: string[]; metadata: any }> {
  const resp = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/citation/format-sources`,
    {
      sources: JSON.stringify(sources),
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
