import { useMemo } from "react";

import { Editor, useEditor } from "@tiptap/react";
import type { Doc as YDoc } from "yjs";

import { ExtensionKit } from "extensions/extension-kit";
import { EditorUser } from "../components/BlockEditor/types";
import { useSidebar } from "./useSidebar";
import StarterKit from "@tiptap/starter-kit";

declare global {
  interface Window {
    editor: Editor | null;
  }
}

export const useBlockEditor = ({ ydoc }: { ydoc: YDoc }) => {
  const leftSidebar = useSidebar();

  const editor = useEditor(
    {
      autofocus: true,
      onCreate: ({ editor }) => {
        editor.commands.setContent(null);
      },
      extensions: [...ExtensionKit({}), StarterKit],
      editorProps: {
        attributes: {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          class: "min-h-full",
        },
        transformPastedHTML(html) {
          // Create a new DOM parser
          const parser = new DOMParser();
          // Parse the HTML string into a document
          const doc = parser.parseFromString(html, "text/html");

          // Remove font color and font size from all elements
          doc.querySelectorAll("*").forEach((node: HTMLElement) => {
            node.style.color = ""; // Remove font color
            node.style.fontSize = ""; // Remove font size
          });

          // Serialize the document back to a string
          return doc.body.innerHTML;
        },
      },
    },
    [ydoc]
  );

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      const names = user.name?.split(" ");
      const firstName = names?.[0];
      const lastName = names?.[names.length - 1];
      const initials = `${firstName?.[0] || "?"}${lastName?.[0] || "?"}`;

      return { ...user, initials: initials.length ? initials : "?" };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  const characterCount = editor?.storage.characterCount || {
    characters: () => 0,
    words: () => 0,
  };

  window.editor = editor;

  return { editor, users, characterCount, leftSidebar };
};
