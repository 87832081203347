import Modal from "./Modal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  plansModalState,
  settingsModalState,
  verfiyEmailModalState,
} from "../../recoil/modal";
import { Container, Header, Subtitle, Title } from "./Modal/commonStyles";
import Spacer from "./Spacer";
import Divider from "./Divider";
import keyboardImage from "../../assets/images/keyboard.webp";
import settingsImage from "../../assets/images/settings.webp";
import cardImage from "../../assets/images/card.webp";
import styled from "styled-components";
import IOSStyleSwitch from "./Switch";
import { motion } from "framer-motion";
import {
  BackIcon,
  ChevronRight,
  Icon1,
  Icon2,
  Icon3,
} from "../../constants/Svgs";
import {
  SettingsDisplayView,
  settingsModalViewState,
} from "../../recoil/settings";
import Sources from "../HomeWork/Sources";
import useUser, { logout, storageKey } from "hooks/user/useUser";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import useSettings from "hooks/user/useSettings";
import { BeckSettingsType, UserDataType } from "types/user";
import useOnboarding from "hooks/user/useOnboarding";
import { userDataState } from "recoil/auth";
import getPortalLink from "lib/plans/getPortalLink";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import CircularLoader from "./CircularLoader";
import DateRange from "./DateRange";
import MultiSelectInput, {
  Option as MultiSelectOption,
} from "./MultiSelectInput";
import { Option as StyledOption } from "./ExportSettingsModal";
import useBeckSettings from "hooks/user/useBeckSettings";
import { formatDottedDate, parseDottedDate } from "lib/utils/date";
import Button from "./Button";

const getSettingsView = (view: number) => {
  switch (view) {
    case 0:
      return <MainScreen />;
    case 1:
      return <ManageSettings />;
    case 2:
      return <CitationSettings />;
    default:
      return <BeckOnlineSettings />;
  }
};

export default function SettingsModal() {
  const [settingsModalOpen, setSettingsModalOpen] =
    useRecoilState(settingsModalState);
  const [currentView, setSettingsModalViewState] = useRecoilState(
    settingsModalViewState
  );
  const setVerifyEmail = useSetRecoilState(verfiyEmailModalState);
  const { onboarding } = useOnboarding();

  return (
    <Modal
      hideClose
      open={settingsModalOpen}
      onClose={() => setSettingsModalOpen(false)}
    >
      {currentView !== SettingsDisplayView.GENERAL && (
        <Back onClick={() => setSettingsModalViewState((prev) => prev - 1)}>
          <BackIcon />
          <BackText>Zurück</BackText>
        </Back>
      )}
      <Container>
        <Header>
          <Title>Verwalte Es.</Title>
          <Subtitle>Verwalte Dein Konto, ganz einfach.</Subtitle>
        </Header>
        <Spacer height={40} />
        <Divider noMargin />
        <Spacer height={29} />
        {getSettingsView(currentView)}
        {/* 
        {!onboarding?.universityEmail && (
          <Box>
            <BoldText>
              Verify Email
              <Flag>Feature</Flag>
            </BoldText>

            <StyledText>
              Verifiziere deine Universitätsemail und erhalte Zugang zu allen
              Quellen für deine wissenschaftliche Arbeit.
            </StyledText>
            <Svg />
            <Separate />
            <Row>
              <Verify onClick={() => setVerifyEmail(true)}>
                Universitätsemail verifizieren
              </Verify>
              <StyledText
                style={{
                  fontSize: 12,
                  margin: 0,
                }}
              >
                Du kannst diesen Schritt auch später in Deinen Einstellungen
                nachholen.
              </StyledText>
            </Row>
          </Box>
        )} */}
      </Container>
    </Modal>
  );
}

const Box = styled.div`
  background: #343231;
  padding: 24px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 32px;
  margin-left: -35px;
  margin-right: -35px;
`;

const BoldText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

const Flag = styled.span`
  background: #784a15;
  border-radius: 3px;
  margin-left: 8px;
  font-weight: 400;
  font-family: "Arial";
  font-size: 12px;
  padding: 2px 4px;
  margin-bottom: 8px;
`;

const StyledText = styled.div`
  font-size: 14px;
  opacity: 0.6;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 21px;
`;

const Separate = styled.div`
  margin: 22px 0;
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 1px;
`;

const Verify = styled.div`
  height: 35px;
  width: 120px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;

  &:hover {
    border-color: #fff;
  }
`;

const BeckOnlineSettings = () => {
  const { settings, updateSetting } = useSettings();
  const { beckSettings, updateBeckSetting } = useBeckSettings();
  const [localSettings, setLocalSettings] = useState<BeckSettingsType>();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date("01.01.2000")
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectedPubTypes, setSelectedPubTypes] = useState<string[]>([
    "Alle Publikationstypen",
  ]);
  const [loading, setLoading] = useState(false);

  const cleanUpInputs = (value?: string) => {
    if (!value) return [];

    return (
      value
        ?.split(",")
        ?.map((a) => a.trim())
        ?.filter((a) => !!a) || []
    );
  };

  useEffect(() => {
    const dateRange = beckSettings?.date?.split(" - ");
    const startDate = parseDottedDate(dateRange?.[0] || "01.01.2000");
    const endDate = dateRange?.[1]
      ? parseDottedDate(dateRange?.[1])
      : new Date();
    setLocalSettings({
      startDate,
      endDate,
      authors: cleanUpInputs(beckSettings?.authors),
      legalAreas: cleanUpInputs(beckSettings?.legalAreas),
      legislativeBodies: cleanUpInputs(beckSettings?.legislativeBodies),
      publications: cleanUpInputs(beckSettings?.publications),
      publicationTypes: cleanUpInputs(beckSettings?.publicationTypes),
      sources: cleanUpInputs(beckSettings?.sources),
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedPubTypes(cleanUpInputs(beckSettings?.publicationTypes));
  }, [beckSettings]);

  useEffect(() => {
    if (selectedPubTypes.length === 0) {
      setSelectedPubTypes(["Alle Publikationstypen"]);
    }
  }, [selectedPubTypes, beckSettings, localSettings]);

  const handleMultiValueChange = (
    name: keyof BeckSettingsType,
    selected: string[]
  ) => {
    setLocalSettings((prev) => ({
      ...prev,
      [name]: selected,
    }));
  };

  const handlePubTypeSelect = (value: string) => {
    setSelectedPubTypes((prev) =>
      prev.includes(value)
        ? prev.filter((format) => format !== value)
        : [...prev, value]
    );
  };

  const handleBeckSettingsSave = async () => {
    if (!settings.useBeckAutomaticMode && !loading) {
      setLoading(true);
      await updateBeckSetting({
        date: `${formatDottedDate(startDate)} - ${formatDottedDate(endDate)}`,
        authors: localSettings?.authors?.join(", "),
        legalAreas: localSettings?.legalAreas?.join(", "),
        legislativeBodies: localSettings?.legislativeBodies?.join(", "),
        publications: localSettings?.publications?.join(", "),
        publicationTypes: selectedPubTypes.join(", "),
        sources: localSettings?.sources?.join(", "),
      });
      toast.success("Beck settings updated successfully");
      setLoading(false);
    }
  };

  return (
    <>
      <SmHeader>
        <Title>Automatischer Modus</Title>
        <Subtitle>Wir erstellen automatisch die Einstellungen.</Subtitle>
      </SmHeader>
      <Row>
        <IOSStyleSwitch
          checked={settings?.useBeckAutomaticMode}
          onChange={(e) => {
            updateSetting("useBeckAutomaticMode", e);
          }}
        />
        <SmText>
          Wenn Du dieses Option aktivierst,
          <br /> werden alle BeckOnline Einstellungen von uns erstellt.
        </SmText>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      {!settings?.useBeckAutomaticMode && (
        <>
          <Spacer height={29} />
          <SmTitle>BeckOnline Eingrenzen</SmTitle>
          <Row $gap={"24px"}>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
            <MultiSelectInput
              type="autor"
              value={localSettings?.authors}
              onChange={(selected: string[]) =>
                handleMultiValueChange("authors", selected)
              }
              placeholder="Autor"
            />
          </Row>
          <Row $gap={"24px"}>
            <MultiSelectInput
              type={"normgeber"}
              value={localSettings?.legislativeBodies}
              onChange={(selected: string[]) =>
                handleMultiValueChange("legislativeBodies", selected)
              }
              placeholder="Bund, Bundesland o. EU"
            />
            <MultiSelectInput
              type={"fundstelle"}
              value={localSettings?.sources}
              onChange={(selected: string[]) =>
                handleMultiValueChange("sources", selected)
              }
              placeholder="Fundstelle"
            />
          </Row>
          <Spacer height={29} />
          <SmTitle>BeckOnline Bereich</SmTitle>
          <Row $gap={"24px"}>
            <MultiSelectInput
              type={"publikation"}
              value={localSettings?.publications}
              onChange={(selected: string[]) =>
                handleMultiValueChange("publications", selected)
              }
              placeholder="Publikationen"
            />
            <MultiSelectInput
              type={"rechtsgebiet"}
              value={localSettings?.legalAreas}
              onChange={(selected: string[]) =>
                handleMultiValueChange("legalAreas", selected)
              }
              placeholder="Rechtsgebiet"
            />
          </Row>
          <Spacer height={29} />
          <MultiSelector
            values={selectedPubTypes}
            setValue={handlePubTypeSelect}
            options={[
              {
                value: "Alle Publikationstypen",
                label: "Alle Publikationstypen",
              },
              { value: "Rechtsprechung", label: "Rechtsprechung" },
              {
                value: "Kommentare, Handbücher, etc.",
                label: "Kommentare, Handbücher, etc.",
              },
              { value: "Rechtsvorschriften", label: "Rechtsvorschriften" },
              { value: "Aufsätze", label: "Aufsätze" },
              {
                value: "Verwaltungsvorschriften",
                label: "Verwaltungsvorschriften",
              },
              {
                value: "Formulare, Verträge, Muster",
                label: "Formulare, Verträge, Muster",
              },
              {
                value: "Verträge, sonst. Rechtsquellen",
                label: "Verträge, sonst. Rechtsquellen",
              },
              { value: "Lexika", label: "Lexika" },
              {
                value: "Meldungen, Anmerkungen",
                label: "Meldungen, Anmerkungen",
              },
              { value: "Wörterbücher", label: "Wörterbücher" },
            ]}
          />
          <Spacer height={44} />
          <Divider noMargin />
          <Spacer height={29} />
          <Button
            onClick={handleBeckSettingsSave}
            disabled={loading}
            variant="white"
            type="submit"
          >
            {loading ? (
              <CircularLoader loading={loading} />
            ) : (
              "Einstellungen speichern"
            )}
          </Button>
          <Text>Stell sicher, dass alle Einstellungen richtig sind.</Text>
        </>
      )}
    </>
  );
};

const SaveButton = styled.button`
  background-color: #343231;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 7px 9px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  margin-left: auto;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const SaveIcon = styled.span`
  margin-right: 5px;
`;

const CitationSettings = () => {
  const { settings, updateSetting, updateMultipleSettings } = useSettings();
  const [localSettings, setLocalSettings] = useState<UserDataType["settings"]>({
    ...settings,
  });
  const setSettingsModalViewState = useSetRecoilState(settingsModalViewState);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  const handleSourceChange = (
    source: "useBeckOnline" | "useJSTOR" | "useGoogleScholar"
  ) => {
    const newSettings = {
      useBeckOnline: false,
      useJSTOR: false,
      useGoogleScholar: false,
      [source]: true,
    };

    updateMultipleSettings(newSettings);
    setLocalSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
  };

  return (
    <>
      <Row $margin={"0px"}>
        <Icon1 />
        <div>
          <SmHeader>
            <Title>BeckOnline</Title>
            <Subtitle>Finde effektiv Zitate auf BeckOnline.</Subtitle>
          </SmHeader>
          <Row $margin={"12px"}>
            <IOSStyleSwitch
              disableWhenOn
              checked={localSettings?.useBeckOnline}
              onChange={() => handleSourceChange("useBeckOnline")}
            />
            <SmText>
              Durch Aktivierung dieser Option,
              <br /> wirst Du immer Zitate auf BeckOnline suchen.
            </SmText>
          </Row>
        </div>
      </Row>
      {!localSettings.useBeckOnline === true && (
        <>
          <Spacer height={29} />
          <Divider noMargin />
        </>
      )}
      <Spacer height={25} />
      {localSettings.useBeckOnline === true && (
        <motion.div
          key="beckonline-settings"
          initial="hidden"
          animate="visible"
          variants={fadeVariants}
          transition={{ duration: 0.5 }}
        >
          <SettingsSection
            heading="BeckOnline"
            subheading="Verwalte Deine BeckOnline Einstellungen"
            img={keyboardImage}
            onClick={() => {
              setSettingsModalViewState(SettingsDisplayView.BECKONLINE);
            }}
          />

          <Spacer height={18} />
        </motion.div>
      )}
      <Row $margin={"0px"}>
        <Icon2 />
        <div>
          <SmHeader>
            <Title>Rechtssprechung</Title>
            <Subtitle>Finde effektive Entscheidungen.</Subtitle>
          </SmHeader>
          <Row $margin={"12px"}>
            <IOSStyleSwitch
              disableWhenOn
              checked={localSettings?.useJSTOR}
              onChange={() => handleSourceChange("useJSTOR")}
            />
            <SmText>
              Durch Aktivierung dieser Option,
              <br /> wirst Du immer Zitate Rechtssprechungen suchen.
            </SmText>
          </Row>
        </div>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={25} />
      <Row $margin={"0px"}>
        <Icon3 />
        <div>
          <SmHeader>
            <Title>Google Scholar</Title>
            <Subtitle>Finde effektiv Zitate auf Google Scholar.</Subtitle>
          </SmHeader>
          <Row $margin={"12px"}>
            <IOSStyleSwitch
              disableWhenOn
              checked={localSettings?.useGoogleScholar}
              onChange={() => handleSourceChange("useGoogleScholar")}
            />
            <SmText>
              Google Scholar
              <br /> wirst Du immer Zitate auf Google Scholar suchen.
            </SmText>
          </Row>
        </div>
      </Row>

      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={25} />
      <SmText>
        Our modern citation finder is still under construction and maybe in very
        small situations produce or not find any citations. You can find our
        citation guidelines{" "}
        <a
          href="https://help.noorioo.com/de/articles/9907807-search-results-policy"
          target="_blank"
        >
          here
        </a>
        .
      </SmText>
      {/* <Sources
        sources={[
          {
            source: "Zeitschriften",
            url: "https://beck-online.beck.de/",
          },
          {
            source: "Bücher",
            url: "https://beck-online.beck.de/",
          },
          {
            source: "Kommentare",
            url: "https://beck-online.beck.de/",
          },
        ]}
      /> */}
    </>
  );
};

const ManageSettings = () => {
  const { settings, updateSetting } = useSettings();
  const [localSettings, setLocalSettings] = useState<UserDataType["settings"]>({
    ...settings,
  });
  const settingsModalOpen = useRecoilValue(settingsModalState);
  const { onboarding, updateOnboarding } = useOnboarding();
  // update preferences flag if not already onboarded
  useEffect(() => {
    if (settingsModalOpen && !onboarding.preferences) {
      updateOnboarding("preferences");
    }
  }, [settingsModalOpen]);

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  const setSettingsModalViewState = useSetRecoilState(settingsModalViewState);
  return (
    <>
      <SmHeader>
        <Title>Verwalten</Title>
        <Subtitle>Browsing Option</Subtitle>
      </SmHeader>
      <Row>
        <IOSStyleSwitch
          checked={localSettings?.useInternet}
          onChange={(e) => {
            updateSetting("useInternet", e);
          }}
        />
        <SmText>
          Durch Aktivierung dieser Option,
          <br /> verwenden Sie immer den Browser.
        </SmText>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={25} />
      <SmHeader>
        <Title>Autovervollständigung</Title>
        <Subtitle>Komplett automatisiert</Subtitle>
      </SmHeader>
      <Row>
        <IOSStyleSwitch
          checked={localSettings?.useAutoComplete}
          onChange={(e) => {
            updateSetting("useAutoComplete", e);
          }}
        />
        <SmText>
          Durch Aktivierung dieser Option,
          <br />
          Werden Sätze automatisch vervollständigt.
        </SmText>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={40} />
      <Header>
        <Title>Zitatquellen verwalten.</Title>
        <Subtitle>Welche Quellen möchtest Du verwenden.</Subtitle>
      </Header>
      <Spacer height={40} />
      <Divider noMargin />
      <Spacer height={25} />
      <SpaceBetween
        onClick={() => {
          setSettingsModalViewState(SettingsDisplayView.CITATIONS);
        }}
      >
        <SmHeader>
          <Title>Quellen</Title>
          <Subtitle>Verwalte Deine Zitateinstellungen.</Subtitle>
        </SmHeader>
        <ChevronRight />
      </SpaceBetween>
    </>
  );
};

const MainScreen = () => {
  const setPlansModalOpen = useSetRecoilState(plansModalState);
  const setVerifyEmailModalOpen = useSetRecoilState(verfiyEmailModalState);
  const setSettingsModalOpen = useSetRecoilState(settingsModalState);
  const setSettingsModalViewState = useSetRecoilState(settingsModalViewState);
  const [loading, setLoading] = useState(false);
  const [manageLoading, setManageLoading] = useState(false);
  const userData = useRecoilValue(userDataState);
  const { setUser } = useUser();
  const hasSubscription = !!userData?.isPremiumUser;

  const handleLogout = async () => {
    if (!loading) {
      try {
        setLoading(true);
        await logout();
        setUser(null);
        setSettingsModalOpen(false);
        localStorage.removeItem("user");
        localStorage.removeItem(storageKey);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleManageSubscription = async () => {
    if (manageLoading) return;
    try {
      setManageLoading(true);
      const { url } = await getPortalLink();
      window.location.href = url;
    } catch (e) {
      axiosErrorHandler(e);
    } finally {
      setManageLoading(true);
    }
  };

  return (
    <>
      <SettingsSection
        heading={hasSubscription ? "Verwalte" : "Kaufen"}
        subheading={hasSubscription ? "Dein Abonnement" : "Abonnement"}
        img={settingsImage}
        loading={manageLoading}
        onClick={() => {
          if (userData?.isPremiumUser) {
            handleManageSubscription();
          } else {
            setPlansModalOpen(true);
            setSettingsModalOpen(false);
          }
        }}
      />
      <SettingsSection
        heading="Verwalten"
        subheading="Deine Einstellungen"
        img={keyboardImage}
        onClick={() => {
          setSettingsModalViewState(SettingsDisplayView.SETTINGS);
        }}
      />
      <SettingsSection
        heading="Verbinden"
        subheading="EDU Email"
        img={cardImage}
        onClick={() => {
          setVerifyEmailModalOpen(true);
          setSettingsModalOpen(false);
        }}
      />
      <Text>
        Es fallen keine Gebühren an, bis Ihre Testphase abläuf,
        <br />
        und Sie können jederzeit kündigen.
      </Text>
      <LogoutButton onClick={handleLogout}>
        {loading ? "Ausloggen..." : "Abmelden"}
      </LogoutButton>
    </>
  );
};

const LogoutButton = styled.div`
  color: #ed4545;
  cursor: pointer;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 4.125rem;
`;

const SettingsSection = ({
  heading,
  subheading,
  img,
  onClick,
  loading = false,
}: {
  heading: string;
  subheading: string;
  img: string;
  loading?: boolean;
  onClick?: () => void;
}) => {
  return (
    <SettingsSectionContainer onClick={onClick}>
      <Padding>
        <Header>
          <SmTitle>{heading}</SmTitle>
          <SmSubtitle>{subheading}</SmSubtitle>
        </Header>
        {loading && <CircularLoader loading={loading} />}
      </Padding>
      <Image src={img} alt={heading} />
    </SettingsSectionContainer>
  );
};

const SettingsSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #191719;
  border-radius: 0.3125rem;
  padding-right: 0;
  min-height: 3.75rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border: 1px solid rgba(38, 39, 47, 0.5);
  margin-bottom: 0.5rem;

  &:hover {
    background-color: rgba(25, 23, 25, 0.9);
  }
`;

const Padding = styled.div`
  padding: 0.9375rem 1.375rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: max-content;
  height: 60px;
  align-self: flex-end;
  object-fit: contain;
`;

const SmTitle = styled(Title)`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
`;

const SmSubtitle = styled(Subtitle)`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  margin-top: 0.25rem;
`;

const Text = styled.p`
  margin-top: 0.75rem;
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.0625rem;
`;

const SmHeader = styled(Header)`
  font-size: 0.75rem;
  line-height: 1.25;
`;

const Row = styled.div<{ $gap?: string; $margin?: string | number }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.$gap || "0.625rem"};
  margin-top: ${(props) => props.$margin || "1.125rem"};
`;

const SmText = styled(Text)`
  font-size: 0.625rem;
  line-height: 0.875rem;
  opacity: 1;
  margin: 0;

  a {
    text-decoration: underline;
    transition: color 0.2s ease;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.9375rem;
  cursor: pointer;

  &:hover {
    svg path {
      stroke-opacity: 1;
    }
  }
`;

export const BackText = styled.p`
  color: #fff;
  opacity: 0.5;
  transition: color 0.2s ease;
`;

export const Back = styled.div`
  background-color: #343231;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  width: max-content;
  min-height: 1.5625rem;
  padding: 0.3125rem 0.5rem;
  position: absolute;
  top: 1.5rem;
  right: 2.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  svg path {
    transition: stroke-opacity 0.2s ease;
  }

  &:hover {
    background-color: #fff;

    ${BackText} {
      color: #000000;
      opacity: 1;
    }

    svg path {
      stroke-opacity: 1;
      stroke: #000000;
    }
  }
`;

interface OptionProps {
  text: string;
  active: boolean;
  click: () => void;
}

interface OptionType {
  value: string;
  label: string;
}

function MultiSelector({
  options,
  values,
  setValue,
}: {
  options: OptionType[];
  values: string[];
  setValue: (values: string) => void;
}) {
  const toggleFormat = (v: string) => {
    setValue(v);
  };

  return (
    <MultiSelectorRow>
      {options.map((format) => (
        <StyledOption
          text={format.label}
          click={() => toggleFormat(format.value)}
          active={values.includes(format.value)}
          key={format.value}
        />
      ))}
    </MultiSelectorRow>
  );
}

const MultiSelectorRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-top: 1.125rem;
  flex-wrap: wrap;
`;
