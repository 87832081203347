import { useEffect, useLayoutEffect, useMemo } from "react";
import Words from "./Words";
import styled from "styled-components";
import CitationBubble from "../ui/CitationBubble";

import { TiptapCollabProvider } from "@hocuspocus/provider";
import "iframe-resizer/js/iframeResizer.contentWindow";
import { useState } from "react";
import { Doc as YDoc } from "yjs";
import { BlockEditor } from "../BlockEditor";
import IntegrateZitat from "../General/IntegrateZitat";
import { Toolbar } from "components/ui/Toolbar";
import FindeZitate from "../General/FindeZitate";

export default function HomeWorkLeftSection() {
  const ydoc = useMemo(() => new YDoc(), []);

  return (
    <Wrapper id="editor-container">
      <BlockEditor ydoc={ydoc} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
  width: 100%;
`;
