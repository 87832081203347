import { TogglePaneIcon } from "../../constants/Svgs";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { paneStateExpanded } from "../../recoil/pane";

export default function TogglePane() {
  const setToggle = useSetRecoilState(paneStateExpanded);
  return (
    <Wrapper>
      <ToggleIcon onClick={() => setToggle((prev) => !prev)}>
        <TogglePaneIcon />
      </ToggleIcon>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-left: 1.3125rem;
  background-color: rgba(28, 27, 27, 0.5);
  display: flex;
  align-items: center;
  min-height: 2.625rem;
  width: 100%;
  border: 1px solid #292826;
  border-left: 0;
  border-right: 0;
`;

const ToggleIcon = styled.div`
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    transition: stroke 0.2s;
  }

  &:hover {
    path {
      stroke: #fff;
    }
  }
`;
