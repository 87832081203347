import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function getSuggestion(
  text: string,
  signal: AbortSignal
): Promise<{ success: boolean; suggestion: string }> {
  try {
    const resp = await axiosWithAuth.post(
      `${process.env.REACT_APP_NOORIOO_API}/writing/suggestion`,
      { text },
      { signal }
    );

    if (!resp.data) {
      throw new Error("Some error occured");
    }
    if (resp.data.error) {
      throw new Error(resp.data.error);
    }

    return resp.data;
  } catch (err) {
    return { success: false, suggestion: "" };
  }
}
