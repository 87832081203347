import styled from "styled-components";

export default function Divider({
  noMargin,
  color,
}: {
  noMargin?: boolean;
  color?: string;
}) {
  return <Container $color={color} $noMargin={noMargin} />;
}

const Container = styled.div<{ $noMargin?: boolean; $color?: string }>`
  width: 100%;
  height: 1px;
  background-color: ${({ $color }) => $color || "rgba(255, 255, 255, 0.15)"};
  margin: ${({ $noMargin }) => ($noMargin ? "0" : "1.5625rem 0")};
`;
