import { Editor } from "@tiptap/react";

export const getSelectedText = (editor: Editor) => {
  const { state } = editor;
  const { from, to } = state.selection;
  return state.doc.textBetween(from, to, "\n");
};

export const replaceSelectedText = (editor: Editor, newText: string) => {
  editor.commands.deleteSelection(); // Deletes the currently selected text
  editor.commands.insertContent(newText); // Inserts new text at the cursor position
};

export const insertTextBelow = (editor: Editor, newText: string) => {
  const { state, view } = editor;
  const { selection } = state;
  const { $from } = selection;
  const pos = $from.end();

  editor.commands.insertContentAt(pos, `<p>${newText}</p>`);
};
