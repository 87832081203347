import { motion } from "framer-motion";
import { LoadingIcon } from "constants/Svgs";
import styled from "styled-components";

const barVariants = {
  initial: { width: 0 },
  animate1: { width: "100%", transition: { duration: 1 } },
  animate2: { width: "100%", transition: { duration: 1, delay: 0.2 } },
  animate3: { width: "80%", transition: { duration: 1, delay: 0.4 } },
};

const shineVariants: any = (index: number) => {
  const initial = `0% ${index * 50}%`;
  return {
    initial: { backgroundPosition: initial },
    animate: {
      backgroundPosition: "100% 50%",
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse",
        delay: index * 0.25,
      },
    },
  };
};

const LoadingComponent = ({
  question,
  repeat = 1,
}: {
  question: string;
  repeat?: number;
}) => {
  return (
    <Row>
      {/* <SvgContainer>
        <LoadingIcon />
      </SvgContainer> */}
      <LoadingContainer>
        {/* <UserText>{question}</UserText> */}
        {Array(repeat)
          .fill(0)
          .map((_, i) => (
            <>
              <LoadingBar
                initial="initial"
                animate="animate1"
                variants={barVariants}
              >
                <Shine
                  variants={shineVariants(i + 0)}
                  initial="initial"
                  animate="animate"
                />
              </LoadingBar>
              <LoadingBar
                initial="initial"
                animate="animate2"
                variants={barVariants}
              >
                <Shine
                  variants={shineVariants(i + 1)}
                  initial="initial"
                  animate="animate"
                />
              </LoadingBar>
              <LoadingBar
                initial="initial"
                animate="animate3"
                variants={barVariants}
              >
                <Shine
                  variants={shineVariants(i + 2)}
                  initial="initial"
                  animate="animate"
                />
              </LoadingBar>
            </>
          ))}
      </LoadingContainer>
    </Row>
  );
};

export default LoadingComponent;

const Row = styled.div`
  display: flex;
  height: 100vh;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: flex-start;
  width: 100%;
`;

const LoadingBar = styled(motion.div)`
  width: 100%;
  height: 18px;
  background-color: transparent;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const Shine = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1) 95%
  );
  background-size: 200% 100%;
`;

const UserText = styled.p`
  color: #727272;
  font-size: 10px;
  line-height: 18px;
  margin-bottom: 15px;
`;

const SvgContainer = styled.div`
  display: inline;
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
