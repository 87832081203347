import { Extension } from "@tiptap/core";
import getSuggestion from "lib/autocomplete/getSuggestion";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { isWithinTokenLimit } from "gpt-tokenizer";
import { decodeTokens, encodeText } from "lib/utils/tokenizer";
import { RawCommands } from "@tiptap/core";

const MAX_INPUT_TOKEN = 200;
const SUGGESTION_DELAY = 3 * 1000; // 3 seconds delay to get suggestion
const autocompleteKey = new PluginKey("autocomplete");

export const Autocomplete = Extension.create({
  name: "AutocompleteExtension",

  addOptions() {
    return {
      enabled: true, // Autocomplete is enabled by default
      className: "autocomplete-suggestion",
    };
  },

  addCommands() {
    return {
      toggleAutocomplete:
        (isEnabled: boolean) =>
        ({ state, dispatch }) => {
          const pluginState = autocompleteKey.getState(state) || {
            enabled: false,
            decorationSet: DecorationSet.empty,
          };

          dispatch(
            state.tr.setMeta(autocompleteKey, {
              toggleEnabled: isEnabled,
            })
          );
          return true;
        },
    } as Partial<RawCommands>;
  },

  addProseMirrorPlugins() {
    let showSuggestion = false; // Tracks whether to show the suggestion
    let currentSuggestion = ""; // The current suggestion text
    let lastContent = ""; // Tracks the last content to check for changes
    let isWaiting = false; // Flag to check if we are waiting for user to stop typing
    let timeoutId: NodeJS.Timeout; // ID for the debounce timeout
    let abortController = new AbortController(); // AbortController to cancel ongoing requests

    return [
      new Plugin({
        key: autocompleteKey,
        state: {
          init() {
            return {
              decorationSet: DecorationSet.empty,
              enabled: true, // Initialize with the default enabled state
            };
          },
          apply: (transaction, value, oldState, newState) => {
            let { decorationSet, enabled } = value;

            // Check if the feature should be toggled
            const meta = transaction.getMeta(autocompleteKey);
            if (meta && meta.toggleEnabled !== undefined) {
              enabled = meta.toggleEnabled;
            }
            // We need to ensure decorationSet is always returned as part of an object
            decorationSet = DecorationSet.empty; // Reset or maintain the decoration set here
            const { selection } = transaction;
            if (
              enabled &&
              selection instanceof TextSelection &&
              selection.$cursor
            ) {
              if (showSuggestion && currentSuggestion) {
                const cursorPos = selection.$head.pos;
                const decoration = Decoration.widget(
                  cursorPos,
                  () => {
                    const parentNode = document.createElement("span");
                    parentNode.innerHTML = `<span style="opacity: 40%;">${currentSuggestion}</span>`;
                    parentNode.classList.add(this.options.className);
                    return parentNode;
                  },
                  { side: 1 }
                );
                decorationSet = decorationSet.add(newState.doc, [decoration]);
              }
            }

            // Always return an object with both properties
            return { decorationSet, enabled };
          },
        },

        props: {
          decorations(state) {
            // Safely get the plugin state
            const pluginState = autocompleteKey.getState(state);
            // Ensure the pluginState is not undefined before accessing decorationSet
            if (pluginState) {
              return pluginState.decorationSet;
            }
            // Return an empty DecorationSet if there's no state to avoid errors
            return DecorationSet.empty;
          },
          handleKeyDown(view, event) {
            const { state, dispatch } = view;
            const pluginState = autocompleteKey.getState(view.state);
            if (!pluginState || !pluginState.enabled) return false;

            const content = state.doc.textBetween(0, state.selection.from, " ");
            const tokens = encodeText(content); // Tokenize the content
            const lastTokens = tokens.slice(-MAX_INPUT_TOKEN); // Get the last 200 tokens
            const textWithInTokenLimits = decodeTokens(lastTokens); // Decode tokens back to string

            if (event.key === " ") {
              const spaceTransaction = state.tr.insertText(" ");

              // Reset the isWaiting flag and clear the previous timeout
              isWaiting = false;
              clearTimeout(timeoutId);

              // Request suggestion only if the text content is more than 15 characters
              if (textWithInTokenLimits.length > 15) {
                isWaiting = true;
                timeoutId = setTimeout(() => {
                  if (content === lastContent) {
                    // Abort the previous request
                    abortController.abort();
                    abortController = new AbortController();

                    getSuggestion(textWithInTokenLimits, abortController.signal)
                      .then((result) => {
                        if (result.success) {
                          showSuggestion = true;
                          currentSuggestion = result.suggestion;
                        } else {
                          showSuggestion = false;
                          currentSuggestion = "";
                        }
                        dispatch(view.state.tr); // Update the view with the new suggestion
                      })
                      .catch((error) => {
                        if (error.name !== "AbortError") {
                          console.error(error);
                        }
                        showSuggestion = false;
                        currentSuggestion = "";
                      });
                  }
                  isWaiting = false;
                }, SUGGESTION_DELAY); // 1 second debounce
              }
              // }
              dispatch(spaceTransaction); // Trigger an update to show the new suggestion
              lastContent = content; // Update the lastContent
              return true;
            } else if (event.key === "Tab") {
              event.preventDefault();
              if (showSuggestion) {
                // Apply the suggestion when Tab is pressed and hide it
                const transaction = state.tr
                  .insertText(
                    currentSuggestion,
                    state.selection.from,
                    state.selection.to
                  )
                  .setMeta("appliedSuggestion", true); // Mark the transaction
                showSuggestion = false;
                currentSuggestion = "";
                dispatch(transaction);
                return true;
              }
            } else {
              const { dispatch } = view;
              if (showSuggestion) {
                showSuggestion = false;
                currentSuggestion = "";
                dispatch(view.state.tr); // Update to remove the suggestion

                // Abort the ongoing request
                abortController.abort();
              }
            }
            return false;
          },
        },
      }),
    ];
  },
});

export default Autocomplete;
