import { CitationType } from "types/citation";

export default function parseCitationOutput(output: {
  zitates: any[];
}): CitationType[] {
  if (!output.zitates || !Array.isArray(output.zitates)) return [];

  let citations: CitationType[] = [];

  for (let zitatObj of output.zitates) {
    const zitat = zitatObj.zitat;

    const content = zitat.find((item: any) => item.zitattext)?.zitattext || "";
    const source = zitat.find((item: any) => item.quelle)?.quelle || "";
    const url = zitat.find((item: any) => item.url)?.url || "";
    const index = parseInt(
      zitat.find((item: any) => item.zitatnummer)?.zitatnummer,
      10
    );

    citations.push({ content, source, url, index });
  }

  return citations;
}
