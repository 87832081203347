import styled from "styled-components";
import { LinkIcon } from "../../constants/Svgs";
import getDomainName from "lib/utils/getDomainName";

export default function SourcesV2({
  sources,
  hideHeader,
}: {
  sources: { url: string; title: string }[];
  hideHeader?: boolean;
}) {
  return (
    <Container>
      <Text>
        Noorioo TLDR uses AI to combine existing content that may be inaccurate.
        The above was generated based on the following sources:
      </Text>
      <Links>
        {sources.map((item, index) => (
          <StyledLink key={index} item={item} index={index} />
        ))}
      </Links>
    </Container>
  );
}

export const StyledLink = ({
  item,
  index,
}: {
  item: { url: string; title: string };
  index: number;
}) => {
  return (
    <LinkWrapper>
      <LinkNumber>{index + 1}.</LinkNumber>
      <LinkContent>
        <Link href={item.url} target="_blank" rel="noopener noreferrer">
          {item.title}
        </Link>
        <LinkDomain>{getDomainName(item.url)}</LinkDomain>
      </LinkContent>
    </LinkWrapper>
  );
};

const Container = styled.div``;

const LinkWrapper = styled.div`
  display: flex;
  align-items: start;
  font-size: 13px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 8px;
`;

const LinkNumber = styled.span`
  margin-right: 5px;
  font-size: 13px;
  line-height: 17px;
`;

const LinkDomain = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  line-height: 17px;
`;

const LinkContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 0.125rem;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 7px;
  color: #81a0f0;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  width: max-content;
  transition: color 0.2s ease;

  svg path {
    transition: stroke-opacity 0.2s ease;
  }

  &:hover {
    color: #fff;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: #fff;
  opacity: 0.6;
  margin-bottom: 10px;
  font-family: "Inter";
`;
