import styled from "styled-components";

export const MainContainerGrid = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
  width: 100%;
`;

export const LeftPad = styled.div<{ $background?: string }>`
  padding-left: var(--padding-left);
  background-color: ${(props) => props.$background || "transparent"};
`;
