import { atom } from "recoil";

export const plansModalState = atom<boolean>({
  key: "plansModalState",
  default: false,
});

export const loginModalState = atom<boolean>({
  key: "loginModalState",
  default: false,
});

export const loginWithPasswordModalState = atom<boolean>({
  key: "loginWithPasswordModalState",
  default: false,
});

export const signUpModalState = atom<boolean>({
  key: "signUpModalState",
  default: false,
});

export const verfiyEmailModalState = atom<boolean>({
  key: "verfiyEmailModalState",
  default: false,
});

export const newHomeWorkModalState = atom<boolean>({
  key: "newHomeWorkModalState",
  default: false,
});

export const settingsModalState = atom<boolean>({
  key: "settingsModalState",
  default: false,
});

export const manageModalState = atom<boolean>({
  key: "manageModalState",
  default: false,
});
