import CitationModal from "components/General/CitationModal";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";

declare global {
  interface WindowEventMap {
    updateCitation: CustomEvent<{
      id: string;
      source: string;
      content: string;
      url?: string;
    }>;
    removeCitation: CustomEvent<{
      id: string;
    }>;
  }
}

export default function useAddCitationHandler() {
  useEffect(() => {
    window.handleCitationClick = (event) => {
      event.preventDefault();
      if (!(event.target instanceof Element)) return;
      // Use closest to find the parent citation container
      const citationContainer = event.target.closest('[data-type="citation"]');

      if (citationContainer) {
        const rect = citationContainer.getBoundingClientRect();
        const id = citationContainer.getAttribute("data-id");
        const source = citationContainer.getAttribute("data-source");
        const content = citationContainer.getAttribute("data-content");
        const url = citationContainer.getAttribute("data-url");
        const modalDiv = document.createElement("div");
        document.body.appendChild(modalDiv);

        // Create a root for React portal
        const root = createRoot(modalDiv);

        root.render(
          <CitationModal
            id={id}
            x={rect.left + window.scrollX} // Adjust for scrolling
            y={rect.bottom + window.scrollY}
            source={source}
            content={content}
            url={url}
            onClose={() => {
              root.unmount();
              document.body.removeChild(modalDiv);
            }}
          />
        );
      }
    };

    return () => {
      delete window.handleCitationClick;
    };
  }, []);
}
