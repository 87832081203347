import { saveAs } from "file-saver";
import { renderAsync } from "docx-preview";
import { ExportConfig } from "types/document";
import { createDocFromHtml } from "./docx";

async function convertDocxToHtml(docxBlob: Blob): Promise<Blob> {
  const container = document.createElement("div");
  container.style.width = "800px";
  container.style.color = "red";
  document.body.appendChild(container);

  try {
    console.log("Rendering DOCX...");
    await renderAsync(await docxBlob.arrayBuffer(), container, null, {
      inWrapper: false,
    });

    console.log("DOCX rendered. Container:", container);

    const htmlContent = `<style>
    * { line-height: 1; }
    h1, h2, h3 { line-height: 1.2 !important; }
    sup { font-size: 0.7rem }
    .docx_heading6 {
      padding: 0.875rem;
      margin: 0.5rem;
      border: 2px solid black;
      font-style: italic;
    }
  </style>
  ${container.innerHTML}`;

    // Create a Blob from the HTML content
    const htmlBlob = new Blob([htmlContent], { type: "text/html" });

    // Clean up by removing the container
    document.body.removeChild(container);

    return htmlBlob;
  } catch (error) {
    console.error("Error rendering DOCX:", error);
    document.body.removeChild(container);
    throw error;
  }
}

export async function createHtmlFromDocx(
  title: string,
  htmlContent: string,
  exportConfigs: ExportConfig
): Promise<void> {
  try {
    console.log("Creating DOCX...");
    const docxBlob = await createDocFromHtml(htmlContent, exportConfigs);
    console.log("DOCX created, converting to HTML...");
    const htmlBlob = await convertDocxToHtml(docxBlob);
    console.log("Saving HTML...");
    saveAs(htmlBlob, `${title}.html`);
    console.log("HTML exported successfully");
  } catch (error) {
    console.error("Error exporting PDF:", error);
    throw error;
  }
}
