"use client";

import { EditorContent } from "@tiptap/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { LinkMenu } from "components/menus";

import { useBlockEditor } from "hooks/useBlockEditor";

import { EditorContext } from "context/EditorContext";
import ImageBlockMenu from "extensions/ImageBlock/components/ImageBlockMenu";
import { ColumnsMenu } from "extensions/MultiColumn/menus";
import { TableColumnMenu, TableRowMenu } from "extensions/Table/menus";
import { TiptapProps } from "./types";
import { EditorHeader } from "./components/EditorHeader";
import { TextMenu } from "../menus/TextMenu";
import { ContentItemMenu } from "../menus/ContentItemMenu";
import { AIDropdown } from "../menus/TextMenu/components/AIDropdown";
import CitationBubble from "../ui/CitationBubble";
import {
  currentHomeWorkContentState,
  currentHomeWorkIdState,
  lastEditedTimeState,
} from "recoil/homework";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import getHomeWork from "lib/homework/getHomeWork";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import syncHomeWork from "lib/homework/syncHomeWork";
import useOnboarding from "hooks/user/useOnboarding";
import useSettings from "hooks/user/useSettings";
import { Editor } from "@tiptap/react";
import { usePageCount } from "hooks/usePageCount";
import { userDataState } from "recoil/auth";
import { plansModalState } from "recoil/modal";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const BlockEditor = ({ ydoc }: TiptapProps) => {
  const menuContainerRef = useRef(null);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const query = useQuery();
  const homeworkId = query.get("id");
  const userData = useRecoilValue(userDataState);
  const setPurchaseModalOpen = useSetRecoilState(plansModalState);
  const [currentHomeWorkId, setCurrentHomeWorkId] = useRecoilState(
    currentHomeWorkIdState
  );
  const [currentContent, setCurrentContent] = useRecoilState(
    currentHomeWorkContentState
  );
  const [lastEditedTime, setLastEditedTime] =
    useRecoilState(lastEditedTimeState);
  const [hwLoading, setHwLoading] = useState(false);
  const { settings } = useSettings();
  const isAutocompleteEnabled = settings?.useAutoComplete;
  const { editor, characterCount } = useBlockEditor({
    ydoc,
  });

  const { onboarding, updateOnboarding } = useOnboarding();
  // update first homework flag if not already onboarded
  useEffect(() => {
    if (!onboarding?.firstHomeWork) {
      updateOnboarding("firstHomeWork");
    }
  }, []);

  useEffect(() => {
    if (userData && userData?.credits <= 0 && !userData?.isPremiumUser) {
      setPurchaseModalOpen(true);
    }
  }, [userData]);

  useEffect(() => {
    if (editor) {
      editor.commands.toggleAutocomplete(isAutocompleteEnabled);
    }
  }, [editor, isAutocompleteEnabled]);

  useEffect(() => {
    if (homeworkId) setCurrentHomeWorkId(homeworkId);
  }, [homeworkId]);

  useEffect(() => {
    (async () => {
      if (editor && currentHomeWorkId && !hwLoading) {
        try {
          setHwLoading(true);
          editor.setEditable(false);
          const resp = await getHomeWork(currentHomeWorkId);
          editor.commands.setContent(resp.content);
          setCurrentContent(resp.content);
          setLastEditedTime(resp.lastEditedAt);
        } catch (e) {
          axiosErrorHandler(e);
        } finally {
          setHwLoading(true);
          editor.setEditable(true);
        }
      }
    })();
  }, [currentHomeWorkId, editor]);

  const handleSave = useCallback(async () => {
    if (!editor || !currentHomeWorkId || hwLoading) return;
    const content = editor?.getHTML();
    if (content !== currentContent) {
      try {
        // console.log(content);

        await syncHomeWork(currentHomeWorkId, content);
        setCurrentContent(content);
        setLastEditedTime(new Date());
      } catch (error) {
        axiosErrorHandler(error);
      }
    }
  }, [editor, currentHomeWorkId]);

  useEffect(() => {
    const unsubscribe = async () => handleSave();
    return () => {
      unsubscribe();
    };
  }, [handleSave]);

  useEffect(() => {
    if (!editor) return;

    let timeoutId: NodeJS.Timeout;
    const handleUpdate = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        await handleSave();
      }, 2000);
    };

    editor.on("update", handleUpdate);

    return () => {
      clearTimeout(timeoutId);
      editor.off("update", handleUpdate);
    };
  }, [editor, currentContent, currentHomeWorkId]);

  useEffect(() => {
    if (!editor) return;

    const handleUpdateCitation = (event: CustomEvent) => {
      const { id, source, content, url } = event.detail;
      editor.commands.updateCitation(id, { source, content, url });
    };

    window.addEventListener(
      "updateCitation",
      handleUpdateCitation as EventListener
    );

    return () => {
      window.removeEventListener(
        "updateCitation",
        handleUpdateCitation as EventListener
      );
    };
  }, [editor]);

  const providerValue = useMemo(() => {
    return {};
  }, []);
  const pages = usePageCount(editor);

  if (!editor) {
    return null;
  }

  return (
    <EditorContext.Provider value={providerValue}>
      <div className="flex h-full" ref={menuContainerRef}>
        <div className="relative flex flex-col flex-1 h-full overflow-hidden">
          <EditorHeader
            characters={characterCount.characters()}
            words={characterCount.words()}
            pages={pages}
          />
          <EditorContent
            editor={editor}
            ref={editorRef}
            className="flex-1 overflow-y-auto"
          />
          <ContentItemMenu editor={editor} />
          <LinkMenu editor={editor} appendTo={menuContainerRef} />
          <TextMenu editor={editor} />
          <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
          <TableRowMenu editor={editor} appendTo={menuContainerRef} />
          <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
          <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        </div>
      </div>
    </EditorContext.Provider>
  );
};

export default BlockEditor;
