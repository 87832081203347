import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import axiosWithAuth from "lib/utils/axiosWithAuth";
import { useRecoilState } from "recoil";
import { userDataState } from "recoil/auth";
import { UserDataType } from "types/user";

export default function useBeckSettings() {
  const [userData, setUserData] = useRecoilState(userDataState);
  const beckSettings = userData?.beckSettings;

  const updateBeckSetting = async (settings: UserDataType["beckSettings"]) => {
    try {
      const resp = await axiosWithAuth.post(
        `${process.env.REACT_APP_NOORIOO_API}/user/beck-settings`,
        {
          ...settings,
        }
      );

      if (!resp.data) {
        throw new Error("Some error occured");
      }
      if (resp.data.error) {
        throw new Error(resp.data.error);
      }

      setUserData(resp?.data);
    } catch (e) {
      axiosErrorHandler(e);
    }
  };

  return { beckSettings, updateBeckSetting };
}
