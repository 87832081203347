import Words from "components/HomeWork/Words";

export type EditorHeaderProps = {
  characters: number;
  words: number;
  pages: number;
};

export const EditorHeader = ({
  characters,
  words,
  pages,
}: EditorHeaderProps) => {
  return <Words characters={characters} words={words} pages={pages} />;
};
