import styled from "styled-components";
import Button from "../General/Button";
import { ArrowUp } from "../../constants/Svgs";
import { useSetRecoilState } from "recoil";
import { newHomeWorkModalState } from "../../recoil/modal";

export default function NewHomeWorkButtons() {
  const setNewHomeWorkModal = useSetRecoilState(newHomeWorkModalState);
  return (
    <Container>
      <Button
        maxWidth="9.0625rem"
        variant="black"
        center
        onClick={() => setNewHomeWorkModal(true)}
      >
        Neue Hausarbeit
      </Button>
      {/* <Button variant="black" center square>
        <ArrowUp />
      </Button>
      <Button variant="black" center square>
        <ArrowUp rotate />
      </Button> */}
      <SmText>
        Verwalten Sie alles auf einmal,
        <br />
        starten, pausieren oder löschen Sie einfach alle Ihre Hausarbeiten.
      </SmText>
    </Container>
  );
}

const Container = styled.div`
  padding: 1.5625rem var(--padding-left);
  width: max-content;
  display: flex;
  align-content: center;
  gap: 0.8125rem;
`;

const SmText = styled.div`
  font-size: 0.625rem;
  color: #fff;
  display: flex;
  align-items: center;
`;
