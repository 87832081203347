import React, { useEffect, useRef, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";
import Divider from "components/General/Divider";
import Spacer from "components/General/Spacer";
import SmallButton from "components/General/SmallButton";
import { DeleteIcon, Done } from "constants/Svgs";
import { Editor } from "@tiptap/react";
import { CitationType } from "types/citation";
import addCitation from "lib/citation/addCitation";
import { useSlashCommandPopover } from "hooks/useSlashCommandPopover";

interface IntegrateZitatProps {
  editor: Editor;
  citationData?: CitationType;
  children?: React.ReactNode;
  isSlashCommand?: boolean;
  onClose?: () => void;
}

export default function IntegrateZitat({
  editor,
  citationData,
  children,
  isSlashCommand,
  onClose,
}: IntegrateZitatProps) {
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState(citationData?.source || "");
  const [citation, setCitation] = useState(citationData?.content || "");

  const { position, contentRef, updatePosition } = useSlashCommandPopover(
    editor,
    isSlashCommand
  );

  useEffect(() => {
    if (isSlashCommand) {
      setOpen(true);
    }
  }, [isSlashCommand]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSource("");
    setCitation("");
    if (onClose) onClose();
  };

  const handleAddCitation = () => {
    if (!source || !citation) return;
    addCitation(editor, { source, content: citation });
    handleClose();
  };

  return (
    <Popover.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          handleOpen();
        } else {
          handleClose();
        }
      }}
    >
      {children ? (
        <Popover.Trigger asChild onClick={handleOpen}>
          {children}
        </Popover.Trigger>
      ) : (
        <Popover.Trigger asChild>
          <span style={{ display: "none" }} onClick={handleOpen} />
        </Popover.Trigger>
      )}
      <Popover.Portal>
        <Popover.Content
          ref={contentRef}
          style={{
            zIndex: 10000,
            ...(isSlashCommand
              ? {
                  position: "absolute",
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                }
              : {}),
          }}
          sideOffset={16}
          align="start"
          side="bottom"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            if (isSlashCommand) {
              updatePosition();
            }
          }}
        >
          <Content>
            <SourceInput
              type="text"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              placeholder="Enter source here..."
            />
            <CitationInput
              value={citation}
              onChange={(e) => setCitation(e.target.value)}
              placeholder="Enter your citation..."
            />
            <Divider noMargin color="#363433" />
            <Spacer height={8} />

            <Row>
              <SmallButton onClick={handleAddCitation}>
                <Done />
              </SmallButton>
              <SmallButton>
                <DeleteIcon />
              </SmallButton>
            </Row>
          </Content>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const Content = styled.div`
  padding: 1rem 1.375rem;
  background-color: #292826;
  border-radius: 0.3125rem;
  width: 31.5rem;
`;

export const SourceInput = styled.input`
  width: 100%;
  padding: 0;
  margin-bottom: 0.9375rem;
  background: transparent;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.625rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const CitationInput = styled.textarea`
  width: 100%;
  margin-bottom: 0.5rem;
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 0.75rem;
  resize: none;

  &::placeholder {
    color: #ffffff;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;
