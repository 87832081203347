import { Editor } from "@tiptap/react";

export default function addCitation(
  editor: Editor,
  { source, content, url }: { source: string; content: string; url?: string }
) {
  // Generate a unique ID for each citation, could be more sophisticated in a real app
  const citationId = Date.now().toString();

  // Use the setCitation command from the custom Tiptap extension
  editor.commands.setCitation({
    id: citationId,
    source,
    content,
    url,
  });
}
