import styled from "styled-components";
import ContainerHOC from "../components/General/ContainerHOC";
import HomeWorkLeftSection from "../components/HomeWork/HomeWorkLeftSection";
import HomeWorkRightSection from "../components/HomeWork/HomeWorkRightSection";
import UserRequired from "components/Auth/UserRequired";
import { useEffect } from "react";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";

export default function HomeWork() {
  useEffect(() => {
    const hideIntercom = () => {
      if (typeof Intercom === "function") {
        shutdown();
      }
    };

    hideIntercom();

    const hideTimeout = setTimeout(hideIntercom, 1000);

    return () => {
      clearTimeout(hideTimeout);
      shutdown();
    };
  }, []);

  return (
    <UserRequired>
      <ContainerHOC>
        <Container>
          <Wrapper>
            <HomeWorkLeftSection />
            <HomeWorkRightSection />
          </Wrapper>
        </Container>
      </ContainerHOC>
    </UserRequired>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;
