import { Icon } from "components/ui/Icon";
import { icons } from "lucide-react";
import { useMemo } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { Toolbar } from "components/ui/Toolbar";
import { Surface } from "components/ui/Surface";
import { DropdownButton, DropdownCategoryTitle } from "components/ui/Dropdown";

export type ContentTypePickerOption = {
  label: string;
  id: string;
  type: "option";
  disabled: () => boolean;
  isActive: () => boolean;
  onClick: () => void;
  icon: any;
};

export type ContentTypePickerCategory = {
  label: string;
  id: string;
  type: "category";
};

export type ContentPickerOptions = Array<
  ContentTypePickerOption | ContentTypePickerCategory
>;

export type ContentTypePickerProps = {
  options: ContentPickerOptions;
};

const isOption = (
  option: ContentTypePickerOption | ContentTypePickerCategory
): option is ContentTypePickerOption => option.type === "option";
const isCategory = (
  option: ContentTypePickerOption | ContentTypePickerCategory
): option is ContentTypePickerCategory => option.type === "category";

export const ContentTypePicker = ({ options }: ContentTypePickerProps) => {
  const activeItem = useMemo(
    () =>
      options.find((option) => option.type === "option" && option.isActive()),
    [options]
  );

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          variant="ghost"
          style={{
            minWidth: "5.75rem",
          }}
          className="flex justify-between"
          buttonSize="medium"
        >
          {activeItem?.label}
          <svg
            className="ml-1"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.99996 3.80016L1.59996 0.333496L0.666626 1.26683L4.99996 5.66683L9.33329 1.26683L8.39996 0.333496L4.99996 3.80016Z"
              fill="white"
              fillOpacity="0.282"
            />
          </svg>
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface
          className="flex flex-col gap-1 px-2 py-4 mt-4 min-w-[180px]"
          style={{
            borderRadius: 3,
            border: 0,
          }}
        >
          {options.map((option) => {
            if (isOption(option)) {
              return (
                <DropdownButton
                  key={option.id}
                  onClick={option.onClick}
                  isActive={option.isActive()}
                >
                  <img
                    height={22}
                    width={22}
                    src={option.icon}
                    alt={option.label}
                  />
                  {option.label}
                </DropdownButton>
              );
            } else if (isCategory(option)) {
              return (
                <div className="mt-2 first:mt-0" key={option.id}>
                  <DropdownCategoryTitle key={option.id}>
                    {option.label}
                  </DropdownCategoryTitle>
                </div>
              );
            }
          })}
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
