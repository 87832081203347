import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown } from "lucide-react";

interface OptionType {
  value: string;
  label: string;
}

interface DropdownProps {
  value: string | null;
  setValue: (value: string | null) => void;
  options: OptionType[];
  placeholder?: string;
}

function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default function Dropdown({
  value,
  setValue,
  options,
  placeholder = "Select an option",
}: DropdownProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (optionValue: string) => {
    setValue(optionValue);
    setIsOpen(false);
  };

  const selectedOption = options.find((option) => option.value === value);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <Container
      ref={dropdownRef}
      onClick={toggleDropdown}
      animate={{
        borderColor: isOpen ? "rgba(255, 255, 255, 0.25)" : "transparent",
      }}
      transition={{ duration: 0.3 }}
    >
      <div>
        {selectedOption ? (
          selectedOption.label
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </div>
      <Arrow
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <ChevronDown height={20} opacity={0.5} />
      </Arrow>
      <AnimatePresence>
        {isOpen && (
          <OptionsContainer
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {options.map((option) => (
              <Option
                key={option.value}
                onClick={() => handleSelect(option.value)}
                whileHover={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                style={{
                  color:
                    option.value === value
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.5)",
                  backgroundColor:
                    option.value === value
                      ? "rgba(255, 255, 255, 0.1)"
                      : "transparent",
                }}
              >
                {option.label}
              </Option>
            ))}
          </OptionsContainer>
        )}
      </AnimatePresence>
    </Container>
  );
}

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3125rem;
  background-color: #191719;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  font-size: 0.75rem;
  font-weight: 500;
  min-height: 2.375rem;
`;

const Placeholder = styled.div`
  color: #fff;
  opacity: 0.5;
`;

const Arrow = styled(motion.div)`
  color: #fff;
  font-size: 12px;
`;

const OptionsContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #191719;
  border-radius: 0.3125rem;
  margin-top: 4px;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
`;

const Option = styled(motion.div)`
  padding: 8px 12px;
  cursor: pointer;
`;
