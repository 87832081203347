import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  background: #191719;
  border-bottom: 1px solid #363433;
  padding: 0 9px;
  min-height: 30px;
  width: 100%;

  .react-datepicker-wrapper {
  }
`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter", sans-serif;
  font-size: 10px;
  line-height: 1;
  padding: 0px;
  width: 60px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Separator = styled.span`
  color: #ffffff50;
  margin: 0 8px;
  margin-left: 2px;
`;

const customStyles = `
  .react-datepicker-wrapper{
    margin-top: -3px;
  }


  .react-datepicker {
    background-color: #191719;
    border: 1px solid #363433;
    font-size: 0.8rem;
  }
  .react-datepicker__header {
    background-color: #191719;
    border-bottom: 1px solid #363433;
    padding-top: 8px;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__year-text {
    color: #ffffff50;
  }
  .react-datepicker__current-month,
  .react-datepicker__year-header {
    font-size: 0.9rem;
    cursor: pointer;
    color: #ffffff50;
  }
  .react-datepicker__day-name, .react-datepicker__day,
  .react-datepicker__month-text, .react-datepicker__year-text {
    width: 1.7rem;
    line-height: 1.7rem;
    margin: 0.1rem;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__year-text:hover {
    background-color: #363433;
  }
  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__year-text--selected {
    background-color: white;
    color: #191719;
  }
  .react-datepicker-popper {
    left: 50px !important;
  }
  .react-datepicker__navigation {
    top: 8px;
  }
  .react-datepicker__month-text, .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  .react-datepicker__year--container {
    display: flex;
    flex-wrap: wrap;
    max-width: 195px;
    justify-content: center;
  }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 195px;
    justify-content: center;
  }
  .react-datepicker__year-text {
    width: 65px;
    margin: 2px;
  }
`;

interface DateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

const CustomInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ value, onClick }, ref) => (
  <StyledInput value={value} onClick={onClick} readOnly ref={ref} />
));

const ArrowButton = styled.button<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? "#ffffff20" : "#ffffff50")};
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const DateRange: React.FC<DateRangeProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [startDateView, setStartDateView] = useState<"date" | "month" | "year">(
    "date"
  );
  const [endDateView, setEndDateView] = useState<"date" | "month" | "year">(
    "date"
  );

  const handleStartDateChange = (date: Date | null) => {
    if (date && endDate && date > endDate) {
      onEndDateChange(date);
    }
    onStartDateChange(date);
    setStartDateView("date");
    setStartDateOpen(false);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date && startDate && date < startDate) {
      return;
    }
    onEndDateChange(date);
    setEndDateView("date");
    setEndDateOpen(false);
  };
  const renderCustomHeader = (
    view: "date" | "month" | "year",
    setView: React.Dispatch<React.SetStateAction<"date" | "month" | "year">>,
    isStart: boolean
  ) => {
    return ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }: any) => {
      const minDate = isStart ? undefined : startDate;
      const maxDate = isStart ? endDate : undefined;

      const isYearDisabled = (year: number) => {
        if (minDate && year < minDate.getFullYear()) return true;
        if (maxDate && year > maxDate.getFullYear()) return true;
        return false;
      };

      const isMonthDisabled = (month: number) => {
        if (
          minDate &&
          date.getFullYear() === minDate.getFullYear() &&
          month < minDate.getMonth()
        )
          return true;
        if (
          maxDate &&
          date.getFullYear() === maxDate.getFullYear() &&
          month > maxDate.getMonth()
        )
          return true;
        return false;
      };

      return (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff50",
          }}
        >
          <ArrowButton
            onClick={
              view === "year"
                ? () => changeYear(date.getFullYear() - 1)
                : decreaseMonth
            }
            disabled={
              view === "year"
                ? isYearDisabled(date.getFullYear() - 1)
                : prevMonthButtonDisabled ||
                  isMonthDisabled(date.getMonth() - 1)
            }
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1L1 6L6 11"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ArrowButton>
          <div
            onClick={() => setView(view === "date" ? "month" : "year")}
            style={{ cursor: "pointer", margin: "0 10px" }}
          >
            {view === "date" && (
              <>
                <span onClick={() => setView("month")}>
                  {date.toLocaleString("default", { month: "long" })}
                </span>{" "}
                <span onClick={() => setView("year")}>
                  {date.getFullYear()}
                </span>
              </>
            )}
            {view === "month" && (
              <span onClick={() => setView("year")}>{date.getFullYear()}</span>
            )}
            {view === "year" && <span>{date.getFullYear()}</span>}
          </div>
          <ArrowButton
            onClick={
              view === "year"
                ? () => changeYear(date.getFullYear() + 1)
                : increaseMonth
            }
            disabled={
              view === "year"
                ? isYearDisabled(date.getFullYear() + 1)
                : nextMonthButtonDisabled ||
                  isMonthDisabled(date.getMonth() + 1)
            }
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 11L6 6L1 1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ArrowButton>
        </div>
      );
    };
  };

  return (
    <>
      <style>{customStyles}</style>
      <DateRangeContainer>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          dateFormat="dd.MM.yyyy"
          customInput={<CustomInput />}
          popperClassName="some-custom-class"
          open={startDateOpen}
          onInputClick={() => setStartDateOpen(true)}
          onClickOutside={() => setStartDateOpen(false)}
          showMonthYearPicker={startDateView === "month"}
          showYearPicker={startDateView === "year"}
          renderCustomHeader={renderCustomHeader(
            startDateView,
            setStartDateView,
            true
          )}
          yearItemNumber={9}
        />
        <Separator>-</Separator>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd.MM.yyyy"
          customInput={<CustomInput />}
          popperClassName="some-custom-class"
          open={endDateOpen}
          onInputClick={() => setEndDateOpen(true)}
          onClickOutside={() => setEndDateOpen(false)}
          showMonthYearPicker={endDateView === "month"}
          showYearPicker={endDateView === "year"}
          renderCustomHeader={renderCustomHeader(
            endDateView,
            setEndDateView,
            false
          )}
          yearItemNumber={9}
        />
      </DateRangeContainer>
    </>
  );
};

export default DateRange;
