import React from "react";
import styled from "styled-components";

export default function SmallButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5625rem;
  min-height: 1.5625rem;
  background-color: #343231;
  border: none;
  outline: none;
  border-radius: 0.125rem;
  cursor: pointer;

  svg path {
    transition: stroke-opacity 0.2s;
  }

  &:hover {
    svg path {
      stroke-opacity: 1;
    }
  }
`;
