import useBeckSuggestions from "hooks/user/useBeckSuggestions";
import { ChevronDown } from "lucide-react";
import React, { useState } from "react";
import Select, {
  StylesConfig,
  components,
  MultiValueProps,
} from "react-select";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #191719;
  border-bottom: 1px solid #363433;
  padding: 4px 9px;
  width: 100%;
  font-size: 12px;
  min-height: 30px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const HorizontalMultiValue = styled.div`
  display: inline-flex;
  align-items: center;
  background: #363433;
  border-radius: 2px;
  margin-right: 4px;
  padding: 2px 4px;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export interface Option {
  value: string;
  label: string;
}

interface MultiSelectInputProps {
  type: string;
  placeholder?: string;
  onChange: (selected: (Option | string)[]) => void;
  value: (Option | string)[];
}

const customStyles: StylesConfig<Option, true> = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    border: "none",
    boxShadow: "none",
    padding: "0",
    minHeight: "auto",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#191719",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "#363433" : "transparent",
    color: "#fff",
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "red",
    margin: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#fff",
    padding: 0,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#fff",
    ":hover": {
      background: "transparent",
      color: "#ff6b6b",
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "rgba(255, 255, 255, 0.5) !important",
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
    margin: 0,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflowX: "auto",
    padding: 0,
  }),
};

const CustomMultiValue = (props: MultiValueProps<Option>) => (
  <HorizontalMultiValue>
    <components.MultiValueRemove {...props} />
  </HorizontalMultiValue>
);

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  type,
  placeholder = "",
  onChange,
  value,
}) => {
  const handleChange = (selected: Option[] | string[]) => {
    console.log({ inSelected: selected });
    onChange([...selected?.map((opt) => opt.value)]);
  };

  const [inputValue, setInputValue] = useState("");
  const { suggestions, isLoading, error, fetchSuggestions } =
    useBeckSuggestions(type);

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (newValue.length > 0) {
      fetchSuggestions(newValue);
    }
  };

  const options: Option[] = suggestions.map((suggestion) => ({
    value: suggestion.value,
    label: suggestion.label,
  }));

  return (
    <Container>
      <ChevronDown
        size={16}
        style={{
          marginRight: 4,
        }}
        color="#ffffff50"
      />
      <StyledSelect
        isMulti
        options={options}
        value={value?.map((v) => ({ value: v, label: v })) || []}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        styles={{
          ...customStyles,
          indicatorsContainer(base) {
            return {
              ...base,
              display: "none",
            };
          },
        }}
        components={{ MultiValue: CustomMultiValue }}
      />
    </Container>
  );
};

export default MultiSelectInput;
