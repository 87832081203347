import axiosWithAuth from "lib/utils/axiosWithAuth";
import { HomeWorkDataType } from "types/document";

export default async function listHomeWorks(): Promise<HomeWorkDataType[]> {
  const resp = await axiosWithAuth.get(
    `${process.env.REACT_APP_NOORIOO_API}/documents/list`
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
