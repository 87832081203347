import { motion } from "framer-motion";
import styled from "styled-components";

export default function EmptySectionText() {
  return (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <HeaderText>
        Das Recherchesystem, welches dir <span>10.000 stunden spart</span>
      </HeaderText>
      <SmText>
        Wir lesen alle Quellen auf der (fast) ganzen Welt durch und liefern Dir,
        dann die besten Ergebnisse und relevanten Informationen auf deine Frage.
      </SmText>
      <CurveContainer>
        <Image src={require("../../assets/images/sites.webp")} />
      </CurveContainer>
    </Container>
  );
}

const SmText = styled.p`
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  text-align: left;
  max-width: 50ch;
`;

const Container = styled(motion.div)`
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  position: relative;
`;

const HeaderText = styled.p`
  font-size: 1.375rem;
  line-height: 2.0625rem;
  max-width: 26ch;
  margin-bottom: 6px;
  font-family: "Exposure", sans-serif;

  span {
    font-style: italic;
    font-weight: 700;
  }
`;

const CurveContainer = styled.div`
  pointer-events: none;
  margin-top: 0.875rem;
  margin-bottom: 1.75rem;
`;

const Image = styled.img`
  height: 1.875rem;
  object-fit: contain;
`;
