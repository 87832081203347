import { atom } from "recoil";
import { ExportConfig } from "types/document";
import { UserDataType } from "types/user";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const exportSettingsModalState = atom<boolean>({
  key: "exportSettingsModalState",
  default: false,
});

export const exportFormatState = atom<"pdf" | "pages" | "docx" | "html">({
  key: "exportFormatState",
  default: undefined,
});

export const exportDocIdState = atom<string>({
  key: "exportDocIdState",
  default: undefined,
});

export const exportConfigState = atom<ExportConfig>({
  key: "exportConfigState",
  default: {
    text: 10, // pt
    heading1: 30, // pt
    heading2: 24, // pt
    heading3: 20, // pt
    font: "Times New Roman",
    leftSpacing: 10, // cm
    lineSpacing: 1.2, // cm
    rightSpacing: 1.5, // cm
    verticalSpacing: 2, //
    numberedPages: true, //
    showBibliography: true,
  },
  effects_UNSTABLE: [persistAtom],
});

export const settingsVisibilityState = atom<boolean>({
  key: "settingsVisibilityState",
  default: false,
});
