import { Editor } from "@tiptap/react";
import { createRoot } from "react-dom/client";
import IntegrateZitat from "components/General/IntegrateZitat";
import { RecoilRoot } from "recoil";

export function openIntegrateZitat(editor: Editor) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <RecoilRoot>
      <IntegrateZitat
        editor={editor}
        isSlashCommand={true}
        onClose={() => {
          root.unmount();
          document.body.removeChild(div);
        }}
      />
    </RecoilRoot>
  );
}
