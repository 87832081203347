import { Auth } from "@supabase/auth-ui-react";
import { ViewType } from "@supabase/auth-ui-shared";
import {
  Container,
  Title,
  Header,
  SmallText,
  Subtitle,
} from "components/General/Modal/commonStyles";
import Spacer from "components/General/Spacer";
import useOnboarding from "hooks/user/useOnboarding";
import useUser from "hooks/user/useUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import supabase from "supabase";
import { noorioLoginTheme } from "supabase/theme";

export default function AuthPage() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [view, setView] = useState<ViewType | null>(null);
  const { onboarding } = useOnboarding();
  const isUserOnboarded =
    !!onboarding?.watchedFullVideo || !!onboarding?.skippedVideo;

  useEffect(() => {
    if (!!user) {
      if (isUserOnboarded) {
        navigate("/");
      } else {
        navigate("/onboarding");
      }
    }
  }, [user]);

  const getTitle = () => {
    switch (view) {
      case null:
        return "Willkommen,";
      case "sign_up":
        return "Willkommen,";
      case "sign_in":
        return "Willkommen zurück,";
      case "forgotten_password":
        return "Passwort vergessen?";
      default:
        return "Willkommen,";
    }
  };

  const getSubtitle = () => {
    switch (view) {
      case null:
        return "deine ersten Schritte";
      case "sign_up":
        return "registriere dich bei Noorioo";
      case "sign_in":
        return "melde dich bei Noorioo an";
      case "forgotten_password":
        return "Setze dein Passwort zurück";
      default:
        return "melde dich bei Noorioo an";
    }
  };

  return (
    <PageWrapper>
      <LeftSection>
        <Text>Mit Noorioo studieren, wie in 2024 - nicht wie in 1900.</Text>
      </LeftSection>
      <RightSection>
        <StyledContainer>
          <Header>
            <Title>{getTitle()}</Title>
            <Subtitle>{getSubtitle()}</Subtitle>
          </Header>
          <Spacer height={10} />

          <Auth
            supabaseClient={supabase}
            view={view}
            appearance={{
              theme: noorioLoginTheme,
              className: {
                anchor: "!hidden",
                button: "!mb-[-12px]",
                divider: "!mt-[28px]",
              },
            }}
            providers={["google"]}
            redirectTo={`${window.location.origin}/auth/callback`}
            localization={{
              variables: {
                sign_in: {
                  social_provider_text: "Mit Google fortfahren",
                  button_label: "Anmelden",
                  email_input_placeholder: "Deine Email",
                  password_input_placeholder: "Dein Password",
                  loading_button_label: "Anmeldung läuft ...",
                },
                sign_up: {
                  social_provider_text: "Mit Google fortfahren",
                  button_label: "Registrieren",
                  email_input_placeholder: "Deine Email",
                  password_input_placeholder: "Dein Password",
                  loading_button_label: "Registrierung läuft ...",
                },
                forgotten_password: {
                  email_label: "E-Mail",
                  password_label: "Passwort",
                  email_input_placeholder: "Deine Email",
                  button_label: "Passwort zurücksetzen",
                  loading_button_label: "Sende E-Mail...",
                },
              },
            }}
          />
          {view === "forgotten_password" && <Spacer height={25} />}
          <ViewToggleContainer>
            {view === null && (
              <>
                <Spacer height={22} />
                <Button onClick={() => setView("sign_up")}>Registrieren</Button>
                <Spacer height={17} />
                <Button onClick={() => setView("sign_in")}>Anmelden</Button>
              </>
            )}
            {view === "sign_in" && (
              <>
                <ViewToggleButton onClick={() => setView("forgotten_password")}>
                  Passwort vergessen?
                </ViewToggleButton>
                <ViewToggleButton onClick={() => setView("sign_up")}>
                  Neu bei Noorioo? Registriere dich
                </ViewToggleButton>
              </>
            )}
            {view === "sign_up" && (
              <ViewToggleButton onClick={() => setView("sign_in")}>
                Du bist schon bei Noorioo? Melde dich an
              </ViewToggleButton>
            )}
            {view === "forgotten_password" && (
              <ViewToggleButton onClick={() => setView("sign_in")}>
                Zurück zur Anmeldung
              </ViewToggleButton>
            )}
          </ViewToggleContainer>
        </StyledContainer>
      </RightSection>
    </PageWrapper>
  );
}
const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`;

const StyledContainer = styled(Container)`
  max-width: 500px;
  width: 100%;
  background-color: #1c1b1b;

  @media (max-width: 500px) {
    padding: 1rem;
  }
`;

const LeftSection = styled.div`
  background-color: #212121;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 2.8125rem;
  line-height: 3.4375rem;
  width: 100%;

  @media (max-width: 992px) {
    padding: 2rem 4rem;
    min-height: 300px;
  }

  @media (max-width: 500px) {
    min-height: 200px;
    padding: 2rem;
  }
`;

const Text = styled.div`
  font-family: "Exposure";
  max-width: 30.375rem;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 1.5;
  }
`;

const RightSection = styled.div`
  background-color: #1c1b1b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;

  @media (max-width: 500px) {
    align-items: flex-start;
  }
`;

const ViewToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: -20px;
`;

const ViewToggleButton = styled.button`
  background: none;
  border: none;
  color: #ffffff80;
  cursor: pointer;
  font-size: 10px;
  line-height: 15.6px;
  text-decoration: underline;
  margin: 2px 0;
`;

const Button = styled.button`
  background-color: #2383e2;
  border-radius: 5px;
  padding: 10px 14px;
  font-size: 12px;
  width: 100%;
  text-align: left;
`;
