import styled from "styled-components";
import { Logo, TiltedArrowIcon } from "../../constants/Svgs";
import { Link, useLocation } from "react-router-dom";
import { LeftPad } from "../../styles/Layout";
import UpgradeButton from "./UpgradeButton";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { newHomeWorkModalState, settingsModalState } from "recoil/modal";
import useOnboarding from "hooks/user/useOnboarding";
import { userDataState } from "recoil/auth";
import FormatSettings from "components/HomeWork/FormatSetting";

export default function Header() {
  const setOpenSettings = useSetRecoilState(settingsModalState);
  const setOpenNewHomeWorkModal = useSetRecoilState(newHomeWorkModalState);
  const userData = useRecoilValue(userDataState);
  const { onboarding, updateOnboarding } = useOnboarding();
  const location = useLocation();
  const fullPath = location.pathname + location.search;

  const Links = [
    {
      name: "Hausaufgaben",
      path: "/",
      // onClick: () => setOpenNewHomeWorkModal(true),
    },
    {
      name: "Hilfezentrum",
      path: "https://help.noorioo.com/",
      onClick: () => {
        if (!onboarding?.helpDesk) {
          updateOnboarding("helpDesk");
        }
      },
    },
    {
      name: "Benutzereinstellungen",
      path: fullPath,
      onClick: () => setOpenSettings(true),
    },
  ];

  return (
    <Container>
      <FormatSettings />
      {!userData?.isPremiumUser && (
        <FreeCredits>
          Sie haben {userData?.credits || 0} kostenlose Credits in Ihrem
          kostenlosen Plan
        </FreeCredits>
      )}
      {!onboarding?.universityEmail && (
        <VerifyNotification>
          Verifiziere Deine Universitätsemail und erhalte Zugang zu allen
          Quellen.
          <TiltedArrowIcon />
        </VerifyNotification>
      )}
      <HeaderWrapper>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <Separator />

        {Links.map((link) => (
          <StyledLink to={link?.path} key={link.name} onClick={link?.onClick}>
            {link.name}
          </StyledLink>
        ))}

        {!userData?.isPremiumUser && <UpgradeButton />}
      </HeaderWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const HeaderWrapper = styled.header`
  display: flex;
  min-height: 3.125rem;
  align-items: stretch;
  padding: 1rem;
  background-color: #1c1b1b;
  color: #fff;
  padding: 12px var(--padding-right) 12px var(--padding-left);
`;

const Separator = styled.div`
  min-height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.08);
  margin-left: 1rem;
  margin-right: 0.75rem;
`;

const StyledLink = styled(Link)`
  color: #cccccc;
  text-decoration: none;
  transition: color 0.2s;
  cursor: pointer;
  margin-right: 1.25rem;
  align-self: center;
  font-size: 0.75rem;

  &:hover {
    color: #fff;
  }
`;

const FreeCredits = styled(LeftPad)`
  background-color: rgba(35, 131, 226, 0.25);
  color: #5caeff;
  font-size: 0.75rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerifyNotification = styled(FreeCredits)`
  background-color: rgba(255, 50, 0, 0.251);
  border-top: 1px solid rgba(255, 129, 48, 0.051);
  gap: 3px;
  color: #ff8130;
`;
