export interface HomeWorkDataType {
  _id?: string;
  title: string;
  module: string;
  content: string; // HTML Content of the homework
  userId: string; // UUID
  status: HomeWorkStatus;
  createdAt: Date;
  lastEditedAt: Date;
}

export enum HomeWorkStatus {
  WORKED_ON = "worked_on",
  PAUSED = "paused",
  FINISHED = "finished",
}

export interface ExportConfig {
  verticalSpacing: number; // This corresponds to "Top & Bototm spacing" in cm
  rightSpacing: number;
  leftSpacing: number; // This corresponds to "Site spacing" in cm
  heading1: number; // in pt
  heading2: number; // in pt
  heading3: number; // in pt
  text: number; // in pt
  lineSpacing: number; // This is a multiplier
  font: string;
  numberedPages: boolean;
  showBibliography: boolean;
}
