import { differenceInDays, format, parse, parseISO } from "date-fns";

export function formatDottedDate(timestamp: number | string | Date): string {
  return format(timestamp, "dd.MM.yyyy");
}

export function formatDottedDateTime(
  timestamp: number | string | Date
): string {
  return format(timestamp, "dd.MM.yyyy - hh:mm:ss");
}

export const isRecentlyAdded = (timestamp: number | string | Date): boolean => {
  const now = new Date();
  return differenceInDays(now, timestamp) <= 3;
};

export function parseDottedDate(dateString: string): Date {
  return parse(dateString, "dd.MM.yyyy", new Date());
}
