import {
  formatDottedDate,
  formatDottedDateTime,
  isRecentlyAdded,
} from "lib/utils/date";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  exportDocIdState,
  exportFormatState,
  exportSettingsModalState,
} from "recoil/export";
import styled, { keyframes } from "styled-components";
import { HomeWorkDataType, HomeWorkStatus } from "types/document";

const StatusMap = {
  [HomeWorkStatus.WORKED_ON]: "Bearbeitet",
  [HomeWorkStatus.PAUSED]: "Pausiert",
  [HomeWorkStatus.FINISHED]: "Fertig",
};

const getStatusColor = (status: HomeWorkStatus) => {
  switch (status) {
    case HomeWorkStatus.PAUSED:
      return "#FBBC04";
    case HomeWorkStatus.FINISHED:
      return "#00D885";
    default:
      return "#ffffff";
  }
};

const HomeWorkTable = ({
  header,
  withHeaders,
  data = [],
}: {
  header?: string;
  withHeaders?: boolean;
  data?: HomeWorkDataType[];
}) => {
  const nagivate = useNavigate();
  const setExportModalOpen = useSetRecoilState(exportSettingsModalState);
  const setExportFormat = useSetRecoilState(exportFormatState);
  const setExportDocId = useSetRecoilState(exportDocIdState);

  const handleExportClick = (
    e: React.MouseEvent<HTMLDivElement>,
    format: "pdf" | "pages" | "docx" | "html",
    id?: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Export button clicked!");
    setExportModalOpen(true);
    setExportFormat(format);
    setExportDocId(id);
  };

  if (data.length === 0) return null;

  return (
    <TableContainer>
      {header && <Header>{header}</Header>}
      {withHeaders && (
        <TableRow>
          <TableCell>Datum</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Zuletzt bearbeitet am</TableCell>
          <TableCell>Exportoptionen</TableCell>
        </TableRow>
      )}

      {data.map((row, i) => {
        // added with in last 3 days
        const isRecent = isRecentlyAdded(row.createdAt);
        return (
          <TableRow
            key={row._id}
            $light
            $showTopBorder={i === 0 && !withHeaders}
            onClick={() => nagivate(`/homework?id=${row._id}`)}
          >
            <Date>{formatDottedDate(row.createdAt)}</Date>
            <TableCell>{row.title}</TableCell>
            <Status $color={getStatusColor(row.status)}>
              {StatusMap[row.status]}
              {isRecent && (
                <>
                  <StatusTag>Recently added</StatusTag>
                </>
              )}
            </Status>
            <Date>{formatDottedDateTime(row.lastEditedAt)}</Date>
            <Row>
              <DownloadButton
                onClick={(e) => handleExportClick(e, "pdf", row._id)}
              >
                .pdf
              </DownloadButton>
              <Separator />
              <DownloadButton
                onClick={(e) => handleExportClick(e, "pages", row._id)}
              >
                .pages
              </DownloadButton>
              <Separator />
              <DownloadButton
                onClick={(e) => handleExportClick(e, "docx", row._id)}
              >
                .docx
              </DownloadButton>
              <Separator />
              <DownloadButton
                onClick={(e) => handleExportClick(e, "html", row._id)}
              >
                .html
              </DownloadButton>
            </Row>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};

export default HomeWorkTable;

const TableContainer = styled.div`
  color: #e0e0e0;
  font-family: sans-serif;
  width: 100%;
`;

const TableRow = styled.div<{ $light?: boolean; $showTopBorder?: boolean }>`
  display: grid;
  grid-template-columns: 0.6fr repeat(3, 1fr) 11.875rem;
  padding: 10px var(--padding-left);
  align-items: center;
  min-height: 2.8125rem;
  ${({ $showTopBorder }) =>
    $showTopBorder ? "border-top: 1px solid rgba(255, 255, 255, 0.1);" : ""}
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  opacity: ${(props) => (props.$light ? 0.95 : 1)};

  &:first-child {
    font-weight: bold;
  }
`;

const TableCell = styled.div`
  text-align: left;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
`;

const DownloadButton = styled(TableCell)`
  cursor: pointer;
`;

const bgChange = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
`;

const StatusTag = styled.div`
  background: linear-gradient(65deg, #e4e4e4, #535353);
  background-size: 200% 100%;
  color: white;
  padding: 0.25rem 0.4375rem;
  border-radius: 0.9375rem;
  font-size: 0.625rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  animation: ${bgChange} 1s linear infinite alternate;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0.9375rem;
    padding: 1px;
    background: linear-gradient(45deg, #d9d9d9, #737373);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background-size: 200% 100%;

    animation: ${bgChange} 0.5s linear infinite alternate;
  }
`;

const Date = styled(TableCell)`
  font-family: "BerkeleyMono";
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;

const Separator = styled.div`
  width: 1px;
  height: 14px;
  background-color: rgba(255, 255, 255, 0.15);
`;

const Status = styled(TableCell)<{ $color: string }>`
  display: flex;
  align-items: center;
  gap: 1.125rem;
  color: ${(props) => props.$color};
`;

const Header = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  font-size: "Inter", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  padding: 1rem var(--padding-left);
  padding-top: 2.125rem;
`;
