import { Theme } from "@supabase/auth-ui-shared";

export const noorioLoginTheme: Theme = {
  default: {
    borderWidths: {
      buttonBorderWidth: "1px",
      inputBorderWidth: "1px",
    },
    colors: {
      defaultButtonBackground: "#191719",
      defaultButtonText: "#fff",
      defaultButtonBorder: "rgba(38, 39, 47, 0.5)",
      defaultButtonBackgroundHover: "initial",
      brand: "#2383E2",
      brandAccent: "#1e6ab3",
      brandButtonText: "white",
      dividerBackground: "rgba(255, 255, 255, 0.15)",
      inputBackground: "#191719",
      inputBorderFocus: "rgba(255, 255, 255, 0.25)",
      inputBorderHover: "rgba(255, 255, 255, 0.25)",
      inputBorder: "rgba(38, 39, 47, 0.5)",
      inputText: "#fff",
      inputPlaceholder: "rgba(255, 255, 255, 0.5)",
      messageTextDanger: "#ff4d4f",
      anchorTextColor: "rgba(255, 255, 255, 0.5)",
    },
    fonts: {
      bodyFontFamily: "Inter, sans-serif",
      buttonFontFamily: "Inter, sans-serif",
      inputFontFamily: "Inter, sans-serif",
      labelFontFamily: "Inter, sans-serif",
    },
    fontSizes: {
      baseInputSize: "0.75rem",
      baseButtonSize: "0.75rem",
      baseLabelSize: "0.75rem",
      baseBodySize: "0.625rem",
    },
    space: {
      inputPadding: "10px 14px",
      buttonPadding: "10px 14px",
      anchorBottomMargin: "2px",
      spaceLarge: "8px",
      spaceMedium: "6px",
      spaceSmall: "2px",
    },
    radii: {
      borderRadiusButton: "5px",
      inputBorderRadius: "5px",
      buttonBorderRadius: "5px",
    },
  },
};
