import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.label<{ $disabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 1.625rem;
  transition: all 0.4s ease;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
`;

const SwitchInput = styled.input`
  display: none;
`;

const SwitchSlider = styled.div<{ $checked: boolean; $disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $checked, $disabled }) =>
    $disabled && $checked ? "#4cd964" : $checked ? "#4cd964" : "#f5f5f5"};
  border-radius: 2.125rem;
  transition: all 0.4s ease;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};

  &::before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: 0.1875rem;
    top: 0.1875rem;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.4s;
    stroke-width: 0.0313rem;
    stroke: rgba(0, 0, 0, 0.04);
    filter: drop-shadow(0rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.16))
      drop-shadow(0rem 0.1875rem 0.5rem rgba(0, 0, 0, 0.15));

    ${({ $checked }) =>
      $checked &&
      `transform: translateX(calc(100% - -0.25rem));
      -webkit-transform: translateX(calc(100% - -0.25rem));
      -moz-transform: translateX(calc(100% - -0.25rem));
      -ms-transform: translateX(calc(100% - -0.25rem));
      -o-transform: translateX(calc(100% - -0.25rem));
      filter: drop-shadow(0.0625rem 0px 0.25rem rgba(0, 0, 0, 0.25))
    `}
  }
`;

type IOSStyleSwitchProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disableWhenOn?: boolean;
};

const IOSStyleSwitch: React.FC<IOSStyleSwitchProps> = ({
  checked,
  onChange,
  disableWhenOn = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    if (disableWhenOn && isChecked) {
      return; // Do nothing if the switch is on and disableWhenOn is true
    }
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  const isDisabled = disableWhenOn && isChecked;

  return (
    <SwitchContainer>
      <SwitchLabel $disabled={isDisabled}>
        <SwitchInput
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          disabled={isDisabled}
        />
        <SwitchSlider $checked={isChecked} $disabled={isDisabled} />
      </SwitchLabel>
    </SwitchContainer>
  );
};

export default IOSStyleSwitch;
