import UserRequired from "components/Auth/UserRequired";
import CustomLayout from "components/General/CustomLayout";
import useOnboarding from "hooks/user/useOnboarding";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isUserOnboardedState } from "recoil/auth";
import { verfiyEmailModalState } from "recoil/modal";
import styled from "styled-components";

interface VideoContainerProps {
  isPlaying: boolean;
}

export default function Onboarding() {
  const navigate = useNavigate();
  const setVerifyEmail = useSetRecoilState(verfiyEmailModalState);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isStepOneDone, setStepOneDone] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoUrl = "/video.mp4";
  const { onboarding, updateOnboarding } = useOnboarding();
  const watchedFullVideo = !!onboarding?.watchedFullVideo;
  const skippedVideo = !!onboarding?.skippedVideo;

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!videoRef.current.paused);
    }
  }, []);

  const handleOnboard = () => {
    updateOnboarding("watchedFullVideo");
  };

  const handleSkip = () => {
    updateOnboarding("skippedVideo");
  };

  useEffect(() => {
    if (watchedFullVideo || skippedVideo) {
      navigate("/");
    }
  }, [watchedFullVideo, skippedVideo]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleVideoEnd = () => {
        console.log("video ended");
        handleOnboard();
      };

      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      videoElement.addEventListener("ended", handleVideoEnd);
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);

      return () => {
        videoElement.removeEventListener("ended", handleVideoEnd);
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      };
    }
  }, [handleOnboard]);

  return (
    <UserRequired>
      <CustomLayout>
        <Content $isStepOneDone={isStepOneDone}>
          {!isStepOneDone ? (
            <>
              <HeaderText>
                1<span>/2 -</span> E-Mail-Verifizierung für vollen Quellenzugang
              </HeaderText>
              <Text>
                Verifiziere Deine Universitötsemail und erhalte Zugang zu allen
                Quellen die Du brauchst. Von Beck Online bis zu JSTOR.
              </Text>
              <Box1>
                <Left>
                  <SitesImage src={require("../assets/images/sites2.png")} />
                  <BoldText>Kompletter Zugang</BoldText>
                  <Item text="Erhalte Zugang zu allen Quellen" />
                  <Item text="Hochqualitativere Quellen " />
                  <Item text="Ohne zusätzliche Kosten" />
                  <Verify onClick={() => setVerifyEmail(true)}>
                    Universitätsemail verifizieren
                  </Verify>
                  <Text>
                    Wenn Du die kurze Tour schaust erhältst Du zusätzlich 25
                    Gratis-Credits. Bei Überspringen bekommst Du diese nicht.
                  </Text>
                </Left>
                <Center>
                  <Strip />
                  <CenterText>ODER</CenterText>
                  <Strip />
                </Center>
                <Right>
                  <BasicContainer>
                    <Basic>Basic</Basic>
                  </BasicContainer>
                  <BoldText>Standart Zugang</BoldText>
                  <Item dot text="Öffentlich zugängliche Quellen" />
                  <Item dot text="Keine zusätzlichen Kosten" />
                  {/* <Item dot text="Public available data" /> */}
                  <Verify onClick={() => setStepOneDone(true)}>
                    Standart Zugang behalten
                  </Verify>
                </Right>
              </Box1>
            </>
          ) : (
            <>
              <HeaderText>
                2<span>/2 -</span> Wie starte ich mit Noorioo
              </HeaderText>
              <Text>
                Verstehe wie Noorioo funktioniert innerhalb von 4-Minuten und
                erhalte 25-Gratis Credits.
              </Text>
              <VideoContainer>
                <GradientOverlay
                  isPlaying={isPlaying}
                  className={`gradient-overlay ${isPlaying ? "playing" : ""}`}
                >
                  <PlayButton onClick={togglePlay}>
                    {isPlaying ? (
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="5.5"
                          y="4"
                          width="3"
                          height="12"
                          rx="1"
                          fill="white"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="12.5"
                          y="4"
                          width="3"
                          height="12"
                          rx="1"
                          fill="white"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5937 10.3651C15.8815 10.2067 15.8815 9.79324 15.5937 9.63491L7.78409 5.3396C7.5064 5.18687 7.16663 5.38777 7.16663 5.70469V14.2953C7.16663 14.6122 7.5064 14.8131 7.78409 14.6604L15.5937 10.3651Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}

                    <PlayText>
                      {isPlaying ? "Pause" : "Play"} the video
                    </PlayText>
                  </PlayButton>
                </GradientOverlay>
                <StyledVideo
                  id="demoVideo"
                  ref={videoRef}
                  controls={isPlaying}
                  onSeeked={() => {}}
                  onSeeking={() => {}}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </StyledVideo>
              </VideoContainer>
              <CenterRow>
                <Skip onClick={handleSkip}>Überspringe (keine Credits)</Skip>
                <StyledText>
                  Wenn Du die kurze Tour schaust erhältst Du zusätzlich 25
                  Gratis-Credits. Bei Überspringen bekommst Du diese nicht.
                </StyledText>
              </CenterRow>
            </>
          )}
        </Content>
      </CustomLayout>
    </UserRequired>
  );
}

const Item = ({ text, dot }: { text: string; dot?: boolean }) => (
  <Row>
    {dot ? (
      <svg
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="4" height="4" rx="2" fill="white" fill-opacity="0.33" />
      </svg>
    ) : (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 8.41726L4.5 12.9173L14 3.41726L12.5828 2L4.5 10.0827L1.41725 7L0 8.41726Z"
          fill="white"
          fill-opacity="0.33"
        />
      </svg>
    )}
    <p>{text}</p>
  </Row>
);

const SitesImage = styled.img`
  height: 1.875rem;
  object-fit: contain;
`;

const Content = styled.div<{ $isStepOneDone?: boolean }>`
  padding-top: 60px;
  max-width: ${({ $isStepOneDone }) => ($isStepOneDone ? "640px" : "768px")};
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1100px) {
    padding: 1rem;
    padding-top: 3rem;
  }
`;

const HeaderText = styled.div`
  font-size: 28px;
  font-family: "Exposure";
  line-height: 1;
  margin-bottom: 12px;
  color: #ededed;
  letter-spacing: 0.1px;

  span {
    opacity: 0.5;
  }
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  margin-bottom: 40px;

  @media (max-width: 1100px) {
    margin-bottom: 10px;
  }
`;

const Box1 = styled.div`
  padding: 34px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  background-color: #343231;
  display: flex;
  gap: 3rem;

  @media (max-width: 1100px) {
    padding: 1rem;
    flex-direction: column;
    gap: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 18px;
    color: #ededed;
  }
`;

const Right = styled.div`
  flex: 1;
`;
const Left = styled.div`
  flex: 1;
`;

const Center = styled.div`
  flex: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoldText = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 28px;
  margin-bottom: 24px;
`;

const Verify = styled.div`
  margin-top: 42px;
  margin-bottom: 10px;
  height: 35px;
  width: auto;
  max-width: fit-content;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  text-wrap: nowrap;
  padding: 0 10px;

  &:hover {
    border-color: #fff;
  }
`;

const Basic = styled.div`
  height: 22px;
  width: 82px;
  background-color: rgba(208, 218, 237, 0.3);
  color: #0a0b0d;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
`;

const BasicContainer = styled.div`
  height: 30px;
`;

const Strip = styled.div`
  height: 50%;
  width: 1px;
  background-color: #fff;
  opacity: 0.1;

  @media (max-width: 1100px) {
    height: 1px;
    width: 100%;
  }
`;

const CenterText = styled.div`
  font-size: 12px;
  color: #fff;
  opacity: 0.3;
  text-align: center;

  @media (max-width: 1100px) {
    margin: 0.25rem 0;
  }
`;

const Skip = styled(Verify)`
  margin: 0;
`;

const CenterRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0;
  max-width: 40ch;
`;

const PlayText = styled.div`
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
`;

const VideoContainer = styled.div`
  max-width: 640px;
  width: 100%;
  min-height: 350px;
  border-radius: 5px;
  margin-bottom: 14px;
  position: relative;
  overflow: hidden;

  &:hover .gradient-overlay {
    opacity: 1;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  object-fit: cover;
`;

const GradientOverlay = styled.div<VideoContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isPlaying ? 0 : 1)};
  pointer-events: auto;
  transition: opacity 0.3s ease;
  z-index: 999;

  &:hover {
    opacity: 1;
  }

  &.playing {
    display: none;
  }
`;

const PlayButton = styled.button`
  background-color: #000;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  cursor: pointer;
  gap: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
