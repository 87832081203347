import { useState, useEffect } from "react";
import axios from "axios";
import axiosWithAuth from "lib/utils/axiosWithAuth";

interface Suggestion {
  id: string;
  label: string;
  value: string;
}

const useBeckSuggestions = (type: string) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSuggestions = async (term: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const resp = await axiosWithAuth.get(
        `${process.env.REACT_APP_NOORIOO_API}/citation/beck-suggestions?type=${type}&term=${term}`
      );

      if (!resp.data) {
        throw new Error("Some error occured");
      }
      if (resp.data.error) {
        throw new Error(resp.data.error);
      }

      if (resp.status === 200) {
        setSuggestions(resp?.data || []);
      } else {
        setError(`Error: Status code ${resp.status}`);
      }
    } catch (err) {
      setError("An error occurred while fetching suggestions");
    } finally {
      setIsLoading(false);
    }
  };

  return { suggestions, isLoading, error, fetchSuggestions };
};

export default useBeckSuggestions;
