import { FormEvent, useEffect, useState } from "react";
import Input from "../General/Input";
import Button from "../General/Button";
import styled from "styled-components";
import { ArrowIcon } from "../../constants/Svgs";
import EmptySectionText from "./EmptySectionText";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import CircularLoader from "components/General/CircularLoader";
import LoadingComponent from "components/General/Loader";

import { useRefreshUser } from "hooks/user/useUser";
import QuestionV2 from "./QuestionV2";
import searchTldr from "lib/question/searchTldr";
import { toast } from "sonner";

const thresholdScore = 0.6;

export default function TldrSection() {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAnswer, setNewAnswer] = useState("");
  const [sources, setSources] = useState<{ url: string; title: string }[]>([]);
  const refreshUser = useRefreshUser();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewAnswer("");
    setSearched(false);
    if (!loading) {
      try {
        if (!search) throw new Error("Please enter a question to search");
        setLoading(true);
        const result = await searchTldr(search);
        if (!result.answer) {
          setSearched(false);
          return toast.error("No answer found");
        }
        setSearched(true);
        setNewAnswer(result.answer || "");
        setSources(result.sources || []);
        refreshUser();
      } catch (e) {
        axiosErrorHandler(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (searched) {
      setLoading(false);
    }
  }, [searched]);

  return (
    <>
      {loading ? (
        <LoadingComponent repeat={2} question={search} />
      ) : (
        <>
          {!searched && !newAnswer && sources.length === 0 ? (
            <EmptySectionText />
          ) : (
            <QuestionV2
              sources={sources}
              answer={newAnswer}
              question={search}
            />
          )}
        </>
      )}

      <InputContainer
        style={{
          marginTop: searched ? "auto" : "0",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Input
            value={search}
            onChange={setSearch}
            isExpandable
            placeholder="Eingabe"
          />
          <Button type="submit" square disabled={!searched ? !search : false}>
            {loading ? <CircularLoader loading={loading} /> : <ArrowIcon />}
          </Button>
        </Form>
        <Text>
          Noorioo, kann möglicherweise falsche Antworten erstellen. Immer
          doppelt überprüfen.
        </Text>
      </InputContainer>
    </>
  );
}

const InputContainer = styled.div`
  width: auto;
  position: sticky;
  bottom: 0;
  background-color: #212121;
  padding-top: 1.5rem;
  padding-bottom: 1.875rem;
  z-index: 2;
  padding-left: 1.625rem;
  padding-right: 1.25rem;
  margin-left: -1.625rem;
  margin-right: -1.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 0.5625rem;
`;

const Text = styled.p`
  font-size: 0.625rem;
  margin-top: 0.5rem;
  color: #808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
