import { User } from "@supabase/supabase-js";
import { atom } from "recoil";
import { UserDataType } from "types/user";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const savedUser = localStorage.getItem("user");
const initialUser = savedUser ? JSON.parse(savedUser) : null;

export const userState = atom<User | null>({
  key: "userState",
  default: initialUser,
});

export const userDataState = atom<UserDataType>({
  key: "userDataState",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const isUserOnboardedState = atom<boolean>({
  key: "isUserOnboardedState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
