import Modal from "./Modal";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { Container, Header, Subtitle, Title } from "./Modal/commonStyles";
import Spacer from "./Spacer";
import Divider from "./Divider";
import styled from "styled-components";
import IOSStyleSwitch from "./Switch";
import { Button } from "components/ui/Button";
import Sources from "components/HomeWork/Sources";
import { LinkIcon } from "lucide-react";
import { useEffect, useState } from "react";
import {
  exportConfigState,
  exportDocIdState,
  exportFormatState,
  exportSettingsModalState,
} from "recoil/export";
import { currentHomeWorkContentState } from "recoil/homework";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import getHomeWork from "lib/homework/getHomeWork";
import CircularLoader from "./CircularLoader";
import { HomeWorkDataType } from "types/document";
import { saveAs } from "file-saver";
import {
  createDocFromHtml,
  createHtmlFromDocx,
  createPdfFromDocx,
} from "lib/export";

const STYLE_OPTIONS = [
  {
    label: "APA",
    value: "apa",
  },
  {
    label: "Oxford",
    value: "oxford",
  },
  {
    label: "Classic",
    value: "classic",
  },
  {
    label: "Jura",
    value: "jura",
  },
];

const EXPORT_FORMATS: {
  label: string;
  value: "pdf" | "pages" | "docx" | "html";
}[] = [
  {
    label: ".pdf",
    value: "pdf",
  },
  {
    label: ".docx",
    value: "docx",
  },
  {
    label: ".html",
    value: "html",
  },
  {
    label: ".pages",
    value: "pages",
  },
];

export default function ExportSettingsModal() {
  const [exportSettingsModalOpen, setExportSettingsModalOpen] = useRecoilState(
    exportSettingsModalState
  );

  return (
    <Modal
      hideClose
      open={exportSettingsModalOpen}
      onClose={() => setExportSettingsModalOpen(false)}
    >
      <Container>
        <Header>
          <Title>Export it.</Title>
          <Subtitle>Verwalten Deine Export-Einstellungen.</Subtitle>
        </Header>
        <Spacer height={40} />
        <Divider noMargin />
        <Spacer height={29} />
        <ManageSettings setOpen={setExportSettingsModalOpen} />
      </Container>
    </Modal>
  );
}

export const Option = ({
  text,
  click,
  active,
}: {
  text: string;
  click: () => void;
  active: boolean;
}) => {
  return (
    <StyledOption $active={active} onClick={click}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.94222 10.4773L6.05834 11.3612C4.83795 12.5815 2.85931 12.5815 1.63892 11.3612C0.418535 10.1408 0.418536 8.16213 1.63892 6.94174L2.52281 6.05785M10.4778 6.94174L11.3616 6.05785C12.582 4.83746 12.582 2.85882 11.3616 1.63844C10.1413 0.418047 8.16261 0.418048 6.94222 1.63844L6.05834 2.52232M4.31278 8.68728L8.68778 4.31228"
          stroke="white"
          stroke-opacity="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {text}
    </StyledOption>
  );
};

const ManageSettings = ({ setOpen }: { setOpen: SetterOrUpdater<boolean> }) => {
  const [activeOption, setActiveOption] = useState("apa");
  const [activeExportFormat, setActiveExportFormat] =
    useRecoilState(exportFormatState);
  const [exportDocId, setExportDocId] = useRecoilState(exportDocIdState);
  const [exportConfigs, setExportConfigs] = useRecoilState(exportConfigState);
  const [exportDoc, setExportDoc] = useState<HomeWorkDataType>();
  const [loading, setLoading] = useState(false);

  console.log({ exportConfigs });

  useEffect(() => {
    (async () => {
      if (exportDocId && !loading) {
        try {
          setLoading(true);
          const resp = await getHomeWork(exportDocId);
          setExportDoc(resp);
          // console.log(resp.content);
        } catch (e) {
          axiosErrorHandler(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [exportDocId]);

  const handleClose = () => {
    setOpen(false);
    setExportDocId(undefined);
    setExportDoc(undefined);
  };

  const handleExport = async () => {
    if (loading || !exportDocId || !exportDoc) return;
    try {
      setLoading(true);
      // console.log("export clicked", exportDocId, exportDoc);
      if (activeExportFormat === "docx") {
        const docBlob = await createDocFromHtml(
          exportDoc.content,
          exportConfigs
        );
        saveAs(docBlob, `${exportDoc.title}.docx`);
      } else if (activeExportFormat === "pdf") {
        await createPdfFromDocx(
          exportDoc.title,
          exportDoc.content,
          exportConfigs
        );
      } else if (activeExportFormat === "html") {
        await createHtmlFromDocx(
          exportDoc.title,
          exportDoc.content,
          exportConfigs
        );
      }
    } catch (e) {
      axiosErrorHandler(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmHeader>
        <Title>Literaurverzeichnis</Title>
        <Subtitle>
          Es wird automatisch ein Literaurverzeichnis erstellt
        </Subtitle>
      </SmHeader>
      <Row>
        <IOSStyleSwitch
          checked={exportConfigs?.showBibliography}
          onChange={(e) => {
            setExportConfigs((prev) => ({ ...prev, showBibliography: e }));
          }}
        />
        <SmText>
          Durch Aktivierung dieser Option,
          <br /> exportieren Sie mit dem Literaurverzeichnis.
        </SmText>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={29} />
      <SmHeader>
        <Title>Exportieren</Title>
        <Subtitle>mit nummerierten Seiten</Subtitle>
      </SmHeader>
      <Row>
        <IOSStyleSwitch
          checked={exportConfigs?.numberedPages}
          onChange={(e) => {
            setExportConfigs((prev) => ({ ...prev, numberedPages: e }));
          }}
        />
        <SmText>
          Autovervollständigung
          <br />
          Sie exportieren immer mit nummerierten Seiten
        </SmText>
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={29} />
      <SmHeader>
        <Title>Exportformat</Title>
        <Subtitle>Wähle ein Format aus</Subtitle>
      </SmHeader>
      <Row>
        {EXPORT_FORMATS.map((style) => (
          <Option
            key={style.value}
            text={style.label}
            click={() => {
              setActiveExportFormat(style.value);
            }}
            active={activeExportFormat === style.value}
          />
        ))}
      </Row>
      <Spacer height={29} />
      <Divider noMargin />
      <Spacer height={40} />
      <Button
        disabled={loading}
        onClick={handleExport}
        className="hover:bg-slate-200 w-full text-left justify-start rounded-[5px]"
        variant="tertiary"
      >
        {loading ? <CircularLoader loading={loading} /> : "Export"}
      </Button>
      <CheckText>
        Bevor Sie den Export starten — überprüfe die Exporteinstellungen.
      </CheckText>
    </>
  );
};

const Text = styled.p`
  margin-top: 0.75rem;
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.0625rem;
`;

const SmHeader = styled(Header)`
  font-size: 0.75rem;
  line-height: 1.25;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-top: 1.125rem;
`;

const SmText = styled(Text)`
  font-size: 0.625rem;
  line-height: 0.875rem;
  opacity: 1;
  margin: 0;
`;

const BackText = styled.p`
  color: #fff;
  opacity: 0.5;
  transition: color 0.2s ease;
`;

const StyledOption = styled.div<{ $active: boolean }>`
  background-color: #343231;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  text-decoration: none;
  white-space: nowrap;
  width: max-content;
  transition: color 0.2s ease;
  border-radius: 0.125rem;
  min-height: 1.5625rem;
  transition: background-color 0.2s ease;
  cursor: pointer;

  svg path {
    transition: stroke-opacity 0.2s ease;
  }

  &:hover {
    background-color: #fff;
    color: #000000;

    svg path {
      stroke-opacity: 1;
      stroke: #000000;
    }
  }

  ${({ $active }) =>
    $active &&
    `
    background-color: #fff;
    color: #000000;

    svg path {
      stroke-opacity: 1;
      stroke: #000000;
    }
  `}
`;

const OptionHeader = styled.div`
  font-size: 10px;
  margin-bottom: -8px;
  opacity: 0.5;
`;

const CheckText = styled.p`
  color: #808080;
  margin-top: 7px;
  font-size: 10px;
  line-height: 12px;
`;
