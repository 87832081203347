import React from "react";
import styled from "styled-components";

export default function Footer() {
  return (
    <FooterWrapper>
      <Version>
        Noorioo: <span> 0.0.1 BETA</span>
      </Version>

      <Guide>
        <SystemOperational>Systems operational</SystemOperational>
        <Seperator>|</Seperator>
        <GuideLink href="#">Guide Hub</GuideLink>
      </Guide>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  background-color: #1c1b1b;
  padding: 0.75rem var(--padding-right) 0.75rem var(--padding-left);
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  border-top: 1px solid #292826;
  justify-content: space-between;
`;

const Version = styled.p`
  font-size: 0.625rem;
  color: #fff;

  span {
    font-family: BerkeleyMono;
    opacity: 0.5;
  }
`;

const Guide = styled.div`
  display: flex;
  align-items: center;
`;

const SystemOperational = styled.p`
  font-size: 0.625rem;
  font-weight: 500;
  color: #00d885;
`;

const GuideLink = styled.a`
  font-size: 0.625rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #fff;
  }
`;

const Seperator = styled.div`
  color: #fff;
  opacity: 0.5;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;
