import styled from "styled-components";

export function MobileAlert() {
  return (
    <Wrapper>
      This screen is not optimised for mobile please visit on a desktop.
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100dvh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.375rem;
  line-height: 2.0625rem;
  font-family: "Exposure", sans-serif;
`;
