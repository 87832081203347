import axiosWithAuth from "lib/utils/axiosWithAuth";

// This gets the link to the portal where existing user's can manage their subscriptions.
export default async function getPortalLink(): Promise<{ url: string }> {
  const response = await axiosWithAuth.get(
    `${process.env.REACT_APP_NOORIOO_API}/user/portal`
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
