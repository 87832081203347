import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "BerkeleyMono";
    src: url("/fonts/BerkeleyMono.ttf") format("truetype");
  }

  @font-face {
    font-family: "CopyrightKlimTypeFoundry";
    src: url("/fonts/CopyrightKlimTypeFoundry.ttf") format("truetype");
  }

  @font-face {
    font-family: "Charter";
    src: url("/fonts/Charter_Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Charter";
    src: url("/fonts/Charter_Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Exposure";
    src: url("/fonts/Exposure.ttf") format("truetype");
  }

  @font-face {
    font-family: "Exposure";
    src: url("/fonts/Exposure-Italic.ttf") format("truetype");
    font-style: italic;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root{
    --padding-left: 6.25rem;
    --padding-right: 2.625rem;
  }

  body{
    background-color: #212121;
    font-family: Roboto, sans-serif;
    color: #ececec;
  }

  a, button, input, textarea{
    font-family: Roboto, sans-serif;
    border: none;
    outline: none;
  }

  .supabase-auth-ui_ui-button[type="submit"]{
    justify-content: flex-start !important;
  }
  
  .toast{
    padding: 16px;
  }

  .toast.success{
    background-color: #4caf50;
  }

  .toast.error{
    background-color: #f44336;
  }

  .toast.warning{
    background-color: #ff9800;
  }

  .toast.info{
    background-color: #2196f3;
  }
`;
