import { useState } from "react";
import styled from "styled-components";
import { LikeHand, TldrLogo, UnlikeHand } from "../../constants/Svgs";
import Sources from "./Sources";
import { motion } from "framer-motion";
import getDomainName from "lib/utils/getDomainName";
import Markdown from "markdown-to-jsx";
import SourcesV2 from "./SourcesV2";

const formatWithSuperscript = (
  text: string,
  sources: { url: string; title: string }[]
) => {
  const superscriptMap = {
    "0": "⁰",
    "1": "¹",
    "2": "²",
    "3": "³",
    "4": "⁴",
    "5": "⁵",
    "6": "⁶",
    "7": "⁷",
    "8": "⁸",
    "9": "⁹",
  };

  const formattedParts = text.split(/(\[\d+\])/g).map((part, index) => {
    if (part.match(/^\[\d+\]$/)) {
      const number = part.replace(/\[(\d+)\]/, "$1");
      return (
        <StyledSup
          key={index}
          onClick={() =>
            window.open(sources?.[Number(number) - 1].url, "_blank")
          }
        >
          [{number}]
        </StyledSup>
      );
    }
    return part;
  });

  return <>{formattedParts}</>;
};

export default function QuestionV2({
  question,
  answer,
  sources,
}: {
  question: string;
  answer: string;
  sources: { url: string; title: string }[];
}) {
  return (
    <Container initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}>
      <LogoWrapper>
        <TldrLogo />
      </LogoWrapper>
      <QuestionText>
        <>{formatWithSuperscript(answer, sources)}</>
      </QuestionText>
      <ActionButtons>
        <Button>
          <LikeHand />
          <Text>Helpful</Text>
        </Button>
        <Button>
          <UnlikeHand />
          <Text>Inaccurate</Text>
        </Button>
      </ActionButtons>
      <SourcesV2 sources={sources} />
    </Container>
  );
}

const Container = styled(motion.div)`
  margin-bottom: auto;
`;

const UserText = styled.p`
  color: #727272;
  font-size: 10px;
  line-height: 18px;
  margin-bottom: 15px;
`;

const QuestionText = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 22px;

  p {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
    text-transform: capitalize;
    line-height: 1.1;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.125rem;
    margin-top: 1.125rem;
    text-transform: capitalize;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.9375rem;
    margin-top: 0.9375rem;
    text-transform: none;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.625rem;
  }

  h6 {
    font-size: 0.875rem;
    font-weight: 200;
    margin-bottom: 0.5rem;
  }

  ol,
  ul,
  menu {
    list-style: auto;
    margin: 0;
    padding: 0 0 0.5rem 0.6875rem;
  }

  ul {
    list-style: disc;
  }
`;

const Text = styled.div`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid #363433;
  margin-bottom: 16px;
`;

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 2px;
  outline: none;
  border: none;

  svg path {
    transition: fill 0.2s ease;
  }

  &:hover {
    ${Text} {
      color: #fff;
    }
    svg path {
      fill-opacity: 1;
    }
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledSup = styled.sup`
  font-size: 10.5px;
  color: #fff;
  opacity: 0.6;
  cursor: pointer;
`;
