import React from "react";
import styled from "styled-components";
import { CloseIcon } from "../../../constants/Svgs";
import { AnimatePresence, motion } from "framer-motion";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  hideClose?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  disableBackdropClose?: boolean;
}

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.9, y: 50 },
  visible: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 0.9, y: 50 },
};

export default function Modal({
  children,
  onClose,
  open,
  hideClose,
  maxHeight,
  maxWidth,
  disableBackdropClose,
}: ModalProps) {
  const handleBackdropClick = () => {
    if (!disableBackdropClose) onClose();
  };
  return (
    <AnimatePresence>
      {open && (
        <ModalWrapper>
          <Backdrop
            initial="hidden"
            animate="visible"
            exit={"exit"}
            variants={backdropVariants}
            transition={{ duration: 0.2 }}
            onClick={handleBackdropClick}
          />
          <ModalContentWrapper
            initial="hidden"
            animate="visible"
            exit={"exit"}
            variants={modalVariants}
            transition={{ duration: 0.2 }}
            $maxWidth={maxWidth}
            $maxHeight={maxHeight}
          >
            {!hideClose && (
              <ModalHeader>
                <CloseIconContainer onClick={onClose}>
                  <CloseIcon />
                </CloseIconContainer>
              </ModalHeader>
            )}
            <ModalContent>{children}</ModalContent>
          </ModalContentWrapper>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;
const Backdrop = styled(motion.div)`
  background-color: rgba(28, 27, 27, 0.5);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 2;
`;
const ModalContent = styled.div``;
const ModalContentWrapper = styled(motion.div)<{
  $maxWidth?: string;
  $maxHeight?: string;
}>`
  position: relative;
  z-index: 4;
  background-color: #1c1b1b;
  width: ${(props) => props.$maxWidth || "550px"};
  border-radius: 8px;
  overflow: auto;
  max-height: ${(props) => props.$maxHeight || "550px"};
  height: 100%;
`;
const ModalHeader = styled.div``;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 0.875rem;
  cursor: pointer;
  border-radius: 0.125rem;
  transition: color 0.2s;
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #343231;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #514f4e;

    svg {
      path {
        stroke-opacity: 1;
      }
    }
  }
`;
