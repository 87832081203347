import { Node, mergeAttributes, RawCommands } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const Citation = Node.create({
  name: "citation",
  inline: true,
  group: "inline",
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => ({ "data-id": attributes.id || "" }),
      },
      source: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-source"),
        renderHTML: (attributes) => ({
          "data-source": attributes.source || "",
        }),
      },
      content: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-content"),
        renderHTML: (attributes) => ({
          "data-content": attributes.content || "",
        }),
      },
      url: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-url"),
        renderHTML: (attributes) => ({
          "data-url": attributes.url || "",
        }),
      },
      index: {
        default: () => 0, // Auto-increment for each citation
      },
    };
  },

  parseHTML() {
    return [{ tag: `a[data-type="citation"]` }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(
        {
          "data-type": "citation",
          "data-id": node.attrs.id,
          class: "citation-container",
          onclick: `window.handleCitationClick(event)`,
        },
        HTMLAttributes
      ),
      [
        "span",
        { class: "citation-index" },
        `${node.attrs.index}`, // display the index
      ],
      [
        "span",
        { class: "citation-source" },
        `${node.attrs.content}`, // display the source
      ],
    ];
  },

  addCommands() {
    return {
      setCitation:
        ({ id, source, content, url }) =>
        ({ state, chain, dispatch }) => {
          const { selection } = state;
          const insertPosition = selection.empty
            ? selection.$from.pos
            : selection.$to.pos;

          // Prepare to insert a citation node
          const citationNode = this.type.create({ id, source, content, url });

          // Start a transaction
          const tr = state.tr.insert(insertPosition, citationNode);

          // Recalculate indices
          updateCitationIndices(tr);

          // Apply the transaction
          if (dispatch) dispatch(tr);
          return true;
        },
      updateCitation:
        (id, { source, content, url }) =>
        ({ tr, state, dispatch }) => {
          const { doc } = state;
          let hasUpdated = false;

          doc.descendants((node, pos) => {
            if (node.type.name === this.name && node.attrs.id === id) {
              tr.setNodeMarkup(pos, null, { id, source, content, url });
              hasUpdated = true;
              return false; // stop searching
            }
          });

          if (hasUpdated) {
            // Recalculate indices after update
            updateCitationIndices(tr);

            if (dispatch) {
              dispatch(tr);
            }
            return true;
          }
          return false;
        },
    } as Partial<RawCommands>; // Explicit cast to conform to the expected type
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("updateCitationIndices"),
        appendTransaction: (transactions, oldState, newState) => {
          // Check if document content has changed
          if (transactions.some((tr) => tr.docChanged)) {
            return updateCitationIndices(newState.tr);
          }
        },
      }),
    ];
  },
});

// Function to update citation indices based on their positions in the document
function updateCitationIndices(tr) {
  let index = 1;
  let modified = false;

  tr.doc.descendants((node, pos) => {
    if (node.type.name === "citation") {
      if (node.attrs.index !== index) {
        tr.setNodeMarkup(pos, null, { ...node.attrs, index: index });
        modified = true;
      }
      index++;
    }
  });

  return modified ? tr : undefined; // Return the transaction if modified, else undefined
}
