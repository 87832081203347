import { atom } from "recoil";

export enum SettingsDisplayView {
  GENERAL,
  SETTINGS,
  CITATIONS,
  BECKONLINE,
}

export const settingsModalViewState = atom<number>({
  key: "settingsModalViewState",
  default: SettingsDisplayView.GENERAL,
});
