import styled from "styled-components";
import { LinkIcon } from "../../constants/Svgs";

export default function Sources({
  sources,
  hideHeader,
}: {
  sources: { source: string; url: string }[];
  hideHeader?: boolean;
}) {
  return (
    <Container>
      {!hideHeader && <SourcesText>Sources</SourcesText>}
      <Links>
        {sources.map((item, index) => (
          <StyledLink key={index} item={item} index={index} />
        ))}
      </Links>
    </Container>
  );
}

export const StyledLink = ({
  item,
  index,
}: {
  item: { source: string; url: string };
  index: number;
}) => {
  return (
    <Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
      <LinkIcon />
      {item.source}
    </Link>
  );
};

const Container = styled.div``;

const SourcesText = styled.p`
  font-size: 10px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 12px;
`;

const Link = styled.a`
  background-color: #343231;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  text-decoration: none;
  width: max-content;
  transition: color 0.2s ease;
  border-radius: 0.125rem;
  min-height: 1.5625rem;
  transition: background-color 0.2s ease;

  svg path {
    transition: stroke-opacity 0.2s ease;
  }

  &:hover {
    background-color: #fff;
    color: #000000;

    svg path {
      stroke-opacity: 1;
      stroke: #000000;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
