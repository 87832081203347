import { Logo } from "constants/Svgs";
import styled from "styled-components";

export default function NewHeader() {
  return (
    <Header>
      <Logo />
      <Separator />
      <span>Noorioo</span>
    </Header>
  );
}

const Header = styled.div`
  background-color: #1c1b1b;
  min-height: 50px;
  display: flex;
  padding-left: 110px;
  align-items: center;
  border-bottom: 1px solid #292826;

  span {
    font-size: 14px;
    font-weight: 700;
  }

  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
`;

const Separator = styled.div`
  height: 20px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 8px;
`;
