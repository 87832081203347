import { Editor } from "@tiptap/react";
import { useMemo } from "react";
import { ContentPickerOptions } from "../components/ContentTypePicker";
import textIcon from "../../../../assets/images/editor/text.png";
import header1Icon from "../../../../assets/images/editor/header1.png";
import header2Icon from "../../../../assets/images/editor/header2.png";
import header3Icon from "../../../../assets/images/editor/header3.png";
import numberListIcon from "../../../../assets/images/editor/numberList.png";
import bulletIcon from "../../../../assets/images/editor/bulletList.png";
import quoteIcon from "../../../../assets/images/editor/quote.png";
import todoIcon from "../../../../assets/images/editor/todo.png";
import calloutIcon from "../../../../assets/images/editor/callout.png";

export const useTextmenuContentTypes = (editor: Editor) => {
  const options = useMemo<ContentPickerOptions>(() => {
    return [
      {
        type: "category",
        label: "Turn into",
        id: "Turninto",
      },
      {
        icon: textIcon,
        onClick: () =>
          editor
            .chain()
            .focus()
            .lift("taskItem")
            .liftListItem("listItem")
            .setParagraph()
            .run(),
        id: "paragraph",
        disabled: () => !editor.can().setParagraph(),
        isActive: () =>
          editor.isActive("paragraph") &&
          !editor.isActive("orderedList") &&
          !editor.isActive("bulletList") &&
          !editor.isActive("taskList"),
        label: "Text",
        type: "option",
      },
      {
        icon: header1Icon,
        onClick: () =>
          editor
            .chain()
            .focus()
            .lift("taskItem")
            .liftListItem("listItem")
            .toggleHeading({ level: 1 })
            .run(),
        id: "heading1",
        disabled: () => !editor.can().setHeading({ level: 1 }),
        isActive: () => editor.isActive("heading", { level: 1 }),
        label: "Überschrift1",
        type: "option",
      },
      {
        icon: header2Icon,
        onClick: () =>
          editor
            .chain()
            .focus()
            .lift("taskItem")
            .liftListItem("listItem")
            .toggleHeading({ level: 2 })
            .run(),
        id: "heading2",
        disabled: () => !editor.can().setHeading({ level: 2 }),
        isActive: () => editor.isActive("heading", { level: 2 }),
        label: "Überschrift2",
        type: "option",
      },
      {
        icon: header3Icon,
        onClick: () =>
          editor
            .chain()
            .focus()
            .lift("taskItem")
            .liftListItem("listItem")
            .toggleHeading({ level: 3 })
            .run(),
        id: "heading3",
        disabled: () => !editor.can().setHeading({ level: 3 }),
        isActive: () => editor.isActive("heading", { level: 3 }),
        label: "Überschrift3",
        type: "option",
      },
      {
        icon: todoIcon,
        onClick: () => editor.chain().focus().toggleTaskList().run(),
        id: "todoList",
        disabled: () => !editor.can().toggleTaskList(),
        isActive: () => editor.isActive("taskList"),
        label: "Aufgabnliste",
        type: "option",
      },
      {
        icon: bulletIcon,
        onClick: () => editor.chain().focus().toggleBulletList().run(),
        id: "bulletList",
        disabled: () => !editor.can().toggleBulletList(),
        isActive: () => editor.isActive("bulletList"),
        label: "Aufzählungsliste",
        type: "option",
      },
      {
        icon: numberListIcon,
        onClick: () => editor.chain().focus().toggleOrderedList().run(),
        id: "orderedList",
        disabled: () => !editor.can().toggleOrderedList(),
        isActive: () => editor.isActive("orderedList"),
        label: "Nummerierte Liste",
        type: "option",
      },
      {
        icon: quoteIcon,
        onClick: () => editor.chain().focus().toggleBlockquote().run(),
        id: "quote",
        disabled: () => !editor.can().setBlockquote(),
        isActive: () => editor.isActive("blockquote"),
        label: "Zitat",
        type: "option",
      },
      {
        icon: calloutIcon,
        onClick: () =>
          editor
            .chain()
            .focus()
            .toggleNode("heading", "paragraph", {
              level: 6,
            })
            .run(),
        id: "callout",
        disabled: () => !editor.can().setNode("heading", { level: 6 }),
        isActive: () => editor.isActive("heading", { level: 6 }),
        label: "Callout",
        type: "option",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editor.state]);

  return options;
};
