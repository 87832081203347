import axios, { AxiosError } from "axios";
import { getToken } from "hooks/user/useUser";

// Create an Axios instance
const axiosWithAuth = axios.create();

// Add a request interceptor to the instance
axiosWithAuth.interceptors.request.use(
  async (config) => {
    // Retrieve the token from cookies
    const token = await getToken();

    // If the token exists, set the Authorization header
    if (token) {
      config.headers["x-auth-token"] = token;
    } else {
      return Promise.reject("No auth token provided");
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosWithAuth;
