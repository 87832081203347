import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  ExternalHyperlink,
  LevelFormat,
  BorderStyle,
  LineRuleType,
  Footer,
  AlignmentType,
  FootnoteReferenceRun,
  PageBreak,
  PageNumber,
} from "docx";
import getFormattedSources from "lib/citation/getFormatedSources";
import { ExportConfig } from "types/document";
interface CitationSource {
  index: string;
  source: string;
}

interface PageData {
  content: Paragraph[];
  citations: CitationSource[];
}

// Helper function to parse and handle nodes recursively
function parseNode(
  node: Element,
  addCitation: (citation: CitationSource) => number
): Paragraph[] {
  switch (node.tagName) {
    case "H1":
      return [
        new Paragraph({
          text: node.textContent || "",
          heading: HeadingLevel.HEADING_1,
        }),
      ];
    case "H2":
      return [
        new Paragraph({
          text: node.textContent || "",
          heading: HeadingLevel.HEADING_2,
        }),
      ];
    case "H3":
      return [
        new Paragraph({
          text: node.textContent || "",
          heading: HeadingLevel.HEADING_3,
        }),
      ];
    case "H4":
      return [
        new Paragraph({
          text: node.textContent || "",
          heading: HeadingLevel.HEADING_4,
        }),
      ];
    case "H5":
      return [
        new Paragraph({
          text: node.textContent || "",
          heading: HeadingLevel.HEADING_5,
        }),
      ];
    case "H6":
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: "\n", // Add a line break before the text
            }),
            new TextRun({
              text: node.textContent || "",
              italics: true,
            }),
            new TextRun({
              text: "\n", // Add a line break after the text
            }),
          ],
          heading: HeadingLevel.HEADING_6,
          border: {
            top: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
            bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
            left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
            right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
          },
          spacing: {
            before: 240, // Space before the paragraph (margin-top)
            after: 240, // Space after the paragraph (margin-bottom)
            line: 360, // Line spacing
            lineRule: LineRuleType.EXACT, // Ensures exact line spacing
          },
          indent: {
            left: 360, // Left indent (padding-left)
            right: 360, // Right indent (padding-right)
          },
        }),
      ];
    case "UL":
      return parseList(node, false, 0, addCitation);
    case "OL":
      return parseList(node, true, 0, addCitation);
    case "BLOCKQUOTE":
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: node.textContent || "",
              italics: true,
            }),
          ],
          indent: { left: 720, hanging: 360, right: 720 },
        }),
      ];
    case "A":
      return [
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: node.textContent || "",
                  style: "Hyperlink",
                }),
              ],
              link: node.getAttribute("href") || "",
            }),
          ],
        }),
      ];
    case "P":
      return [
        new Paragraph({
          children: parseChildren(node, addCitation),
          style: "normalParagraph",
        }),
      ];
    default:
      return [
        new Paragraph({
          text: node.textContent || "",
          style: "normalParagraph",
        }),
      ];
  }
}

// Function to parse children nodes
function parseChildren(
  parent: Element,
  addCitation: (citation: CitationSource) => number
): (TextRun | Paragraph | ExternalHyperlink)[] {
  return Array.from(parent.childNodes)
    .map((child) => {
      if (
        child.nodeName.toUpperCase() === "A" &&
        (child as Element).getAttribute("data-type") === "citation"
      ) {
        const citationNode = child as HTMLElement;
        const index =
          citationNode.querySelector(".citation-index")?.textContent;
        const source = citationNode.getAttribute("data-source");
        const url = citationNode.getAttribute("data-url");
        const content = citationNode.getAttribute("data-content") || "";

        if (index && source) {
          const footnoteId = addCitation({ index, source });
          return [
            new TextRun(` ${content}`), // Add the content as normal text
            new FootnoteReferenceRun(footnoteId),
          ];
        }

        return new ExternalHyperlink({
          children: [
            new TextRun({
              text: `[${index}]`,
              superScript: true,
              style: "Hyperlink",
            }),
          ],
          link: url || "",
        });
      } else if (child.nodeType === Node.TEXT_NODE) {
        return new TextRun(child.textContent || "");
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        if (child.nodeName.toUpperCase() === "A") {
          const aNode = child as HTMLAnchorElement;
          return new ExternalHyperlink({
            children: [
              new TextRun({
                text: aNode.textContent || "",
                style: "Hyperlink",
              }),
            ],
            link: aNode.href,
          });
        }
        return parseNode(child as Element, addCitation);
      }
    })
    .flat()
    .filter((el): el is TextRun | ExternalHyperlink | Paragraph => !!el);
}
// Function to parse lists recursively
function parseList(
  listNode: Element,
  isOrdered: boolean,
  level: number = 0,
  addCitation: (citation: CitationSource) => number
): Paragraph[] {
  return Array.from(listNode.children).flatMap((item) => {
    const paragraphs: Paragraph[] = [];

    // Process the main content of the list item
    const mainContent = Array.from(item.childNodes).filter(
      (node) =>
        node.nodeType === Node.TEXT_NODE ||
        ((node as Element).tagName !== "UL" &&
          (node as Element).tagName !== "OL")
    );

    if (mainContent.length > 0) {
      const children = mainContent
        .map((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return new TextRun({ text: node.textContent?.trim() || "" });
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            return parseChildren(node as Element, addCitation);
          }
        })
        .flat()
        .filter(
          (child): child is TextRun | ExternalHyperlink | Paragraph => !!child
        );

      paragraphs.push(
        new Paragraph({
          children: children,
          numbering: {
            level: level,
            reference: isOrdered ? "ordered-list" : "unordered-list",
          },
        })
      );
    }

    // Process nested lists
    const nestedLists = Array.from(item.children).filter(
      (child) => child.tagName === "UL" || child.tagName === "OL"
    );

    nestedLists.forEach((nestedList) => {
      paragraphs.push(
        ...parseList(
          nestedList,
          nestedList.tagName === "OL",
          level + 1,
          addCitation
        )
      );
    });

    return paragraphs;
  });
}

function processDocument(body: Element): {
  content: Paragraph[];
  citations: CitationSource[];
} {
  const content: Paragraph[] = [];
  const citations: CitationSource[] = [];
  let footnoteId = 0;

  function addCitation(citation: CitationSource): number {
    if (!citations.some((c) => c.index === citation.index)) {
      citations.push(citation);
      footnoteId++;
    }
    return footnoteId;
  }

  function parseNodeWrapper(node: Element): void {
    const parsedNodes = parseNode(node, addCitation);
    content.push(...parsedNodes);
  }

  Array.from(body.childNodes).forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      parseNodeWrapper(node as Element);
    }
  });

  return { content, citations };
}

// Main function to create a DOCX document from complex HTML content
export async function createDocFromHtml(
  htmlString: string,
  config: ExportConfig
): Promise<Blob> {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, "text/html");
  const body = htmlDoc.body;

  const { content, citations: rawCitations } = processDocument(body);

  const formatResp = await getFormattedSources(
    rawCitations.map((s) => s.source)
  );

  let citations = formatResp.output.map((source, i) => ({
    index: rawCitations[i],
    source,
  }));

  // console.log({ rawCitations, citations: formatResp.output });

  // Add bibliography section
  const bibliographyTitle =
    citations.length > 0
      ? [
          new Paragraph({
            children: [new PageBreak(), new TextRun("Literaturverzeichnis")],
            heading: HeadingLevel.HEADING_2,
          }),
        ]
      : [];

  const bibliographyContent = citations.map(
    (citation, index) =>
      new Paragraph({
        text: `${citation.source}`,
        style: "normalParagraph",
        numbering: {
          reference: "bibliography-list",
          level: 0,
        },
      })
  );

  // Create footer with page number if numberedPages is true
  const footer = config.numberedPages
    ? new Footer({
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                children: [PageNumber.CURRENT],
                size: 20,
              }),
            ],
          }),
        ],
      })
    : undefined;

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: config.text * 2,
            font: config.font || "Times New Roman",
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240),
            },
          },
        },
        heading1: {
          run: {
            size: config.heading1 * 2,
            bold: true,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240),
            },
          },
        },
        heading2: {
          run: {
            size: config.heading2 * 2,
            bold: true,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240),
            },
          },
        },
        heading3: {
          run: {
            size: config.heading3 * 2,
            bold: true,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240),
            },
          },
        },
        heading6: {
          run: {
            size: config.text * 3,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: 240,
            },
          },
        },
        listParagraph: {
          run: {
            size: config.text * 2,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240),
            },
            indent: { left: 720, hanging: 360 }, // Indentation for list paragraphs
          },
        },
        hyperlink: {
          run: {
            color: "#0000FF", // Style for hyperlinks (blue color)
            underline: { color: "#0000FF" },
          },
        },
      },
      paragraphStyles: [
        {
          id: "normalParagraph",
          name: "Normal Paragraph",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: config.text * 2,
            font: config.font || "Times New Roman",
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: Math.round(config.lineSpacing * 240), // Convert to twips
              before: 20, // 3/4 of line spacing
            },
          },
        },
        {
          id: "footnoteText",
          name: "Footnote Text",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 10 * 2,
          },
          paragraph: {
            spacing: {
              lineRule: LineRuleType.AUTO,
              line: 240,
              before: 0,
              after: 240,
            },
          },
        },
      ],
    },
    numbering: {
      config: [
        {
          reference: "ordered-list",
          levels: Array.from({ length: 9 }, (_, i) => ({
            level: i,
            format: LevelFormat.DECIMAL,
            text: `%${i + 1}.`,
            alignment: "left",
            style: {
              paragraph: {
                indent: { left: 720 * (i + 1), hanging: 360 },
              },
            },
          })),
        },
        {
          reference: "unordered-list",
          levels: Array.from({ length: 9 }, (_, i) => ({
            level: i,
            format: LevelFormat.BULLET,
            text: "•",
            alignment: "left",
            style: {
              paragraph: {
                indent: { left: 720 * (i + 1), hanging: 360 },
              },
            },
          })),
        },
        {
          reference: "task-list",
          levels: Array.from({ length: 9 }, (_, i) => ({
            level: i,
            format: LevelFormat.BULLET,
            text: "",
            alignment: "left",
            style: {
              paragraph: {
                indent: { left: 720 * (i + 1), hanging: 360 },
              },
            },
          })),
        },
        {
          reference: "bibliography-list",
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: "%1.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 240, hanging: 360 },
                },
              },
            },
          ],
        },
      ],
    },
    footnotes: {
      ...rawCitations.reduce((acc, citation, index) => {
        acc[index + 1] = {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: " ",
                }),
                new TextRun(citation.source),
              ],
              style: "footnoteText", // Apply the footnote style
            }),
          ],
        };
        return acc;
      }, {}),
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              left: `${config.leftSpacing}cm`, // Convert cm to twips
              right: `${config.rightSpacing}cm`, // Convert cm to twips
              top: `${config.verticalSpacing}cm`, // Convert cm to twips
              bottom: `${config.verticalSpacing}cm`, // Convert cm to twips
            },
          },
        },
        children: [
          ...content,
          ...(config.showBibliography
            ? [...bibliographyTitle, ...bibliographyContent]
            : []),
        ],
        footers: footer ? { default: footer } : undefined,
      },
    ],
  });

  return Packer.toBlob(doc);
}
