import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUser, { getToken, storageKey } from "hooks/user/useUser";
import { useMediaQuery } from "react-responsive";
import { MobileAlert } from "components/General/MobileAlert";

const UserRequired = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const session = localStorage.getItem(storageKey);
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  useEffect(() => {
    if (!session) {
      navigate("/auth");
    }
  }, [session, navigate]);

  if (!session) {
    return null;
  }

  if (!!session && isMobile && location.pathname !== "/onboarding") {
    return <MobileAlert />;
  }

  return <>{children}</>;
};

export default UserRequired;
